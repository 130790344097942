import React from "react";
import UI from "./UI";

import { GET } from "./../../../../../../../utils/api";

class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      project: {
        project_details: { project_partner: {} },
        sg_distribution_by_agent: [],
        members_enrollment_by_month: [],
        mno_distribution: []
      },
      lL: false,
      lE: false,
      lM: "",
      lR: null
    };
  }

  componentDidMount = () => this.fetchData();

  fetchData = () => {
    let { project_id } = this.props;
    this.setState({ lL: true, lE: false });

    Promise.all([
      GET(`save-${global.platform}/project/${project_id}`),
      GET(`save-${global.platform}/project/mno_distribution/${project_id}`)
    ])
      .then(data => {
        this.setState({
          project: {
            ...(() => {
              let d = { ...data[0] };
              d.project_details.project_partner = d.project_partner;
              return d;
            })(),
            mno_distribution: data[1]
          },
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.fetchData
        });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default Overview;
