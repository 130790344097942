import React from "react";

import { Switch, Route } from "react-router-dom";

import Overview from "./Overview";
import SavingGroups from "./SavingGroups";

const UI = props => (
  <Switch>
    <Route
      exact
      path={`/federations/${props.project_id}/admins/${props.agent_id}`}
      render={props_ => <Overview {...props} {...props_} />}
    />
    <Route
      path={`/federations/${props.project_id}/admins/${
        props.agent_id
      }/saving-groups`}
      render={props_ => <SavingGroups {...props} {...props_} />}
    />
  </Switch>
);

export default UI;
