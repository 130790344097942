import React from "react";

import { Grid, Icon } from "semantic-ui-react";

import Box from "./../../../../../../../../components/Box";
import phoneVerify from "./../../../../../../../../utils/phoneVerify";
import nTh from "./../../../../../../../../utils/nTh";
var moment = require("moment");

const UI = ({ member, history }) => (
  <div className="pannel-scroll">
    <div className="padding">
      <div className="header">
        {history && history.goBack && (
          <div className="back" onClick={() => history.goBack()}>
            <Icon name="arrow left" fitted size="large" />
          </div>
        )}
        <span className="title">
          {member.name} / {member.sg_name}
        </span>
      </div>

      <Grid columns={4}>
        <Grid.Row stretched>
          <Grid.Column width={4}>
            <Box
              title="CUMULATIVE SAVINGS"
              value={member.savings_amount}
              color="green"
              chart={{
                data: member.savings_vs_loans_distribution,
                key: "savings_amount"
              }}
              lineChart
            />
            <Box
              title="CUMULATIVE LOANS"
              value={member.loans_amount}
              unit="RWF"
              color="orange"
              chart={{
                data: member.savings_vs_loans_distribution,
                key: "loans_amount"
              }}
              lineChart
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Box
              title="SOCIAL FUND"
              value={member.social_fund_amount}
              color="blue"
              chart={{
                data: member.savings_vs_loans_distribution,
                key: "social_fund_amount"
              }}
              lineChart
            />
            <Box
              title="OUTSTANDING LOANS"
              value={member.outstanding_loans_amount}
              unit="RWF"
              color="red"
              chart={{
                data: member.savings_vs_loans_distribution,
                key: "outstanding_loans_amount"
              }}
              lineChart
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Box
              name={member.name}
              rows={[
                [
                  {
                    label: "Phone",
                    value: phoneVerify(member.phone).phone_
                  }
                ],
                [
                  {
                    label: "Email",
                    value: member.email
                  }
                ],
                [
                  {
                    label: "Address",
                    value: member.location.province.name
                  }
                ],
                [
                  {
                    label: member.location.district.name,
                    className: "labelFlat noMargin"
                  }
                ],
                [
                  {
                    label: member.location.sector.name,
                    className: "labelFlat noMargin"
                  }
                ],
                [
                  {
                    label: member.location.cell.name,
                    className: "labelFlat noMargin"
                  }
                ],
                [
                  {
                    label: member.location.village.name,
                    className: "labelFlat noMargin"
                  }
                ],
                [],
                [
                  {
                    label: "???",
                    className: "labelBadge"
                  }
                ],
                [
                  {
                    label: `Joined on ${moment().format(
                      "MMM DD, YYYY"
                    )} ???????`,
                    className: "labelFlat footer right"
                  }
                ]
              ]}
              noDate
              noChart
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Box
              title="Cycle Length"
              value={moment(member.sg_cycles[0].end).diff(
                member.sg_cycles[0].start,
                "months"
              )}
              unit="months"
              noChart
            />
            <Box
              title="SG Cycle"
              value={member.sg_cycles.length}
              unit={nTh(member.sg_cycles.length, false)}
              noChart
            />
            <Box
              title="Member Cycle"
              value={member.member_cycles.length}
              unit={nTh(member.member_cycles.length, false)}
              noChart
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched>
          <Grid.Column width={8}>
            <Box
              title="Savings VS Loans"
              chart={{
                data: [
                  ...(member.savings_vs_loans_distribution
                    ? member.savings_vs_loans_distribution
                    : []
                  ).map(d => ({
                    name: moment(d.month).format("MMM"),
                    Savings: d.savings_amount,
                    Loans: d.loans_amount
                  }))
                ],
                keys: ["Savings", "Loans"]
              }}
              doubleBarChart
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Box
              title="Synchronized Savings and Shares"
              chart={{
                data: [
                  ...(member.savings_vs_shares_distribution
                    ? member.savings_vs_shares_distribution
                    : []
                  ).map(d => ({
                    name: moment(d.month).format("MMM"),
                    Savings: d.savings,
                    Shares: d.shares
                  }))
                ],
                keys: ["Savings", "Shares"]
              }}
              complexChart
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  </div>
);

export default UI;
