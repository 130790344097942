import React from "react";

import ContentLoader from "./../../../../../../components/Loader";
import DataTable from "./../../../../../../components/DataTable";

const UI = ({ 
    history,
    requests,
    profile_id,
    requestssss 
}) => (
  <ContentLoader
    lL={false}
    lE={false}
    lM={""}
    lR={()=>{}}
    message="Fetching requests..."
    showContent={requests.length !== 0}
  >
  <div className="pannel-scroll">
    <DataTable
      title="Requests"
      history={history}
      dataKey="saving_group_id"
      empty="No requests."
      data={requests[0]}
      columns={[
        {
          dataField: "saving_group_name",
          text: "Saving group",
          sort: true,
          formatter: "name",
          link: ["profiles", [(profile_id)], "requests", "saving-groups", "[(saving_group_id)]"]
        },
        {
          dataField: "amount",
          text: "Amount",
          sort: true,
          textPrefix: " (RWF)",
          formatter: "number"
        },
        {
          dataField: "members",
          text: "Members",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "outstanding_loans",
          text: "Oustanding Loans",
          sort: true,
          textPrefix: " (RWF)",
          formatter: "number"
        },
        {
          dataField: "savings",
          text: "Savings",
          sort: true,
          textPrefix: " (RWF)",
          formatter: "number"
        },
        {
          dataField: "status",
          text: "Status",
          sort: true,
          formatter: "status",
          badge2: {
            statusArray: ["Declined", "Approved", "Pending"],
            status: 
              ({status}) => 
                status.toLowerCase() === "approved" ? 
                  1 : (
                    status.toLowerCase() === "pending" ?
                      2 :
                      0
                  )
            ,
            request: true
          }
        }
      ]}
    />
    </div>
  </ContentLoader>
);

export default UI;
