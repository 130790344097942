import React from "react";

import { Switch, Route } from "react-router-dom";

import List from "./List";
import Member from "./Member";

const UI = props => (
  <Switch>
    <Route
      exact
      path={`/organizations/${props.org_id}/saving-groups/${
        props.sg_id
      }/members`}
      render={props_ => <List {...props} {...props_} />}
    />
    <Route
      path={`/organizations/${props.org_id}/saving-groups/${
        props.sg_id
      }/members/:member_id`}
      render={props_ => (
        <Member
          {...props}
          {...props_}
          member_id={props_.match.params.member_id}
        />
      )}
    />
  </Switch>
);

export default UI;
