import React from "react";
import ContentLoader from "./../../../../../../components/Loader";
import MyList from "./../../../../../../components/MyList";
import { Link } from "react-router-dom";
import phoneVerify from "./../../../../../../utils/phoneVerify";
import userFilters from "./../../../../../../utils/userFilters";
import { Icon, Label, Button, Dropdown, Pagination } from "semantic-ui-react";

const UI = ({
  lL,
  lE,
  lM,
  lR,
  users,
  currentUser,
  currentFilter,
  page,
  pages,
  handleFetch,
  history
}) => (
  <div className="pannel-scroll">
    <div className="padding">
      <div className="header">
        {history && history.goBack && (
          <div className="back" onClick={() => history.goBack()}>
            <Icon name="arrow left" fitted size="large" />
          </div>
        )}
        <span className="title">Organization Users</span>
        {currentUser.is_admin === 1 && (
          <Button
            as={Link}
            to="/users/add"
            content="Add User"
            labelPosition="right"
            icon="add"
          />
        )}
      </div>
      <span>
        Filter by{" "}
        <Dropdown
          disabled={lL}
          inline
          options={userFilters}
          value={currentFilter}
          onChange={(event, { value }) => {
            if (currentFilter !== value) handleFetch(value, 1);
          }}
        />
      </span>
      <ContentLoader
        lL={lL}
        lE={lE}
        lM={lM}
        lR={lR}
        message="Wait a moment..."
        showContent={users.length !== 0}
      >
        <MyList
          items={users}
          left="name"
          title="name"
          description={["phone", "work_email"]}
          descriptionFn={t => [
            <span>
              <Icon name="phone" fitted size="small" />
              &nbsp;{phoneVerify(t[0]).phone_}
            </span>,
            <span>
              <Icon name="at" fitted size="small" />
              &nbsp;{t[1]}
            </span>
          ]}
          descriptionJoin={<span className="padder">&nbsp;</span>}
          right="is_admin"
          rightFn={t =>
          (
              <Label 
                color={t!==0?"green":null} 
                horizontal style={{ marginRight: 0 }}>
                {t===0?"user":"Admin"}
              </Label>
            )
          }
          {...(currentUser.is_admin === 0
            ? {}
            : { as: Link, link: ["users", "[(id)]"] })}
        />
      </ContentLoader>
      <div
        style={{
          marginTop: 24,
          display: "flex",
          flexDirection: "row-reverse"
        }}
      >
        <Pagination
          disabled={lL}
          activePage={page}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true
          }}
          firstItem={{
            content: <Icon name="angle double left" />,
            icon: true
          }}
          lastItem={{
            content: <Icon name="angle double right" />,
            icon: true
          }}
          prevItem={{ content: <Icon name="angle left" />, icon: true }}
          nextItem={{ content: <Icon name="angle right" />, icon: true }}
          totalPages={pages}
          onPageChange={(event, { activePage }) => {
            if (page !== activePage) handleFetch(currentFilter, activePage);
          }}
        />
      </div>
    </div>
  </div>
);

export default UI;
