import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Profile from "./Profile";
import New from "./New";

class FSP extends React.Component {
  render() {
    return (
      <Switch>
        {/* <Route
          exact
          path="/profiles"
          render={props => <Profiles {...this.props} {...props} />}
        /> */}
        <Route
          exact
          path="/profiles/new"
          render={props => (
            <New {...this.props} {...props} {...props.match.params} />
          )}
        />
        <Route
          path="/profiles/:profile_id"
          render={props => (
            <Profile {...this.props} {...props} {...props.match.params} />
          )}
        />
        <Route path="/" render={props => <Home {...this.props} {...props} />} />
      </Switch>
    );
  }
}

export default FSP;
