import React from "react";

import { Icon, Button, Input } from "semantic-ui-react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, {
  numberFilter,
  textFilter
} from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import numberFormat from "./../../utils/numberFormat";
import nThLetter from "./../../utils/nThLetter";
import createLink from "./../../utils/createLink";

class DataTable extends React.Component {
  static defaultProps = {
    columns: [
      {
        dataField: "id",
        text: "ID"
      },
      {
        dataField: "name",
        text: "Name"
      },
      {
        dataField: "more",
        text: "More..."
      }
    ]
  };

  state = {
    updateUI: 0,
    filter: false
  };

  formatter = (
    formatter,
    link,
    disabled = () => false,
    badge = null,
    textIf = null,
    badge2 = null
  ) => {
    const Wrap = ({ children, item }) => {
      if (link) {
        if (disabled(item)) {
          return children;
        } else {
          return <Link {...createLink(item, link)}>{children}</Link>;
        }
      }
      return children;
    };

    let formatters = {
      number: (cell, row) => (
        <Wrap item={row}>
          {textIf ? textIf(row) : numberFormat(cell)}{" "}
          {badge && badge.show(row) && (
            <span className="my-badge">{badge.text}</span>
          )}
        </Wrap>
      ),
      name: (cell, row) => {
        return (
          <Wrap item={row}>
            <span className="name">
              <span className="abrev">{nThLetter(cell, 2)}</span>
              <span className="text">{textIf ? textIf(row) : cell}</span>
              {badge && badge.show(row) && (
                <span className="my-badge">{badge.text}</span>
              )}
            </span>
          </Wrap>
        );
      },
      _: (cell, row) => (
        <Wrap item={row}>
          {textIf ? textIf(row) : cell}{" "}
          {badge && badge.show(row) && (
            <span className="my-badge">{badge.text}</span>
          )}
        </Wrap>
      ),
      status: (cell, row) => {
        return (
          <Wrap item={row}>
            {badge2.request ? (
              <span className={badge2.status(row) === 1 ? "my-badge-status-paid" : (
                badge2.status(row) === 2 ? 
                  "my-badge-status-yellow" :
                  "my-badge-status-unpaid"
                )}>
                {badge2.statusArray[badge2.status(row)]}
              </span>
            ) : (
              <span className={badge2.status(row) === 1 ? "my-badge-status-paid" : "my-badge-status-unpaid"}>
                {badge2.statusArray[badge2.status(row)]}
              </span>
            )}
          </Wrap>
        );
      }
    };

    return formatters[formatter];
  };

  filters = [];

  handleColumns = (cols, filter) => {
    let columns_ = [...cols];

    let columns = [];

    this.filters = [];

    columns_.map((column_, index) => {
      let column = { ...column_ };
      if (column.hasOwnProperty("sort")) {
        column.sortCaret = (order, column) => {
          if (!order)
            return (
              <span className="carets">
                <Icon name="sort" fitted />
              </span>
            );
          else if (order === "asc")
            return (
              <span className="carets">
                <Icon name="caret down" fitted />
              </span>
            );
          else if (order === "desc")
            return (
              <span className="carets">
                <Icon name="caret up" fitted />
              </span>
            );
          return null;
        };
      }

      if (column.formatter === "number") {
        column.filter = numberFilter({
          getFilter: filter_ => {
            this.filters.push(filter_);
          }
        });
        column.csvType = Number;
      } else {
        column.filter = textFilter({
          getFilter: filter_ => {
            this.filters.push(filter_);
          }
        });
      }

      if (!filter) {
        column.filter = null;
      }

      if (column.hasOwnProperty("textPrefix")) {
        column.headerFormatter = (
          { sortElement, filterElement }
        ) => {
          return (
            <div>
              <span className="_text_">{column.text}</span>
              <span className="_prefix_">{column.textPrefix}</span>
              {sortElement}
              {filterElement}
            </div>
          );
        };
      } else {
        column.headerFormatter = (
          { sortElement, filterElement }
        ) => (
          <div>
            <span className="_text_">{column.text}</span>
            {sortElement}
            {filterElement}
          </div>
        );
      }

      column.formatter = this.formatter(
        column.formatter,
        column.link,
        column.disabled,
        column.badge,
        column.textIf,
        column.badge2
      );

      columns.push(column);

      return column_;
    });

    return columns;
  };

  reference = r => {
    this.table = r;
  };

  render = () => {
    let { handleColumns } = this;
    let {
      columns,
      empty = "Table is Empty.",
      data = [],
      history,
      title = "Data",
      dataKey = "id",
      more = null
    } = this.props;

    let { filter, updateUI } = this.state;

    let cols = handleColumns([...columns], filter);

    return (
      <div key={updateUI} className="my-data-table">
        <ToolkitProvider
          keyField={dataKey}
          data={data}
          columns={[...cols]}
          filter={filterFactory()}
          search
          exportCSV={{
            fileName: `${title}.csv`
          }}
        >
          {props => (
            <div className="padding">
              <div className="header">
                {history && history.goBack && (
                  <div className="back" onClick={() => history.goBack()}>
                    <Icon name="arrow left" fitted size="large" />
                  </div>
                )}
                <span className="title">{title}</span>
                <Input
                  placeholder="Search..."
                  size="mini"
                  iconPosition="left"
                  onChange={(e, { value }) =>
                    props.searchProps.onSearch(value.trim())
                  }
                  action
                >
                  <Icon name="search" />
                  <input />
                  <Button
                    basic={!filter}
                    icon="filter"
                    size="small"
                    primary={filter}
                    onClick={() => {
                      this.filters.map(filter_ => {
                        try {
                          filter_("");
                        } catch (exc) {}
                        return filter_;
                      });

                      this.setState({
                        updateUI: updateUI + 1,
                        filter: !filter
                      });
                    }}
                  />
                  <Button
                    size="small"
                    onClick={() =>
                      props.csvProps.onExport(this.table.table.props.data)
                    }
                    labelPosition="right"
                    icon="external share"
                    content="Export CSV"
                  />
                </Input>
                {more}
              </div>
              <BootstrapTable
                ref={n => this.reference(n)}
                {...props.baseProps}
                filter={filterFactory()}
                wrapperClasses="data-table"
                noDataIndication={empty}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  };
}

export default DataTable;
