import { reactLocalStorage } from "reactjs-localstorage";

const updateSession = (_this, user_ = true) => {
  let user = { signedIn: false, token: "", token_v2: "" };
  if (user_ === true) {
    user = reactLocalStorage.getObject("user");
  } else {
    user = { ...user, ...user_ };
    reactLocalStorage.setObject("user", user);
  }

  let { signedIn, token, token_v2 } = user;

  global.token = token;
  global.token_v2 = token_v2;

  _this.setState({ signedIn, token, token_v2, user, loadingSession: false });
};

export default updateSession;
