import React from "react";
import { Icon, Grid, Button } from "semantic-ui-react";
import ContentLoader from "./../../../../../../../components/Loader";
import Box from "./../../../../../../../components/Box";
import numberFormat from "./../../../../../../../utils/numberFormat";
import {Link} from "react-router-dom";
var moment = require("moment");

const UI = ({ project, history, lL, lE, lM, lR }) =>(
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Wait a moment..."
    showContent={Object.keys(project).includes("members_count")}
  >
    <div className="pannel-scroll">
      <div className="padding">
        <div className="header">
          {history && history.goBack && (
            <div className="back" onClick={() => history.goBack()}>
              <Icon name="arrow left" fitted size="large" />
            </div>
          )}
          <span className="title">Project: {project.project_details.name}</span>

          <Button
            size="small"
            as={Link}
            to={`/organizations/${project.project_details.organization_id}/projects/${project.project_details.id}/saving-groups`}
            label={project.sgs_count?project.sgs_count.toString():'...'}
            content="View Saving groups"></Button>
        </div>


        <Grid columns={4}>
          <Grid.Row stretched>
            <Grid.Column width={4}>
              <Box
                title="SAVING GROUPS"
                value={project.sgs_count}
                color="green"
                chart={{
                  data: project.sg_enrollment_per_month,
                  key: "count"
                }}
                lineChart
              />
              <Box
                title="SAVINGS"
                value={project.savings_amount}
                unit="RWF"
                color="orange"
                chart={{
                  data: project.savings_vs_loans_distribution,
                  key: "savings_amount"
                }}
                lineChart
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Box
                title="AGENTS"
                value={project.agent_count}
                color="blue"
                chart={{
                  data: project.agent_enrollment_per_month,
                  key: "count"
                }}
                lineChart
              />
              <Box
                title="OUTSTANDING LOANS"
                value={project.outstanding_loans_amount}
                unit="RWF"
                color="red"
                chart={{
                  data: project.savings_vs_loans_distribution,
                  key: "outstanding_loans_amount"
                }}
                lineChart
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Box
                name={project.project_details.name}
                rows={[
                  [
                    {
                      label: "Start Date",
                      value: moment(project.project_details.start).format(
                        "MMM DD, YYYY"
                      )
                    },
                    {
                      label: "End Date",
                      value: moment(project.project_details.end).format(
                        "MMM DD, YYYY"
                      )
                    }
                  ],
                  [
                    {
                      label: "Budget",
                      value: numberFormat(
                        project.project_details.budget,
                        "",
                        "RWF"
                      )
                    }
                  ],
                  [
                    {
                      label: "Partner",
                      value:
                        project.project_details.project_partner.partner_name ||
                        "-",
                      link: `/organizations/${
                        project.project_details.organization_id
                      }/partners/${
                        project.project_details.project_partner.partner_id
                      }`
                    },
                    {
                      label: "Donor",
                      value: project.project_details.donor
                    }
                  ],
                  [],
                  [
                    {
                      label: `Created on ${moment(
                        project.project_details.date
                      ).format("MMM DD, YYYY")}`,
                      className: "labelFlat footer right"
                    }
                  ]
                ]}
                noDate
                noChart
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Box
                title="MEMBERS"
                value={project.members_count}
                chart={{
                  data: project.members_enrollment_by_month,
                  key: "count"
                }}
                barChart
              />
              <Box
                title="DROP OUTS"
                value={project.drop_out_count}
                chart={{ data: project.dropouts_per_month, key: "count" }}
                barChart
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column width={8}>
              <Box
                title="SGs Distribution Per Agent"
                chart={{
                  data: [
                    ...project.sg_distribution_by_agent.map(d => ({
                      name: d.agent_name,
                      caption: "SGs:",
                      count: d.sg_count
                    }))
                  ],
                  key: "count"
                }}
                radialChart
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Box
                title="MNO Distribution Per Province"
                chart={{
                  data: [
                    ...project.mno_distribution.map(d => ({
                      name: d.province_name,
                      MTN: d.mtn_count,
                      TIGO: d.tigo_count
                    }))
                  ],
                  keys: ["MTN", "TIGO"]
                }}
                doubleBarChart
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  </ContentLoader>
);

export default UI;
