import React from "react";
import { POST, GET } from "./../../../../../../utils/api";
import formErrors from "./../../../../../../utils/formErrors";
import UI from "./UI";

class New extends React.Component {
  constructor(props) {
    super(props);

    let { defaultAreas } = props;

    this.state = {
      org_name: "",
      org_type: 7,
      adm_name: "",
      adm_email: "",
      adm_phone: "",
      adm_id_number: "",
      adm_gender: "",
      partners: [],
      partner: "",
      ...defaultAreas,
      lL: false,
      lE: false,
      lM: "",
      lS: false,
      aL: false,
      aE: false,
      aM: "",
      aR: null,
      aI: null
    };
  }

  componentDidMount = () => {
    this.handleFetch();
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, lE: false, lS: false });
  };

  handleFetch = () => {
    let { project_id } = this.props;

    this.setState({ aL: true, aE: false, aM: "", aR: null, aI: 0 });

    GET(`v2/projects/${project_id}/partners`)
      .then(data => {
        let partners = [];

        data.partners.map(p => {
          if (p.partner.type === 6) {
            partners.push({
              text: p.partner.name,
              value: p.partner.id,
              key: p.partner.id
            });
          }

          return p;
        });

        this.setState({
          partners,
          aL: false,
          aE: false,
          aM: "",
          aR: null,
          aI: 1
        });
      })
      .catch(error => {
        this.setState({
          aL: false,
          aE: true,
          aM: error,
          aR: this.handleFetch,
          aI: 0
        });
      });
  };

  handleSave = (...e) => {
    if (formErrors(e).ok) {
      const {
        org_name,
        adm_name,
        adm_email,
        adm_phone,
        adm_id_number,
        adm_gender,
        village,
        partner
      } = this.state;

      const data = {
        name: org_name,
        union_id: partner * 1,
        cooperative_contact_person: {
          name: adm_name,
          email: adm_email,
          phone: adm_phone,
          id_number: adm_id_number,
          gender: adm_gender
        },
        village_ids: village
      };

      this.setState({ lL: true, lE: false, lM: "", lS: false });

      POST(`save-ssa/cooperatives`, data)
        .then(data_ => {
          this.setState({ lL: false, lE: false, lM: "", lS: true });
        })
        .catch(error => {
          this.setState({ lL: false, lE: true, lM: error, lS: false });
        });
    }
  };

  render() {
    return <UI {...this} {...this.props} {...this.state} />;
  }
}

export default New;
