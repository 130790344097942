import React from "react";

import { Form, Card, Header, Message } from "semantic-ui-react";
import validator from "validator";

const UI = ({ lL, lE, lM, lS, email, prev, handleChange, handleSubmit }) => (
  <Card.Content>
    <Card.Header textAlign="center" as={Header} size="huge">
      Reset Password
    </Card.Header>
    <Form loading={lL} error={lE} success={lS} onSubmit={handleSubmit}>
      <Form.Input
        fluid
        name="email"
        placeholder="Email..."
        type="email"
        autoComplete="email"
        iconPosition="left"
        icon="at"
        value={email}
        onChange={handleChange}
        error={!validator.isEmail(email)}
        label="Email"
      />
      <Message error header="Failed" content={lM} />
      <Message
        success
        header="Success"
        content={`A reset link was sent to ${prev}`}
      />
      <Form.Button
        primary
        floated="right"
        content="Submit"
        labelPosition="right"
        icon="check"
        disabled={!validator.isEmail(email)}
      />
    </Form>
  </Card.Content>
);

export default UI;
