import React from "react";

import Logo from "./../../assets/images/logo-white-front.png";
import { Card, Image } from "semantic-ui-react";
import { Redirect, Route, Switch, Link } from "react-router-dom";

import SignIn from "./SignIn";
import ResetPassword from "./ResetPassword";
import SetPassword from "./SetPassword";

class Public extends React.Component {
  render() {
    return (
      <div id="app" className="public-forms">
        <div>
          <Image src={Logo} size="small" centered />
          <Card>
            <Switch>
              <Route exact path="/" component={SignIn} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route
                exact
                path="/0/:code"
                component={props => <SetPassword {...props.match.params} />}
              />
            </Switch>
          </Card>
          <Switch>
            <Redirect exact from="/sign-out" to="/" />
            <Route
              exact
              path="/"
              component={props => (
                <Link to="/reset-password">Forgot password?</Link>
              )}
            />
            <Route
              exact
              path="/reset-password"
              component={props => <Link to="/">Sign in instead?</Link>}
            />
            <Route
              exact
              path="/0/:code"
              component={props => <Link to="/">Sign in instead?</Link>}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Public;
