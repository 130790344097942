import React from "react";

import { Link } from "react-router-dom";

import { Button } from "semantic-ui-react";

import ContentLoader from "./../../../../../../components/Loader";
import DataTable from "./../../../../../../components/DataTable";

const UI = ({ lL, lE, lM, lR, partners, project_id, history }) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Fetching partners..."
    showContentMessage="You have no partner!"
    showContentAction={{
      link: `/projects/${project_id}/partners/new`,
      text: "Invite Partners"
    }}
    showContent={partners.length !== 0}
  >
    <DataTable
      title="Partners"
      history={history}
      more={
        <Button as={Link} to={`/projects/${project_id}/partners/new`} primary>
          Invite Partners
        </Button>
      }
      dataKey="id"
      empty="No partner."
      data={partners}
      columns={[
        {
          dataField: "name",
          text: "Partner",
          sort: true,
          formatter: "name",
          link: ["projects", "[(project_id)]", "partners", "[(id)]"]
        },
        {
          dataField: "agents",
          text: "Agents",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "saving_groups",
          text: "Saving Groups",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "members",
          text: "Members",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "budget",
          text: "Budget",
          textPrefix: " (RWF)",
          sort: true,
          formatter: "number"
        },
        // {
        //   dataField: "duration",
        //   text: "Duration",
        //   textPrefix: "(Years)",
        //   sort: true,
        //   formatter: "_"
        // }     
      ]}
    />
  </ContentLoader>
);

export default UI;
