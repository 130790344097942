import React from "react";
import { GET } from "./../../../../../../utils/api";
import queryString from "query-string"; 
import UI from "./UI";

class ConfirmAction extends React.Component {
  state = {
    user: {},
    lL: false,
    lE: false,
    lM: "",
    lS: false,
    lR: null,
    errorVisible:false,
    userStatusAction:"",
  };

  componentDidMount = () => {
    const {action: userStatusAction}=queryString.parse(this.props.location.search);
    this.setState({userStatusAction});
    this.handleFetch();


  }

  handleFetch = () => {
    let { id } = this.props;

    this.setState({ lL: true, lE: false, lM: "", lS: false, lR: null });

    GET(`save-ssa/user/${id}`)
      .then(data => {
        this.setState({
          user: data,
          lL: false,
          lE: false,
          lM: "",
          lS: false,
          lR: null
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lS: false,
          lR: this.handleFetch
        });
      });
  };

  handleUserAction= () => {
    let { user,userStatusAction } = this.state;
    let { id, work_email } = user;
    let { action } = this.props;
    action=userStatusAction||action
    let massages = {
      "reset-password": "Reset password email successfully sent!",
      deactivate: "User deactivated!",
      activate:"User activated!"
    };

    let actions = {
      "reset-password": ()=>GET(`save-ssa/forgot_pwd/${work_email}`),
      deactivate: ()=>GET(`save-ssa/user/${id}/deactivate`),
      activate:()=>GET(`save-ssa/user/${id}/activate`),
    };

    this.setState({ lL: true, lE: false, lM: "", lS: false, lR: null });
    actions[action]()
    .then(res=> {
        this.setState({
          lL: false,
          lE: false,
          lM: massages[action],
          lS: true,
          lR: null
        });
      })
      .catch(error => {
        this.setState({ 
          lL: false, 
          lE: true,
          errorVisible:true, 
          lM: error, 
          lS: false, 
          lR: null });
      });
  };

  handleErrorDismissal=()=>{
    this.setState({errorVisible:false})
    }

  render = () =>{
    let { userStatusAction } = this.state;
    let { action } = this.props;
   return(<UI {...this.props} {...this.state} {...this} action={userStatusAction||action} />);
  } 
}

export default ConfirmAction;
