import React from "react";
import UI from "./UI";

class List extends React.Component {
  state = {
    sgs: [],
    lL: false,
    lE: false,
    lM: "",
    lR: null
  };
  componentDidMount = () => this.handleFetch();

  handleFetch = () => {
    let { saving_groups } = this.props;
    this.setState({ lL: true, lE: false, lM: "", lR: null });
    this.setState({
      sgs: saving_groups,
      lL: false,
      lE: false,
      lM: "",
      lR: null
    });

  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default List;
