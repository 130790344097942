import React from "react";
import NumberFormat from "react-number-format";

const numberFormat = (c, pre = "", ext = "") => {
  return (
    <NumberFormat
      value={Math.round(c * 1)}
      displayType={"text"}
      thousandSeparator={true}
      renderText={text => {
        return (
          <span>
            {`${pre}`}
            {text}
            {` ${ext}`}
          </span>
        );
      }}
    />
  );
};

export default numberFormat;
