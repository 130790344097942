import React from "react";

import ContentLoader from "./../../../../../../components/Loader";
import DataTable from "./../../../../../../components/DataTable";

const UI = ({ lL, lE, lM, lR, projects, history }) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Fetching projects..."
    showContent={projects.length !== 0}
  >
    <DataTable
      title="Projects"
      history={history}
      dataKey="id"
      empty="No project."
      data={projects}
      columns={[
        {
          dataField: "name",
          text: "Project",
          sort: true,
          formatter: "name",
          link: ["organizations", "[(organization_id)]", "projects", "[(id)]"]
        },
        {
          dataField: "partner_name",
          text: "Partner",
          textIf: ({ partner_name }) =>
            partner_name.length === 0 ? (
              <span style={{ opacity: 0.3 }}>No partner</span>
            ) : (
              partner_name
            ),
          sort: true,
          formatter: "_",
          link: [
            "organizations",
            "[(organization_id)]",
            "projects",
            "[(id)]",
            "partner"
          ],
          disabled: ({ partner_name }) => partner_name.length === 0
        },
        {
          dataField: "savings_groups_count",
          text: "Saving Groups",
          sort: true,
          formatter: "number",
          link: [
            "organizations",
            "[(organization_id)]",
            "projects",
            "[(id)]",
            "saving-groups"
          ]
        },
        {
          dataField: "budget",
          text: "Budget",
          textPrefix: "(RWF)",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "duration",
          text: "Duration",
          sort: true,
          formatter: "_"
        }
      ]}
    />
  </ContentLoader>
);

export default UI;
