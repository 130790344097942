import Base64 from './base-64';

export const baseUrl = process.env.REACT_APP_API_URL;
export const baseUrlFSP = 'http://68.183.58.79:8092/';

// let prod = "apis";
// let dev = "testapi";

// export const baseUrl = `https://${dev}.getsave.io/`;

const apiCall = (url, method, body) => {
  return new Promise((resolve, reject) => {
    let v = '';

    let theUrl = '';

    if (url.startsWith(baseUrl)) {
      theUrl = url;
    } else {
      theUrl = `${baseUrl}${v}${url}`;
    }

    let hash = `${
      url.startsWith('save-ssa') || url.startsWith('save-fsp')
        ? global.token
        : Base64.btoa(global.token_v2 + ':')
      // : global.token_v2
    }`;

    if (['save-ssa/authenticate/web'].includes(url)) {
      hash = Base64.btoa(`${body.username}:${body.password}`);
    }

    let props = {
      method: method,
      headers: {
        Authorization: `Basic ${hash}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    if (method !== 'GET') {
      props.body = JSON.stringify(body);
    }

    console.log(theUrl);
    console.log(JSON.stringify(props, true, 4));

    fetch(theUrl, props)
      .then(response => {
        var retObj = {
          '401': {
            ok: false,
            status: response.status,
            message: 'Invalid credentials! Check and try again.',
          },
          '401_': {
            ok: false,
            status: response.status,
            message: 'Session Expired! Sign in again.',
          },
          '404': {
            ok: false,
            status: response.status,
            message: "Ooops! Can't find what you're looking for.",
          },
        };
        if (response.status === 401) {
          if (['save-ssa/authenticate/web'].includes(url)) {
            throw retObj['401'];
          } else {
            global.signInPopup(retObj['401_']);
            throw retObj['401_'];
          }
        } else if (response.status === 404) {
          throw retObj['404'];
        } else {
          return response.json();
        }
      })
      .then(responseJson => {
        console.log(JSON.stringify(responseJson, true, 4));
        var retObj = {};
        if (typeof responseJson === 'undefined' || responseJson === null) {
          retObj.message = 'Something went wrong';
          throw retObj;
        } else if (
          responseJson.status === '01' ||
          responseJson.hasOwnProperty('error')
        ) {
          retObj = responseJson;
          retObj.message = responseJson.description;
          throw retObj;
        } else {
          resolve(responseJson);
        }
      })
      .catch(error => {
        console.log(JSON.stringify(error, true, 4));
        reject(error.message);
      });
  });
};

export const GET = (url, body = {}) => {
  return apiCall(url, 'GET', body);
};

export const POST = (url, body = {}) => {
  return apiCall(url, 'POST', body);
};

export const DELETE = (url, body = {}) => {
  return apiCall(url, 'DELETE', body);
};

export const PUT = (url, body = {}) => {
  return apiCall(url, 'PUT', body);
};

export default apiCall;
