import React from "react";
import UI from "./UI";

class Users extends React.Component {
  render() {
    return <UI {...this.props} />;
  }
}

export default Users;
