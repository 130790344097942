import React from "react";
import { GET } from "./../../../../../../utils/api";
import UI from "./UI";

class List extends React.Component {
  state = {
    partners: [],
    lL: false,
    lE: false,
    lM: "",
    lR: null
  };

  componentDidMount = () => this.handleFetch();

  handleFetch = () => {
    let { project_id } = this.props;
    this.setState({ lL: true, lE: false, lM: "", lR: null });

    GET(`save-ssa/federations/${project_id}/unions/`)
      .then(data => {
        let partners = [];

        data.map(p => {
          if (p.union.type === 6) {
            let d = { ...p };
            delete d.union;
            partners.push({
              id: p.union.id,
              name: p.union.name,
              ...d,
              project_id
            });
          }
          return p;
        });
        this.setState({
          partners,
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: null });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default List;
