import React from "react";
import Toolbar from "./../../../../components/Toolbar";
import { fspToolbar } from "./../../../../utils/vars";
import { Switch, Route } from "react-router-dom";
import { GET } from "./../../../../utils/api";
import ContentLoader from "./../../../../components/Loader";

import Overview from "./Overview";
import Agents from "./Agents";
import SavingGroups from "./SavingGroups";
import Settings from "./Settings";
import Requests from "./Requests";

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {},
      saving_groups: [],
      agents: [],
      requests: [],
      lL: false,
      lE: false,
      lM: "",
      lR: null
    };
  }

  componentDidMount = () => this.fetchData();

  fetchData = () => {
   let { profile_id, defaultAreas, userOrg } = this.props;
    this.setState({ lL: true, lE: false });

    Promise.all([
      GET(`save-fsp/organization/${userOrg.id}/details/${profile_id}`),
      GET(`save-fsp/profile/${profile_id}/intervention_areas`)
    ])
      .then(data => {
        let p = {};
        this.setState({
          lL: false,
          lE: false,
          lM: "",
          lR: null,
          saving_groups: data[0].savings_group ? data[0].savings_group : [],
          agents: data[0].agents ? data[0].agents : [],
          requests: data[0].group_request ? data[0].group_request : [],
          profile: {
            ...p,
            ...(() => {
              let d = { ...data[0] };
              return d;
            })(),
            areas: (() => {
              let areas = { ...defaultAreas, provinces: [] };

              let villages = [],
                cells = [],
                sectors = [],
                districts = [],
                provinces = [];

              data[1].map(a => {
                let {
                  village,
                  cell,
                  sector,
                  district,
                  province
                } = a.location_details;

                villages.push(village.id);
                areas.village.push(village.id);
                areas.villages.push({
                  text: village.name,
                  value: village.id,
                  key: village.id,
                  cell: cell.id,
                  sector: sector.id,
                  district: district.id,
                  province: province.id
                });

                if (!cells.includes(cell.id)) {
                  cells.push(cell.id);
                  areas.cell.push(cell.id);
                  areas.cells.push({
                    text: cell.name,
                    value: cell.id,
                    key: cell.id,
                    sector: sector.id,
                    district: district.id,
                    province: province.id
                  });
                }

                if (!sectors.includes(sector.id)) {
                  sectors.push(sector.id);
                  areas.sector.push(sector.id);
                  areas.sectors.push({
                    text: sector.name,
                    value: sector.id,
                    key: sector.id,
                    district: district.id,
                    province: province.id
                  });
                }

                if (!districts.includes(district.id)) {
                  districts.push(district.id);
                  areas.district.push(district.id);
                  areas.districts.push({
                    text: district.name,
                    value: district.id,
                    key: district.id,
                    province: province.id
                  });
                }

                if (!provinces.includes(province.id)) {
                  provinces.push(province.id);
                  areas.province.push(province.id);
                  areas.provinces.push({
                    text: province.name,
                    value: province.id,
                    key: province.id
                  });
                }

                return a;
              });

              return areas;
            })()
          }
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.fetchData
        });
      });
  };

  render() {
    let { profile_id } = this.props.match.params;
    let { profile, lL, lE, lM, lR, saving_groups, agents, requests } = this.state;
    return (
      <ContentLoader
        lL={lL}
        lE={lE}
        lM={lM}
        lR={lR}
        message="Wait a moment..."
        showContent={Object.keys(profile).length !== 0}
      >
        <div className="pannels-column">
         <Toolbar prefix={`/profiles/${profile_id}`} links={fspToolbar} />
          <Switch>
            <Route
              exact
              path={`/profiles/${profile_id}`}
              render={props => (
                <Overview
                  {...this.props}
                  profile={profile}
                  update={() => this.fetchData()}
                  {...props}
                  profile_id={profile_id}
                />
              )}
            />
            <Route
              path={`/profiles/${profile_id}/requests`}
              render={props => (
                <Requests
                  {...this.props}
                  profile={profile}
                  update={() => this.fetchData()}
                  {...props}
                  profile_id={profile_id}
                  requests={requests}
                />
              )}
            />
            <Route
              path={`/profiles/${profile_id}/agents`}
              render={props => (
                <Agents
                  {...this.props}
                  profile={profile}
                  update={() => this.fetchData()}
                  {...props}
                  profile_id={profile_id}
                  agents={agents}
                />
              )}
            />
            <Route
              path={`/profiles/${profile_id}/saving-groups`}
              render={props => (
                <SavingGroups
                  {...this.props}
                  profile={profile}
                  update={() => this.fetchData()}
                  {...props}
                  profile_id={profile_id}
                  saving_groups={saving_groups}
                />
              )}
            />
            <Route
              path={`/profiles/${profile_id}/settings`}
              render={props => (
                <Settings
                  {...this.props}
                  profile={profile}
                  update={() => this.fetchData()}
                  {...props}
                  profile_id={profile_id}
                />
              )}
            />
          </Switch>
        </div>
      </ContentLoader>
    );
  }
}

export default Profile;
