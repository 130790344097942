import React from "react";

import { Link } from "react-router-dom";

import { Button } from "semantic-ui-react";

import ContentLoader from "./../../../../../../components/Loader";
import DataTable from "./../../../../../../components/DataTable";

const UI = ({ lL, lE, lM, lR, partners, project_id, history }) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Fetching cooperatives..."
    showContentMessage="You have no cooperative!"
    showContentAction={{
      link: `/federations/${project_id}/cooperatives/new`,
      text: "Add Cooperative"
    }}
    showContent={partners.length !== 0}
  >
    <DataTable
      title="Cooperatives"
      history={history}
      more={
        <Button
          as={Link}
          to={`/federations/${project_id}/cooperatives/new`}
          primary
        >
          Add Cooperative
        </Button>
      }
      dataKey="id"
      empty="No cooperative."
      data={partners}
      columns={[
        {
          dataField: "name",
          text: "Union",
          sort: true,
          formatter: "name",
          link: ["federations", "[(project_id)]", "cooperatives", "[(id)]"]
        },
        {
          dataField: "admins_count",
          text: "Admins",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "sg_count",
          text: "Saving Groups",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "member_count",
          text: "Number Of Members",
          sort: true,
          formatter: "number"
        }
      ]}
    />
  </ContentLoader>
);

export default UI;
