const nTh = (i, v = true) => {
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return (v ? i : "") + "st";
  }
  if (j === 2 && k !== 12) {
    return (v ? i : "") + "nd";
  }
  if (j === 3 && k !== 13) {
    return (v ? i : "") + "rd";
  }
  return (v ? i : "") + "th";
};

export default nTh;
