import React from "react";
import { Switch, Route } from "react-router-dom";
import List from "./List";
import Project from "./Project";

const UI = props => (
  <Switch>
    <Route
      exact
      path={`/organizations/${props.org_id}/projects`}
      render={props_ => <List {...props} {...props_} />}
    />
    <Route
      path={`/organizations/${props.org_id}/projects/:project_id`}
      render={props_ => (
        <Project
          {...props}
          {...props_}
          project_id={props_.match.params.project_id}
        />
      )}
    />
  </Switch>
);

export default UI;
