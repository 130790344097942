import React from "react";
import { Switch, Route } from "react-router-dom";
import List from "./List";
import SavingGroup from "./SavingGroup";

const UI = props => (
  <Switch>
    <Route
      exact
      path={`/federations/${props.project_id}/saving-groups`}
      render={props_ => <List {...props} {...props_} />}
    />
    <Route
      path={`/federations/${props.project_id}/saving-groups/:sg_id`}
      render={props_ => <SavingGroup {...props} {...props_} />}
    />
  </Switch>
);

export default UI;
