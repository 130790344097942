import React from "react";
import List from "./List";
import User from "./User";
import Add from "./Add";
import ConfirmAction from "./ConfirmAction";
import ChangePassword from "./ChangePassword";
import { Switch, Route } from "react-router-dom";

const UI = props => (
  <Switch>
    <Route
      exact
      path={`/users`}
      render={props_ => <List {...props} {...props_} />}
    />
    <Route
      exact
      path={`/users/add`}
      render={props_ => (
        <Add {...props} {...props_} organization_id={props.userOrg.id} />
      )}
    />
    <Route
      exact
      path={`/users/:user_id/change-password`}
      render={props_ => (
        <ChangePassword
          {...props}
          {...props_}
          id={props_.match.params.user_id}
        />
      )}
    />
    <Route
      exact
      path={`/users/:user_id/deactivate`}
      render={props_ => (
        <ConfirmAction
          {...props}
          {...props_}
          action="deactivate"
          id={props_.match.params.user_id}
        />
      )}
    />
    <Route
      exact
      path={`/users/:user_id/reset-password`}
      render={props_ => (
        <ConfirmAction
          {...props}
          {...props_}
          action="reset-password"
          id={props_.match.params.user_id}
        />
      )}
    />
    <Route
      path={`/users/:user_id`}
      render={props_ => (
        <User {...props} {...props_} id={props_.match.params.user_id} />
      )}
    />
  </Switch>
);

export default UI;
