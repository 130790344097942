import React from "react";
import { Switch, Route } from "react-router-dom";
import List from "./List";
import SavingGroup from "./SavingGroup";

const UI = props => (
  <Switch>
    <Route
      exact
      path={`/organizations/${props.id}/saving-groups`}
      render={props_ => <List {...props} {...props_} id={props.id} />}
    />
    <Route
      path={`/organizations/${props.id}/saving-groups/:sg_id`}
      render={props_ => <SavingGroup {...props} {...props_} id={props.id} />}
    />
  </Switch>
);

export default UI;
