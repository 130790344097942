import React from "react";
import UI from "./UI";
var moment = require('moment');

class List extends React.Component {
  state = {
    requestssss: [],
    requests: []
  };
  componentDidMount = () => {
      this.handleFetch();

      var dummyRequest = JSON.parse('{"request_id": "1" , "saving_group": "Abishyizehamwe", "members": "36", "amount": "3654737", "repayment_date": "2012-05-09T00:00:00Z", "savings": "2771124", "status": "Declined"}');
      var dummyRequest2 = JSON.parse('{"request_id": "1" , "saving_group": "Abishyizehamwe", "members": "36", "amount": "3654737", "repayment_date": "2012-05-09T00:00:00Z", "savings": "2771124", "status": "Approved"}');
      var dummyRequest3 = JSON.parse('{"request_id": "1" , "saving_group": "Abishyizehamwe", "members": "36", "amount": "3654737", "repayment_date": "2012-05-09T00:00:00Z", "savings": "2771124", "status": "Pending"}');
      var dummyRequests = [];
      var i;

      for (i = 0; i < 99; i++) {
          dummyRequests.push(dummyRequest);
          dummyRequests.push(dummyRequest2);
          dummyRequests.push(dummyRequest3);
      }

      let requestssssToState = dummyRequests.map(e => {
        e = {...e, repayment_date: moment("2012-05-09T00:00:00Z").format('DD/MM/YYYY') };
       return e;
      });
      this.setState({
        requestssss: requestssssToState
      });
  }  
  
  handleFetch = () => {
    let { requests } = this.props;
    this.setState({
            requests: [...requests.map(a => {
              let returnData = [];
              let statuses = [
                ["pending", {...a.pending}],
                ["approved", {...a.approved}],
                ["declined", {...a.declined}]
              ];
              let x = null;
            
              for (let [key, status] of statuses) {
                x = {
                  saving_group_id: a.id.toString(),
                  saving_group_name: a.name.toString(),
                  members: a.members.toString(),
                  status: key,
                  amount: status.amount.toString(),
                  savings: status.savings,
                  outstanding_loans: status.outstanding
                };
                returnData.push(x);
            
              }
              return returnData;   
            })],
            lL: false,
            lE: false,
            lM: "",
            lR: null
          });
  };

  render = () => {
  return <UI {...this.props} {...this.state} {...this} />};
}

export default List;
