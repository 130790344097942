import React from "react";

import {
  Button,
  Icon,
  Sidebar,
  Segment,
  Form,
  Input,
  Select,
  TextArea,
  Message,
  Grid
} from "semantic-ui-react";
import Box from "./../../../../../components/Box";
import ContentLoader from "./../../../../../components/Loader";
import phoneVerify from "./../../../../../utils/phoneVerify";
import validator from "validator";

import DataTable from "./../../../../../components/DataTable";

const UI = ({
  userOrg,
  activity,
  topup,
  credit,
  phone,
  amount,
  mtn,
  tigo,
  orgs,
  agents,
  sgs,
  org,
  sg,
  agent,
  description,
  beneficiary,
  handleChange,
  activateTopup,
  activateCredit,
  history,
  handleCredit,
  handleTopup,
  lL,
  lE,
  lM,
  lR,
  sL,
  sE,
  sM,
  sR,
  uL,
  uE,
  uM,
  uS
}) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Working..."
    showContent={true}
  >

    <Sidebar.Pushable className="my-filter-panel">

      <Sidebar
        as={Segment}
        direction="right"
        animation="overlay"
        visible={topup}
        width="wide"
        className="credit-topup"
      >  
     
        <div className="padding">
          <div className="header">
            <div className="back" onClick={() => activateTopup(!topup)}>
              <Icon name="arrow left" fitted size="large" />
            </div>
            <span className="title">Top-up</span>
          </div>
        </div>
        <div className="padding">
          <Form loading={uL} success={uS} error={uE} onSubmit={handleTopup}>
            <Form.Field
              id="input-phone"
              name="phone"
              value={phone}
              control={Input}
              label="Source"
              placeholder="Phone..."
              onChange={handleChange}
              error={!phoneVerify(phone).ok}
            />
            <Form.Field
              id="input-amount"
              name="amount"
              value={amount}
              control={Input}
              label="Amount"
              placeholder="Amount..."
              onChange={handleChange}
              error={!validator.isInt(`${amount}`)}
            />
            <Message error content={uM} />
            <Message success content="Top-up successfully initiated!" />
            <Form.Button primary floated="right">
              Initiate
            </Form.Button>
          </Form>
        </div>
      </Sidebar>
      <Sidebar
        as={Segment}
        direction="right"
        animation="overlay"
        visible={credit}
        width="wide"
        className="credit-topup"
      >
        <div className="pannel-scroll">
          <div className="padding">
            <div className="header">
              <div className="back" onClick={() => activateCredit(!credit)}>
                <Icon name="arrow left" fitted size="large" />
              </div>
              <span className="title">Credit</span>
            </div>
          </div>
          <div className="padding">
            <Form loading={uL} success={uS} error={uE} onSubmit={handleCredit}>
              <Form.Field
                name="org"
                value={org}
                control={Select}
                options={orgs}
                label={{ children: "Organization", htmlFor: "input-org" }}
                placeholder="Organization..."
                search
                searchInput={{ id: "input-org" }}
                onChange={handleChange}
                error={!validator.isInt(`${org}`)}
                disabled={sL || sE}
              />
              {!validator.isEmpty(`${org}`) && (
                <Form.Field
                  name="beneficiary"
                  value={beneficiary}
                  control={Select}
                  options={[
                    { value: 0, key: 0, text: "Saving Group" },
                    { value: 1, key: 1, text: "Agent" }
                  ]}
                  label={{ children: "Beneficiary", htmlFor: "input-ben" }}
                  placeholder="Beneficiary..."
                  search
                  searchInput={{ id: "input-ben" }}
                  onChange={handleChange}
                  error={!validator.isInt(`${beneficiary}`)}
                  disabled={sL || sE}
                />
              )}
              {beneficiary === 0 && !validator.isEmpty(`${org}`) && (
                <Form.Field
                  name="sg"
                  value={sg}
                  control={Select}
                  options={sgs}
                  label={{ children: "Saving Group", htmlFor: "input-sg" }}
                  placeholder="Saving group..."
                  search
                  searchInput={{ id: "input-sg" }}
                  onChange={handleChange}
                  error={!validator.isInt(`${sg}`)}
                  loading={sL}
                />
              )}
              {beneficiary === 1 && !validator.isEmpty(`${org}`) && (
                <Form.Field
                  name="agent"
                  value={agent}
                  control={Select}
                  options={agents}
                  label={{ children: "Agent", htmlFor: "input-agent" }}
                  placeholder="Agent..."
                  search
                  searchInput={{ id: "input-agent" }}
                  onChange={handleChange}
                  error={!validator.isInt(`${agent}`)}
                  loading={sL}
                />
              )}
              {sE && (
                <Message
                  error
                  content={
                    <div className="areaError">
                      <span>{sM}</span>
                      <span onClick={() => sR()}>Retry...</span>
                    </div>
                  }
                />
              )}
              <Form.Field
                id="input-description"
                name="description"
                value={description}
                control={TextArea}
                label="Description"
                placeholder="Description..."
                onChange={handleChange}
                error={validator.isEmpty(description)}
              />
              <Form.Field
                id="input-amount"
                name="amount"
                value={amount}
                control={Input}
                label="Amount"
                placeholder="Amount..."
                onChange={handleChange}
                error={!validator.isInt(`${amount}`)}
              />
              <Message error content={uM} />
              <Message
                success
                content={`${
                  beneficiary === 0 ? "Saving group" : "Agent"
                }'s wallet successfully credited!`}
              />
              <Form.Button primary floated="right">
                Credit {beneficiary === 0 ? "Saving Group" : "Agent"}
              </Form.Button>
            </Form>
          </div>
        </div>
      </Sidebar>
      <Sidebar.Pusher style={{ overflow: "unset" }}>
        <div className="pannels-column the-wallet">
          <div className="padding some-sticky-stuff">
            <div className="header">
              {history && history.goBack && (
                <div className="back" onClick={() => history.goBack()}>
                  <Icon name="arrow left" fitted size="large" />
                </div>
              )}
              <span className="title">Wallet: {userOrg.name}</span>
              <Button primary onClick={() => activateTopup(!topup)}>
                Top-up
              </Button>
              <div className="padder" />
              <Button primary onClick={() => activateCredit(!topup)}>
                Credit
              </Button>
            </div>
            <Grid columns={3}>
              <Grid.Row>
                <Grid.Column>
                  <Box
                    title="CURRENT BALANCE"
                    value={userOrg.more_data.org_wallet_balance}
                    unit="RWF"
                    color="green"
                    noChart
                  />
                </Grid.Column>
                <Grid.Column>
                  <Box
                    title="MTN BALANCE"
                    value={mtn}
                    unit="RWF"
                    color="yellow"
                    noChart
                  />
                </Grid.Column>
                <Grid.Column>
                  <Box
                    title="TIGO BALANCE"
                    value={tigo}
                    unit="RWF"
                    color="blue"
                    noChart
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>

          <div className="some-sticky-table-stuff" style = {{ overflow: "auto", height: "calc(100vh - 275px)" }}> 
            <DataTable
              title=""
              dataKey="id"
              empty="No recent activity."
              data={activity}
              columns={[
                {
                  dataField: "id",
                  text: "ID",
                  sort: true,
                  formatter: "_",
                  tooltip: "trx_id",
                  classes: "c-2"
                },
                {
                  dataField: "creditor",
                  text: "Originator/Creditor",
                  sort: true,
                  formatter: "name"
                },
                {
                  dataField: "recipient",
                  text: "Recipient",
                  sort: true,
                  formatter: "_"
                },
                {
                  dataField: "date",
                  text: "Date",
                  sort: true,
                  formatter: "_"
                },
                {
                  dataField: "amount",
                  text: "Amount",
                  textPrefix: "(RWF)",
                  sort: true,
                  formatter: "number"
                },
                {
                  dataField: "channel",
                  text: "Channel",
                  sort: true,
                  formatter: "_"
                },
                {
                  dataField: "reason",
                  text: "Reason",
                  sort: true,
                  formatter: "_"
                },
                {
                  dataField: "status",
                  text: "Status",
                  sort: true,
                  formatter: "_",
                  colors: {
                    Pending: "white",
                    Processing: "yellow",
                    Completed: "green",
                    Failed: "red"
                  }
                }
              ]}
            />
          </div>
        </div>
      </Sidebar.Pusher>

    </Sidebar.Pushable>

  </ContentLoader>
);

export default UI;
