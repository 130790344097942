import React from "react";
import { POST } from "./../../../../../utils/api";
import formErrors from "./../../../../../utils/formErrors";
import phoneVerify from "./../../../../../utils/phoneVerify";
import UI from "./UI";

class User extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      org_id: "",
      org_name: "",
      org_email: "",
      org_phone: "",
      org_type: "",
      org_address: "",
      org_country: "",
      lL: false,
      lE: false,
      lM: "",
      lS: false
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, lE: false, lS: false });
  };

  handleSave = (...e) => {
    const {
      org_name,
      org_email,
      org_phone,
      org_type,
      org_address,
      org_country
    } = this.state;
    const { currentUser } = this.props;
    if (formErrors(e).ok) {
      this.setState({ lL: true, lE: false, lM: "", lS: false });
      POST(`save-ssa/organization/create`, {
        created_by: currentUser.id,
        name: org_name,
        type: org_type,
        email: org_email,
        phone: phoneVerify(org_phone).phone,
        address: org_address,
        country: org_country
      })
        .then(data => {
          this.setState({
            org_id: data.id,
            lL: false,
            lE: false,
            lM: "",
            lS: true
          });
        })
        .catch(error => {
          this.setState({ lL: false, lE: true, lM: error, lS: false });
        });
    }
  };

  render() {
    return <UI {...this} {...this.props} {...this.state} />;
  }
}

export default User;
