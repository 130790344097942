import React from "react";

import ContentLoader from "../../../../../../../components/Loader";
import DataTable from "../../../../../../../components/DataTable";

const UI = ({ lL, lE, lM, lR, sgs, history }) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Fetching saving groups..."
    showContent={sgs.length !== 0}
  >
    <DataTable
      title="Saving Groups"
      history={history}
      dataKey="id"
      empty="No saving group."
      data={sgs}
      columns={[
        {
          dataField: "name",
          text: "Saving Group",
          sort: true,
          formatter: "name",
          link: [
            "organizations",
            "[(organization_id)]",
            "saving-groups",
            "[(id)]"
          ],
          disabled: ({ activate_status, status }) =>
            activate_status === 0 && status === 2,
          badge: {
            show: ({ activate_status, status }) =>
              activate_status === 0 && status === 2,
            text: "Inactive"
          }
        },
        {
          dataField: "sg_members_count",
          text: "Members",
          sort: true,
          formatter: "number",
          link: [
            "organizations",
            "[(organization_id)]",
            "saving-groups",
            "[(id)]",
            "members"
          ],
          disabled: ({ activate_status, status }) =>
            activate_status === 0 && status === 2
        },
        {
          dataField: "total_savings",
          text: "Total Savings",
          textPrefix: "(RWF)",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "total_loans",
          text: "Total Loans",
          textPrefix: "(RWF)",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "wallet_balance",
          text: "Wallet Balance",
          textPrefix: "(RWF)",
          sort: true,
          formatter: "number"
        }
      ]}
    />
  </ContentLoader>
);

export default UI;
