import React from "react";

import { GET } from "./../../../../../../utils/api";

import UI from "./UI";

class List extends React.Component {
  state = {
    agents: [],
    lL: false,
    lE: false,
    lM: "",
    lR: null
  };
  componentDidMount = () => this.handleFetch();

  handleFetch = () => {
    let { project_id } = this.props;

    this.setState({ lL: true, lE: false, lM: "", lR: null });

    GET(`save-ssa/federations/${project_id}/admins`)
      .then(data => {
        this.setState({
          agents: data.map(agent => ({
            name: agent.user.name,
            id: agent.user.id,
            sg_count: agent.sg_count,
            union: agent.union.name,
            union_id: agent.union.id,
            cooperative: agent.cooperative.name,
            cooperative_id: agent.cooperative.id,
            project_id
          })),
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: null });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default List;
