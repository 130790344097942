import React from "react";
import { NavLink, Switch, Route, Link } from "react-router-dom";

import { Button, Grid } from "semantic-ui-react";
import Box from "./../../../../components/Box";
import Boxx from "./../../../../components/Boxx";
import Activity from "./Activity";
import Users from "./Users";
import Settings from "./Settings";
import Overview from "./Overview";
import Profiles from "./../Profiles";

const UI = props => (
  <div className="pannels-row">
    <div className="sidebar">
      <div className="header">
        <span className="title">Dashboard</span>
      </div>
      <NavLink to={`/`} exact activeClassName="active">
        Activity
      </NavLink>
      <NavLink to={`/overview`} exact activeClassName="active">
        Overview
      </NavLink>
      <NavLink to={`/users`} activeClassName="active">
        Users
      </NavLink>
      <NavLink to={`/settings`} exact activeClassName="active">
        Settings
      </NavLink>
    </div>
    <div className="pannels-column">
      <Switch>
        <Route
          exact
          path={`/`}
          render={props_ => <Activity {...props} {...props_} />}
        />
        <Route
          path={`/users`}
          render={props_ => <Users {...props} {...props_} />}
        />
        <Route
          exact
          path={`/settings`}
          render={props_ => <Settings {...props} {...props_} />}
        />
        <Route
          exact
          path={`/overview`}
          render={props_ => <Overview {...props} {...props_} />}
        />
        <Route
          exact
          path={`/profiles`}
          render={props_ => <Profiles {...props} {...props_} />}
        />
      </Switch>
    </div>
    <Switch>
      <Route
        exact
        path={`/`}
        render={props_ => (
          <div style={{ width: "530px" }}>
            <div className="pannel-scroll">
              <div className="padding">
                <div className="header">
                  <span className="title">Profile summary</span>
                  <Button
                    size="small"
                    as={Link}
                    to={`/profiles`}
                    content="View All"
                  />
                </div>
              </div>
              <div style={{ marginLeft: "30px" }}>
                <Grid style={{ marginLeft: "0px" }}>
                  <Boxx
                    title="Total Savings"
                    value={props.overview.savings_amount}
                    chart={{
                      data: props.overview.saving_loans_distributions,
                      key: "savings_amount"
                    }}
                    
                    title2="Total Loans"
                    value2={props.overview.loan_amount}
                    chart2={{
                      data: props.overview.saving_loans_distributions,
                      key: "loans_amount"
                    }}
                    barChart2
                  />
                </Grid>

                <Grid columns={2} style={{ marginRight: "0px" }}>
                    <Grid.Row>
                      <Grid.Column>
                        <Box
                          title="TOTAL SAVINGS"
                          value={props.overview.savings_amount}
                          unit="RWF"
                          color="orange"
                          chart={{
                            data: props.overview.saving_loans_distributions,
                            key: "savings_amount"
                          }}
                          slightHeight
                          lineChart
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <Box
                          title="SAVING GROUPS"
                          value={props.overview.sg_count}
                          color="red"
                          chart={{
                            data: props.overview.sg_enrollment_per_month,
                            key: "count"
                          }}
                          slightHeight
                          lineChart
                        />  
                      </Grid.Column>
                    </Grid.Row>
                </Grid>
              </div>
            </div>
          </div>
        )}
      />
    </Switch>
  </div>
);

export default UI;
