const careateLink = (item, chunks) => {
  let link = {};

  if (chunks.length > 0) {
    let to = "";

    chunks.map(chunk => {
      if (chunk.includes(`[(`) && chunk.includes(`)]`)) {
        to += `/${item[chunk.substring(2, chunk.length - 2)]}`;
      } else {
        to += `/${chunk}`;
      }
      return chunk;
    });

    link = { to };
  }

  return link;
};

export default careateLink;
