import React from "react";
import { Grid } from "semantic-ui-react";
import Box from "./../../../../../components/Box";

var moment = require("moment");

const UI = ({
  overview,
  cumulativeVsCurrentSavings,
  cumulativeVsOutstandingLoans
}) => (
  <div className="pannel-scroll">
    <div className="padding">
    <Grid columns={5}>
        <Grid.Row stretched>
          <Grid.Column width={5}>
            <Box
              title="SAVING GROUPS"
              value={overview.sg_count}
              color="green"
              chart={{
                data: overview.sg_enrollment_per_month,
                key: "count"
              }}
              lineChart
            />
            <Box
              title="CUMULATIVE SAVINGS"
              value={overview.savings_amount}
              unit="RWF"
              color="orange"
              chart={{
                data: overview.cumulative_vs_current_savings.cumulative_savings,
                key: "savings"
              }}
              lineChart
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Box
              title="AGENTS"
              value={overview.agent_count}
              color="blue"
              chart={{
                data: overview.agent_enrollment_per_month,
                key: "count"
              }}
              lineChart
            />
            <Box
              title="OUTSTANDING LOANS"
              value={overview.loan_amount}
              unit="RWF"
              color="red"
              chart={{
                data: overview.saving_loans_distributions,
                key: "outstanding_loans_amount"
              }}
              lineChart
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Box
              title="Male Members"
              value={overview.male_members_count}
              chart={{
                data: overview.male_enrollment_per_month,
                key: "count"
              }}
              icon="male"
              barChart
            />
            <Box
              title="Female Members"
              value={overview.female_members_count}
              chart={{
                data: overview.female_enrollment_per_month,
                key: "count"
              }}
              icon="female"
              barChart
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <Box
              title="Cumulative vs Current Savings"
              chart={{
                data: [
                  ...cumulativeVsCurrentSavings.map(d => ({
                    "Cumulative Savings": d.cumulative_savings,
                    "Current Savings": d.current_savings,
                    name: moment(d.month).format("MMM")
                  }))
                ],
                keys: ["Cumulative Savings", "Current Savings"]
              }}
              doubleBarChart
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <Box
              title="Cumulative vs Outstanding Loans"
              chart={{
                data: [
                  ...cumulativeVsOutstandingLoans.map(d => ({
                    "Cumulative Loans": d.cumulative_loans,
                    "Outstanding Loans": d.outstanding_loans,
                    name: moment(d.month).format("MMM")
                  }))
                ],
                keys: ["Cumulative Loans", "Outstanding Loans"]
              }}
              doubleLineChart
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <Box
              title="Members Age Range"
              chart={{
                data: [
                  {
                    name: "18-24",
                    caption: "Members:",
                    count: overview.member_age_groups.from1824
                  },
                  {
                    name: "25-29",
                    caption: "Members:",
                    count: overview.member_age_groups.from2529
                  },
                  {
                    name: "30-34",
                    caption: "Members:",
                    count: overview.member_age_groups.from3034
                  },
                  {
                    name: "35-39",
                    caption: "Members:",
                    count: overview.member_age_groups.from3539
                  },
                  {
                    name: "40-49",
                    caption: "Members:",
                    count: overview.member_age_groups.from4049
                  },
                  {
                    name: "50+",
                    caption: "Members:",
                    count: overview.member_age_groups.above50
                  }
                ],
                key: "count"
              }}
              radialBarChart
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Box
              title="SG Distribution Per Province"
              chart={{
                data: [
                  ...overview.sg_enrollment_per_province.map(d => ({
                    name: d.province_name,
                    "SG Count": d.sg_count
                  }))
                ],
                key: "SG Count"
              }}
              singleBarChart
            />
          </Grid.Column>

          <Grid.Column width={8}>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  </div>
);

export default UI;
