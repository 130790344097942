import React from "react";
import UI from "./UI";

import { GET } from "./../../../../../../../utils/api";

class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      agent: {
        user_details: {},
        members_education_grouping: [],
        enrollment_by_gender_per_month: [],
        location: {
          province: {},
          district: {},
          sector: {},
          cell: {},
          village: {}
        }
      },
      lL: false,
      lE: false,
      lM: "",
      lR: null
    };
  }

  componentDidMount = () => this.fetchData();

  getLocation = data => {
    let agent = { ...data };

    GET(`location/village/${agent.user_details.location}`)
      .then(data_ => {
        agent.location = data_;
        this.setState({
          agent,
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: () => this.getLocation(data)
        });
      });
  };

  fetchData = () => {
    let { agent_id } = this.props;
    this.setState({ lL: true, lE: false });

    GET(`save-ssa/agent/${agent_id}`)
      .then(this.getLocation)
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.fetchData
        });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default Overview;
