import React from "react";

import { GET } from "./../../../../../../utils/api";

import UI from "./UI";

var moment = require('moment');

class SavingGroupRequests extends React.Component {
  state = {
    members_requests: [],
    // lL: false,
    lL: true,
    lE: false,
    lM: "",
    lR: null
  };
  componentDidMount = () => this.handleFetch();

  handleFetch = () => {
    let {sg_id } = this.props;
    Promise.all([
      GET(`save-fsp/requests/${sg_id}`),
      GET(`save-fsp/sg/${sg_id}`)
    ])
    .then(data => {
          this.setState({
            members_requests: [...data[0].map(a => {
              a = {...a, 
                repayment_date: 
                  a.repayment_date !== "" ?  
                    moment(a.repayment_date).format('DD/MM/YYYY') :
                    ""
              };
              return a;
            })],
            sg_name: data[1].saving_group.name,
            lL: false,
            lE: false,
            lM: "",
            lR: null
          });
        })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: null });
      });
  }

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default SavingGroupRequests;
