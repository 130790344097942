const phoneVerify = (
  phone_,
  prefix = 250,
  length = 9,
  starters = [
    { value: "78", label: "MTN" },
    { value: "72", label: "Tigo" },
    { value: "73", label: "Airtel" }
  ]
) => {
  let phone = `${phone_}`.replace(" ", "");
  let label = null;

  if (`${phone}`.length < length) {
    return {
      ok: false,
      phone: `${phone}`,
      phone_: `+250 7XX XXX XXX`,
      phone__: `7XXXXXXXX`,
      label: label
    };
  } else {
    let phone__ = `${phone}`.substring(`${phone}`.length - length);

    if (
      starters
        .map((s, i) => {
          let r = phone__.startsWith(`${s.value}`);
          if (r) {
            label = s.label;
          }
          return r;
        })
        .includes(true)
    ) {
      return {
        ok: true,
        phone: `${prefix}${phone__}`,
        phone_: `(+${prefix}) ${phone__.substr(0, 3)} ${phone__.substr(
          3,
          3
        )} ${phone__.substr(6, 3)}`,
        phone__: `${phone__}`,
        label: label
      };
    } else {
      return {
        ok: false,
        phone: `${phone}`,
        phone_: `+250 7XX XXX XXX`,
        phone__: `7XXXXXXXX`,
        label: label
      };
    }
  }
};

export default phoneVerify;
