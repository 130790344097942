import React from "react";

import { GET } from "../../../../../../../utils/api";
import capitalize from "./../../../../../../../utils/capitalize";

import UI from "./UI";

class Activity extends React.Component {
  state = {
    activity: [],
    tab: 0,
    tabs: ["Transactions", "Requests / Approvals"],
    sg: {},
    filter: false,
    name: "",
    type: "",
    lL: false,
    lE: false,
    lM: "",
    lR: null
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  clearFilters = () => {
    this.setState({ name: "", type: "" });
  };

  requests = [
    "social_fund_request",
    "social_fund_approved",
    "social_fund_declined",
    "loan_request",
    "loan_approved",
    "loan_declined",
    "loan_write_off_request",
    "loan_write_off_declined",
    "loan_write_off_approved",
    "share_out_request",
    "share_out_declined",
    "share_out_approved"
  ];

  types = [
    "savings",
    "social_fund_contribution",
    "social_fund_request",
    "social_fund_approved",
    "social_fund_declined",
    "social_fund_disbursement",
    "loan_request",
    "loan_repayment",
    "loan_disbursement",
    "loan_approved",
    "loan_declined",
    "loan_write_off",
    "loan_write_off_request",
    "loan_write_off_declined",
    "loan_write_off_approved",
    "fine_payment",
    "share_out_request",
    "share_out_declined",
    "share_out_approved",
    "sg_share_out"
  ];

  messages = {
    savings: "saved",
    social_fund_contribution: "contributed a social fund of",
    social_fund_request: "requested a social fund of",
    social_fund_approved: "approved a social fund request of",
    social_fund_declined: "declined a social fund request of",
    social_fund_disbursement: "received a social fund of",
    loan_request: "requested a loan of",
    loan_repayment: "repaid a loan of",
    loan_disbursement: "received a loan of",
    loan_approved: "approved a loan request of",
    loan_declined: "declined a loan request of",
    loan_write_off: "acquired a loan write-off of",
    loan_write_off_request: "requested a loan write-off of",
    loan_write_off_declined: "declined a loan write-off of",
    loan_write_off_approved: "approved a loan write-off of",
    fine_payment: "paid a fine of",
    share_out_request: "requested a share-out of",
    share_out_declined: "declined a share-out of",
    share_out_approved: "approved a share-out of",
    sg_share_out: "did a share-out of"
  };

  componentDidMount = () => this.handleFetch();

  activate = tab => this.setState({ tab, name: "", type: "" });
  activateFilter = filter => this.setState({ filter });

  opt = t => (t === "sg_share_out" ? "Share Out" : t.split("_").join(" "));

  options = () => {
    let { tab } = this.state;
    let { requests, types } = this;

    let options = [
      {
        text: "- All -",
        value: "",
        key: "-all-"
      }
    ];

    types.map(req => {
      if (tab === 0 && !requests.includes(req)) {
        options.push({
          text: capitalize(this.opt(req)),
          value: req,
          key: req
        });
      } else if (tab === 1 && requests.includes(req)) {
        options.push({
          text: capitalize(req.split("_").join(" ")),
          value: req,
          key: req
        });
      }
      return req;
    });

    return options;
  };

  applyFilter = (act, name, type) => {
    if (name === "" && type === "") {
      return true;
    }

    let match1 = name === "",
      match2 = type === "";

    if (!match1) {
      match1 = act.member_name.toLowerCase().includes(name.toLowerCase());
    }

    if (!match2) {
      match2 = act.transaction_type === type;
    }

    return match1 && match2;
  };

  items = () => {
    let { activity, tab, name, type } = this.state;
    let { requests, applyFilter } = this;

    let act = [];
    activity.map(act_ => {
      if (applyFilter(act_, name, type)) {
        if (tab === 0 && !requests.includes(act_.transaction_type)) {
          act.push(act_);
        } else if (tab === 1 && requests.includes(act_.transaction_type)) {
          act.push(act_);
        }
      }
      return act_;
    });
    return act;
  };

  handleFetch = () => {
    let { sg_id, org_id } = this.props;

    this.setState({ lL: true, lE: false, lM: "", lR: null });

    Promise.all([
      GET(`save-ssa/latest_transactions/sg/${sg_id}`),
      GET(`save-ssa/sg/${sg_id}`)
    ])
      .then(data => {
        this.setState({
          activity: data[0].map(item => ({
            ...item,
            sg_id: sg_id,
            org_id: org_id
          })),
          sg: data[1].saving_group,
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: null });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default Activity;
