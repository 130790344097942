import React from "react";
import { Form, Input, Header, Message, Icon, Card } from "semantic-ui-react";
import validator from "validator";
import { types } from "../../../../utils/vars";

import MySelect from "./../../../../components/MySelect";

const UI = ({
  lL,
  lS,
  lE,
  lM,
  lR,
  aL,
  aE,
  aM,
  aR,
  aI,
  name,
  type,
  step,
  handleNextStep,
  handlePreviousStep,
  min_monthly_savings,
  max_monthly_savings,
  min_monthly_loans,
  max_monthly_loans,
  min_age_range,
  max_age_range,
  male_gender_count,
  female_gender_count,
  sg_count_display,
  member_count_display,
  province,
  district,
  sector,
  cell,
  village,
  provinces,
  districts,
  sectors,
  cells,
  villages,
  handleChange
}) => (
  <div className="pannel-scroll">
    <div className="padding">
      
      <div style={{ margin: "0 auto", width: "500px", alignItems: "center" }}>
        <Card fluid>

        <Card.Header>
          {step === 1 && (
            <div
              style={{
                fontWeight: "bold",
                fontSize: "1.5em",
                paddingLeft: "45px"
            }}
            >
              New Profile
            </div>  
          )}
          {step === 2 && (
            <div
              style={{
                fontWeight: "bold",
                fontSize: "1.5em",
                paddingLeft: "45px"
            }}
            >
              <span className="backk" onClick={handlePreviousStep}>
                <Icon name="arrow left" fitted size="small" />
              </span>
              <span 
                style={{
                  fontWeight: "bold",
                  fontSize: "1em"
                }}
              >
                New Profile
              </span>
            </div>  
          )}
        </Card.Header>

        <div className="pannels-row">
          <div className="flex-1" />
          <Form loading={lL} success={lS} error={lE} onSubmit={lR}>
          {step === 1 && (
            <div style={{ paddingBottom: "50px" }}>
            <Header className="steps-header">Savings Groups Details</Header>
            <Header className="header-mod">Name</Header>
            <Form.Field
              id="form-input-name"
              name="name"
              value={name}
              control={Input}
              placeholder="Name..."
              error={validator.isEmpty(name)}
              onChange={handleChange}
            />
            
            <Header className="header-mod">Monthly Savings</Header>
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-min_monthly_savings"
                className="rwf"
                name="min_monthly_savings"
                value={min_monthly_savings}
                control={Input}
                placeholder="Min..."
                error={!validator.isFloat(`${min_monthly_savings}`)}
                onChange={handleChange}
              />_
              <Form.Field
                id="form-input-max_monthly_savings"
                name="max_monthly_savings"
                value={max_monthly_savings}
                control={Input}
                placeholder="Max..."
                error={!validator.isFloat(max_monthly_savings)}
                onChange={handleChange}
              />
            </Form.Group>

            <Header className="header-mod">Monthly Loans</Header>
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-min_monthly_loans"
                className="rwf"
                name="min_monthly_loans"
                value={min_monthly_loans}
                control={Input}
                placeholder="Min..."
                error={!validator.isFloat(min_monthly_loans)}
                onChange={handleChange}
              />_
              <Form.Field
                id="form-input-max_monthly_loans"
                name="max_monthly_loans"
                value={max_monthly_loans}
                control={Input}
                placeholder="Max..."
                error={!validator.isFloat(max_monthly_loans)}
                onChange={handleChange}
              />
            </Form.Group>

            <Header className="header-mod">Type</Header>
            <Form.Field
                name="type"
                value={type}
                control={MySelect}
                fluid
                options={types}
                placeholder="Type..."
                error={type.length === 0 || (aE && aI === 3)}
                onChange={handleChange}
                disabled={aL}
                loading={aL && aI === 3}
            />

            
            <Form.Button primary fluid onClick={handleNextStep} style={{ marginTop: "30px" }}>
              Next
            </Form.Button>
            </div>
          )}

            {step === 2 && (
              <div style={{ paddingBottom: "50px", width: "400px" }}>
              <Header className="steps-header">Savings Groups Members</Header>
            <Header  className="header-mod">Provinces</Header>
              <Form.Field
                name="province"
                value={province}
                control={MySelect}
                fluid
                options={provinces}
                placeholder="Provinces..."
                error={province.length === 0 || (aE && aI === 0)}
                onChange={handleChange}
                disabled={aL}
                loading={aL && aI === 0}
              />
              <Header className="header-mod">Districts</Header>
              <Form.Field
                name="district"
                value={district}
                control={MySelect}
                fluid
                placeholder="Disctricts..."
                options={districts}
                error={district.length === 0 || (aE && aI === 1)}
                onChange={handleChange}
                disabled={aL}
                loading={aL && aI === 1}
              />
              <Header className="header-mod">Sectors</Header>
              <Form.Field
                name="sector"
                value={sector}
                control={MySelect}
                fluid
                options={sectors}
                placeholder="Sectors..."
                error={sector.length === 0 || (aE && aI === 2)}
                onChange={handleChange}
                disabled={aL}
                loading={aL && aI === 2}
              />
              <Header className="header-mod">Cells</Header>
              <Form.Field
                name="cell"
                value={cell}
                control={MySelect}
                fluid
                options={cells}
                placeholder="Cells..."
                error={cell.length === 0 || (aE && aI === 3)}
                onChange={handleChange}
                disabled={aL}
                loading={aL && aI === 3}
              />
              <Header className="header-mod">Villages</Header>
            <Form.Field
              name="village"
              value={village}
              control={MySelect}
              fluid
              options={villages}
              placeholder="Villages..."
              error={village.length === 0 || (aE && aI === 4)}
              onChange={handleChange}
              disabled={aL}
              loading={aL && aI === 4}
            />
            <Header className="header-mod">Age Range
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;
            Male
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;
            Female
            </Header>
            <Form.Group widths="equal">
                <Form.Field
                  id="form-input-min_age_range"
                  name="min_age_range"
                  value={min_age_range}
                  control={Input}
                  placeholder="..."
                  error={!validator.isInt(`${min_age_range}`)}
                  onChange={handleChange}
                  width='1'
                />_
                <Form.Field
                  id="form-input-max_age_range"
                  name="max_age_range"
                  value={max_age_range}
                  control={Input}
                  placeholder="..."
                  error={!validator.isInt(`${max_age_range}`)}
                  onChange={handleChange}
                  width='1'
                />

                <Form.Field
                  id="form-input-male_gender_count"
                  name="male_gender_count"
                  value={male_gender_count}
                  control={Input}
                  placeholder="..."
                  error={!validator.isInt(male_gender_count)}
                  onChange={handleChange}
                  width='1'
                />_
                <Form.Field
                  id="form-input-female_gender_count"
                  name="female_gender_count"
                  value={female_gender_count}
                  control={Input}
                  placeholder="..."
                  error={!validator.isInt(female_gender_count)}
                  onChange={handleChange}
                  width='1'
                />
            </Form.Group>

            <Form.Group widths="equal" style={{marginTop: "30px"}}>
              
              <Form.Field>
                <Card className="cards">
                  <div>SG COUNT</div>
                  <div>{sg_count_display}</div>
                </Card>
              </Form.Field>

              <Form.Field>
                <Card className="cardss">
                  <div>MEMBER COUNT</div>
                  <div>{member_count_display}</div>
                </Card>
              </Form.Field>
            </Form.Group>
            {aE && (
              <Message error visible={aE}>
                <div className="areaError">
                  <span>{aM}</span>
                  <span onClick={() => aR()}>Retry...</span>
                </div>
              </Message>
            )}
            <Message error content={lM} />
            <Message success content="Profile details saved!" />
            <Form.Button primary fluid style={{ marginTop: "30px" }}>
              Create Profile
            </Form.Button>
            </div>
            )}
          </Form>
          <div className="flex-1" />
        </div>
        </Card>
      </div>
    </div>
  </div>
);

export default UI;
