export default [
  {
    key: "active",
    text: "Active Users",
    value: "active"
  },
  {
    key: "inactive",
    text: "Inactive Users",
    value: "inactive"
  },
  {
    key: "deactivated",
    text: "Deactivated Users",
    value: "deactivated"
  },
  {
    key: "all",
    text: "All Users",
    value: "all"
  }
];
