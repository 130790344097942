import React from "react";
import nThLetter from "./../../utils/nThLetter";
import careateLink from "./../../utils/createLink";
import { isArray } from "util";
class MyList extends React.Component {
  render() {
    let {
      className = "",
      items = [],
      left = null,
      leftFn = t => t,
      right = null,
      rightFn = t => t,
      title = null,
      titleFn = t => t,
      description = [],
      descriptionFn = t => t,
      descriptionJoin = " | ",
      itemClass = null,
      itemClassFn = t => t,
      as = "div",
      asFn = t => t,
      link = []
    } = this.props;
    const As = asFn(as);

    description = isArray(description) ? description : [description];

    const dsc = item => {
      let desc = [];

      description.map(key => {
        if (Object.keys(item).includes(key)) desc.push(item[key]);
        return key;
      });

      return desc;
    };

    return (
      <div className={`my-list ${className}`}>
        {items.map((item, index) => (
          <As
            className={`item ${itemClass ? itemClassFn(item[itemClass]) : ""}`}
            key={index}
            {...careateLink(item, link)}
          >
            {left && (
              <div className="abrev">{nThLetter(leftFn(item[left]), 2)}</div>
            )}
            <div className="body">
              {title && <div className="title">{titleFn(item[title])}</div>}
              {description && (
                <div className="description">
                  {descriptionFn(dsc(item)).map((e, i, a) => {
                    return (
                      <span key={`e-${i}-${a.length}`}>
                        {e}
                        {i < a.length - 1 && descriptionJoin}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>
            {right && (
              <div className="right">
                <div className="pannels-row">{rightFn(item[right])}</div>
              </div>
            )}
          </As>
        ))}
      </div>
    );
  }
}

export default MyList;
