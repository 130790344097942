import React from "react";
import { baseUrl, GET, POST, PUT } from "./../../../../../utils/api";
import UI from "./UI";
import formErrors from "./../../../../../utils/formErrors";


class Settings extends React.Component {
  constructor(props) {
    super(props);

    let { profile, areas } = props.profile;
    let { defaultAreas } = props;

    let monthly_savings = profile.monthly_savings.split(" ");
    let monthly_loans = profile.monthly_loans.split(" ");
    let age_range = profile.age_range.split(" ");
    let gender_count = profile.gender_count.split(" ");

    this.state = {
      name: profile.name,
      min_monthly_savings: monthly_savings[0],
      max_monthly_savings: monthly_savings[2],
      min_monthly_loans: monthly_loans[0],
      max_monthly_loans: monthly_loans[2],
      min_age_range: age_range[0],
      max_age_range: age_range[2],
      male_gender_count: gender_count[0],
      female_gender_count: gender_count[2],
      type: profile.type.replace(/, +/g, ",").split(",").map(Number),
      ...areas,
      provinces: defaultAreas.provinces,
      lL: false,
      lE: false,
      lM: "",
      lS: false,
      aL: false,
      aE: false,
      aM: "",
      aR: null,
      aI: null
    };
  }

  areas = ["province", "district", "sector", "cell", "village"];

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, lE: false, lS: false }, () =>
      setTimeout(() => {
        if (this.areas.includes(name)) {
          let next = this.areas.indexOf(name) + 1;
          if (next < this.areas.length) {
            this.handleLocation(next);
          }
        }
      }, 500)
    );

  handleLocation = index => {
    let add_ = this.state[this.areas[index - 1]];

    this.setState({
      aL: true,
      aE: false,
      aM: "",
      aR: null,
      aI: index
    });

    let location = `${baseUrl}location/${this.areas[index - 1]}s_${
      this.areas[index]
    }s/${add_.join(",")}`;

    GET(location)
      .then(data => {
        let items = [];
        data.map(item => {
          items.push({
            label: item.name,
            text: item.name,
            value: item.id,
            key: item.id
          });
          return item;
        });

        let obj = {};

        obj[`${this.areas[index]}`] = [];
        obj[`${this.areas[index]}s`] = items;

        for (let i = index + 1; i < this.areas.length; i++) {
          obj[`${this.areas[i]}`] = [];
          obj[`${this.areas[i]}s`] = [];
        }

        this.setState({
          aL: false,
          aE: false,
          aM: "",
          aR: null,
          aI: null,
          ...obj
        });
      })
      .catch(error => {
        this.setState({
          aL: false,
          aE: true,
          aM: `Failed to get ${this.areas[index]}s.`,
          aR: () => this.handleLocation(index),
          aI: index
        });
      });
  };

  handleSave = (...e) => {
    let { project_id, update, currentUser } = this.props;

    const { name, end, start, budget, donor, village } = this.state;

    let d = { name, end, start, budget, donor };

    if (formErrors(e).ok) {
      this.setState({ lL: true, lE: false, lM: "", lS: false });
      Promise.all([
        PUT(`v2/projects/${project_id}`, {
          ...d,
          user_id: currentUser.id
        }),
        POST(`save-ssa/project/${project_id}/intervention_areas`, {
          village_ids: village
        })
      ])
        .then(data => {
          this.setState({ lL: false, lE: false, lM: "", lS: true });
          update();
        })
        .catch(error => {
          this.setState({ lL: false, lE: true, lM: error, lS: false });
        });
    }
  };

  render() {
    return <UI {...this} {...this.props} {...this.state} />;
  }
}

export default Settings;
