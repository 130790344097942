import React from "react";

import { GET } from "./../../../utils/api";

import UI from "./UI";

class SignIn extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      em: 0,
      password: "",
      pa: 0,
      lL: false,
      lE: false,
      lM: ""
    };
  }

  handleChange = (e, { name, value }) =>
    this.setState({
      [name]: value,
      em: this.state.em + (name === "email" ? 1 : 0),
      pa: this.state.pa + (name === "password" ? 1 : 0)
    });

  handleSignIn = () => {
    let { email, password } = this.state;

    this.setState({ lL: true, lE: false, lM: "" });

    GET(`save-ssa/authenticate/web`, { username: email, password })
      .then(data => {
        this.setState({ lL: false });
        global.updateSession({
          ...data.user,
          signedIn: true,
          token: data.token,
          token_v2: data.save_v2_token
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default SignIn;
