import React from "react";

import UI from "./UI";

import { GET, POST } from "./../../../../../utils/api";
import formErrors from "./../../../../../utils/formErrors";
import phoneVerify from "./../../../../../utils/phoneVerify";

var moment = require("moment");

class Wallet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activity: [],
      topup: false,
      credit: false,
      phone: props.userOrg.phone,
      amount: "",
      mtn: "",
      tigo: "",
      org: "",
      sg: "",
      agent: "",
      description: "",
      orgs: [],
      agents: [],
      sgs: [],
      beneficiary: 0,
      lL: false,
      lE: false,
      lM: "",
      lR: null,
      sL: false,
      sE: false,
      sM: "",
      sR: null,
      uL: false,
      uE: false,
      uM: "",
      uS: false
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () =>
      setTimeout(() => {
        if (name === "org") this.handleFetchList();
      }, 600)
    );
  };

  activateTopup = topup =>
    this.setState({ topup, uL: false, uE: false, uM: "", uS: false });
  activateCredit = credit =>
    this.setState({ credit, uL: false, uE: false, uM: "", uS: false });

  componentDidMount = () => this.handleFetch();

  handleFetch = () => {
    let { userOrg } = this.props;

    this.setState({ lL: true, lE: false, lM: "", lR: null });

    Promise.all([
      GET(`save-ssa/organizations`),
      GET(`save-ssa/organization/transactions/${userOrg.id}`),
      GET(`save-ssa/mno_wallet_bal/mtn`),
      GET(`save-ssa/mno_wallet_bal/tigo`)
    ])
      .then(data => {
        this.setState({
          mtn: data[2].balance,
          tigo: data[3].balance,
          orgs: [
            ...data[0].map(({ id, name }) => ({
              value: id,
              key: id,
              text: name
            }))
          ],
          activity: [
            ...data[1].map(d => {
              let ac = {
                id: d.id,
                trx_id: `${d.trx_id} (${d.external_transaction_id || "-"})`,
                date: moment(
                  d.time_completed.startsWith("0000")
                    ? d.time_initiated
                    : d.time_completed
                ).format("MMM DD, YYYY HH:mm"),
                amount: d.amount,
                channel: d.provider || "Wallet",
                reason: d.description,
                status: ["Pending", "Processing", "Completed", "Failed"][
                  d.status
                ],
                creditor: userOrg.name,
                recipient: userOrg.name
              };

              if (d.transaction_type === "ORGANIZATION_CASH_IN") {
                ac.creditor = d.account;
              } else {
                ac.recipient =
                  d.sg_name || d.agent_name || d.sg_id || d.agent_id;
              }

              return ac;
            })
          ],
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: this.handleFetch });
      });
  };

  handleFetchList = () => {
    let { org } = this.state;

    this.setState({
      sg: "",
      agent: "",
      sgs: [],
      agents: [],
      sL: true,
      sE: false,
      sM: "",
      sR: null
    });

    Promise.all([
      GET(`save-ssa/organization/saving_groups/${org}`),
      GET(`save-ssa/organization/agents/${org}`)
    ])
      .then(data => {
        this.setState({
          sgs: [
            ...data[0].map(({ saving_group }) => ({
              value: saving_group.id,
              key: saving_group.id,
              text: saving_group.name
            }))
          ],
          agents: [
            ...data[1].agents.map(({ agent_id, agent_name }) => ({
              value: agent_id,
              key: agent_id,
              text: agent_name
            }))
          ],
          sL: false,
          sE: false,
          sM: "",
          sR: null
        });
      })
      .catch(error => {
        this.setState({
          sL: false,
          sE: true,
          sM: error,
          sR: this.handleFetchList
        });
      });
  };

  handleCredit = (...e) => {
    let { userOrg } = this.props;
    let { amount, description, sg, beneficiary, agent } = this.state;

    if (formErrors(e).ok) {
      this.setState({ uL: true, uE: false, uM: "", uS: false });
      let url = ``;
      let data_ = {};

      if (beneficiary === 1) {
        url = `save-ssa/organizations/${userOrg.id}/creditagent`;
        data_ = {
          agent_user_id: agent,
          amount: amount * 1,
          description: description
        };
      } else if (beneficiary === 0) {
        url = `save-ssa/account_transfer/org_to_sg`;
        data_ = {
          org_id: userOrg.id,
          sg_id: sg * 1,
          amount: amount * 1,
          description: description
        };
      }

      POST(url, data_)
        .then(data => {
          this.setState({
            uL: false,
            uE: false,
            uM: "Credited successfully!",
            uS: true
          });
        })
        .catch(error => {
          this.setState({ uL: false, uE: true, uM: error, uS: false });
        });
    }
  };
  handleTopup = (...e) => {
    let { userOrg } = this.props;
    let { amount, phone } = this.state;

    if (formErrors(e).ok) {
      this.setState({ uL: true, uE: false, uM: "", uS: false });

      let url = `save-ssa/organization/cashin/${userOrg.id}`;
      let data_ = {
        msisdn: phoneVerify(phone).phone,
        amount: amount * 1
      };

      POST(url, data_)
        .then(data => {
          this.setState({
            uL: false,
            uE: false,
            uM: "Top-up initiated!",
            uS: true
          });
        })
        .catch(error => {
          this.setState({ uL: false, uE: true, uM: error, uS: false });
        });
    }
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default Wallet;
