// Before

// export const listItemNames = [
//   "project",
//   "organization",
//   "organization",
//   "???",
//   "???"
// ];

// export const listItemsLinks = ["", "organizations", "", ""];
// export const listItemsLinksFetch = ["", "organization", "", ""];

//End of before

// export const listItemNames = [
//   "project",
//   "organization",
//   "organization",
//   "???",
//   "profile",
//   "???"
// ];

export const listItemNames = [
  "project",
  "organization",
  "project",
  "project",
  "federation",
  "???",
  "???",
  "???",
  "profile"
];

export const listItemsLinks = ["profiles", "organizations", "", ""];
export const listItemsLinksFetch = ["profile", "organization", "", ""];

export const ssaToolbar = [
  {
    title: "Overview",
    url: "",
    exact: true
  },
  {
    title: "Projects",
    url: "/projects"
  },
  {
    title: "Agents",
    url: "/agents"
  },
  {
    title: "Saving Groups",
    url: "/saving-groups"
  },
  {
    title: "Settings",
    url: "/settings"
  }
];

export const ngoToolbar = [
  {
    title: "Overview",
    url: "",
    exact: true
  },
  {
    title: "Partners",
    url: "/partners"
  },
  {
    title: "Agents",
    url: "/agents"
  },
  {
    title: "Saving Groups",
    url: "/saving-groups"
  },
  {
    title: "Settings",
    url: "/settings"
  }
];

export const fspToolbar = [
  {
    title: "Overview",
    url: "",
    exact: true
  },
  // {
  //   title: "Partners",
  //   url: "/partners"
  // },
  {
    title: "Agents",
    url: "/agents"
  },
  {
    title: "Saving Groups",
    url: "/saving-groups"
  },
  {
    title: "Requests",
    url: "/requests"
  },
  {
    title: "Settings",
    url: "/settings"
  }
];

export const ssanccrToolbar = [
  {
    title: "Overview",
    url: "",
    exact: true
  },
  {
    title: "Federations",
    url: "/federations"
  },
  {
    title: "Admins",
    url: "/admins"
  },
  {
    title: "Saving Groups",
    url: "/saving-groups"
  },
  {
    title: "Settings",
    url: "/settings"
  }
];

export const nccrToolbar = [
  {
    title: "Overview",
    url: "",
    exact: true
  },
  {
    title: "Unions",
    url: "/unions"
  },
  {
    title: "Cooperatives",
    url: "/cooperatives"
  },
  {
    title: "Admins",
    url: "/admins"
  },
  {
    title: "Saving Groups",
    url: "/saving-groups"
  },
  {
    title: "Settings",
    url: "/settings"
  }
];

export const organizationTypes = [
  { key: 1, text: "International NGO", value: 1 },
  { key: 2, text: "Local NGO", value: 2 },
  { key: 3, text: "Business ORG", value: 3 },
  { key: 4, text: "NCCR", value: 4 },
  { key: 5, text: "Federation", value: 5 },
  { key: 6, text: "Union", value: 6 },
  { key: 7, text: "Cooperative", value: 7 },
  { key: 8, text: "FSP", value: 8 }
];

export const maritalStatuses = [
  { key: 0, text: "Single", value: 0 },
  { key: 1, text: "Married", value: 1 },
  { key: 2, text: "Widowed", value: 2 },
  { key: 3, text: "Divorced", value: 3 }
];

export const educationLevels = [
  { key: "Primary", text: "Primary", value: "Primary" },
  { key: "Secondary", text: "Secondary", value: "Secondary" },
  { key: "University", text: "University", value: "University" },
  { key: "none", text: "Non-applicable", value: "" }
];

export const genders = [
  { key: 0, text: "Male", value: 0 },
  { key: 1, text: "Female", value: 1 }
];

export const sgStatuses = [
  { key: 0, text: "Supervised", value: 0 },
  { ket: 1, text: "Graduated", value: 1 }
];

export const types = [
  { key: 0, text: "Independent", value: 0 },
  { key: 1, text: "Graduated", value: 1 },
  { key: 2, text: "Supervised", value: 2 }
];

export const countries = [{ key: "rw", text: "Rwanda", value: "rwanda" }];
