import React from "react";
import { POST } from "./../../../../../utils/api";
import UI from "./UI";
import phoneVerify from "./../../../../../utils/phoneVerify";
import formErrors from "./../../../../../utils/formErrors";

class Details extends React.Component {
  constructor(props) {
    super(props);

    let organization = props.userOrg;

    this.state = {
      id: organization.id,
      name: organization.name,
      email: organization.email,
      phone: organization.phone,
      address: organization.address,
      type: organization.type,
      lL: false,
      lE: false,
      lM: "",
      lS: false
    };
  }
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, lE: false, lS: false });

  handleSave = (...e) => {
    let { id } = this.state;
    let { updateOrg } = this.props;

    let data_ = (({ name, email, phone, address, type }) => ({
      name,
      email,
      phone: phoneVerify(phone).phone,
      address,
      type
    }))(this.state);

    if (formErrors(e).ok) {
      this.setState({ lL: true, lE: false, lM: "", lS: false });
      POST(`save-ssa/organization/update/${id}`, data_)
        .then(data => {
          this.setState({ lL: false, lE: false, lM: "", lS: true });
          updateOrg(data);
        })
        .catch(error => {
          this.setState({ lL: false, lE: true, lM: error, lS: false });
        });
    }
  };

  render() {
    return <UI {...this} {...this.props} {...this.state} />;
  }
}

export default Details;
