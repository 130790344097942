import React from "react";
import { Loader } from "semantic-ui-react";

class SignOut extends React.Component {
  componentDidMount() {
    setTimeout(
      () =>
        global.updateSession({
          signedIn: false,
          token: ""
        }),
      3000
    );
  }
  render() {
    return (
      <div className="sign-out-page">
        <Loader active inline="centered" />
      </div>
    );
  }
}

export default SignOut;
