import React from "react";
import UI from "./UI";

class Overview extends React.Component {
  render() {
    return <UI {...this.props} />;
  }
}

export default Overview;
