import React from "react";
import UI from "./UI";

import { GET } from "../../../../../../../utils/api";

class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      agent: {
        user_details: {},
        members_education_grouping: [],
        enrollment_by_gender_per_month: []
      },
      lL: false,
      lE: false,
      lM: "",
      lR: null
    };
  }

  componentDidMount = () => this.fetchData();

  fetchData = () => {
    let { agent_id } = this.props;
    this.setState({ lL: true, lE: false });

    GET(`save-${global.platform}/agent/${agent_id}`)
      .then(data => {
        this.setState({
          agent: data,
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.fetchData
        });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default Overview;
