import React from "react";

import { GET } from "./../../../../../../../../utils/api";

import UI from "./UI";

class Member extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      member: {
        sg_cycles: [{}],
        member_cycles: [],
        savings_vs_loans_distribution: [],
        savings_vs_shares_distribution: [],
        location: {
          province: {},
          district: {},
          sector: {},
          cell: {},
          village: {}
        }
      },
      lL: false,
      lE: false,
      lM: "",
      lR: null
    };
  }
  componentDidMount = () => this.myFetchData();

  myFetchData = () => {
    let { member_id } = this.props;
    this.setState({ lL: true, lE: false });

    GET(`save-fsp/sg/member/${member_id}`)
      .then(
        data => {
          let member = { ...data.user_details, ...data.sg_details, ...data };

          this.setState({
            member,
            lL: false,
            lE: false,
            lM: "",
            lR: null
          });
        }
      )
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.myFetchData
        });
      });
  };

  getLocation = data => {
    let member = { ...data.user_details, ...data.sg_details, ...data };

    GET(`location/village/${member.location}`)
      .then(data_ => {
        member.location = data_;
        this.setState({
          member,
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: () => this.getLocation(data)
        });
      });
  };

  fetchData = () => {
    let { member_id } = this.props;
    this.setState({ lL: true, lE: false });

    GET(`save-ssa/sg/member/${member_id}`)
      .then(this.getLocation)
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.fetchData
        });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default Member;
