import React from "react";
import { BarChart, Bar } from "recharts";
import { Segment} from "semantic-ui-react";
import numberFormat from "./../../utils/numberFormat";


const Boxx = ({
  title = "",
  value = null,
  chart = { data: [], key: "", keys: [] },
                  
  title2 = "",
  value2 = null,
  chart2 = { data: [], key: "", keys: [] }
}) => (
  <Segment>
    <div  className="boxx-overview" style={{display: "flex", flexDirection: "row"}}>
        <div>
          <div className="boxx-value">{numberFormat(value)}</div>
          <BarChart width={190} height={40} data={chart.data}>
             <Bar dataKey={chart.key} fill='#8884d8'/>
           </BarChart>
           <div className="boxx-title">{title}</div>
         </div>
         
         <div style={{margin: "0 15px 0 15px"}}>
           <div className="boxx-value">{numberFormat(value2)}</div>
           <BarChart width={190} height={40} data={chart2.data}>
             <Bar dataKey={chart2.key} fill='#8884d8'/>
           </BarChart>
           <div className="boxx-title">{title2}</div>
         </div>
         
    </div>

  </Segment>
);

export default Boxx;
