import React from "react";
import {
  Form,
  Input,
  Header,
  Message,
  Icon,
  Divider,
  Select
} from "semantic-ui-react";
import validator from "validator";
import { genders } from "./../../../../utils/vars";

import phoneVerify from "./../../../../utils/phoneVerify";

const UI = ({
  lL,
  lS,
  lE,
  lM,
  lR,
  history,
  name,
  activities,
  adm_name,
  adm_email,
  adm_phone,
  adm_id_number,
  adm_gender,
  pre_name,
  pre_phone,
  sec_name,
  sec_phone,
  handleChange
}) => (
  <div className="pannel-scroll">
    <div className="padding">
      <div className="header">
        {history && history.goBack && (
          <div className="back" onClick={() => history.goBack()}>
            <Icon name="arrow left" fitted size="large" />
          </div>
        )}
        <span className="title">New Federation</span>
      </div>
      <div className="pannels-row">
        <div className="flex-1" />
        <Form loading={lL} success={lS} error={lE} onSubmit={lR}>
          <Header>Feeration Information</Header>
          <Form.Field
            id="form-input-name"
            name="name"
            value={name}
            control={Input}
            label="Name"
            placeholder="Name..."
            error={validator.isEmpty(name)}
            onChange={handleChange}
          />
          <Form.Field
            id="form-input-activities"
            name="activities"
            value={activities}
            control={Input}
            label="Common Activities"
            placeholder="Common activities..."
            error={validator.isEmpty(activities)}
            onChange={handleChange}
          />
          <Header>Federation Contact Person</Header>
          <Form.Field
            id="form-input-adm_name"
            name="adm_name"
            value={adm_name}
            control={Input}
            label="Name"
            placeholder="Name..."
            error={validator.isEmpty(adm_name)}
            onChange={handleChange}
          />
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-adm_email"
              name="adm_email"
              value={adm_email}
              control={Input}
              label="Email"
              type="email"
              placeholder="Email..."
              error={!validator.isEmail(adm_email)}
              onChange={handleChange}
            />
            <Form.Field
              id="form-input-adm_phone"
              name="adm_phone"
              value={adm_phone}
              control={Input}
              label="Phone"
              type="tel"
              placeholder="Phone..."
              error={!phoneVerify(adm_phone).ok}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-adm_id_number"
              name="adm_id_number"
              value={adm_id_number}
              control={Input}
              label="ID Number"
              placeholder="ID Number..."
              error={validator.isEmpty(adm_id_number)}
              onChange={handleChange}
            />
            <Form.Field
              name="adm_gender"
              value={adm_gender}
              control={Select}
              fluid
              options={genders}
              label={{
                children: "Gender",
                htmlFor: "form-input-adm_gender"
              }}
              placeholder="Gender..."
              search
              searchInput={{ id: "form-input-adm_gender" }}
              error={!validator.isInt(`${adm_gender}`)}
              onChange={handleChange}
            />
          </Form.Group>
          <Header>Federation Committee</Header>
          <Header as="h3">President</Header>
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-pre_name"
              name="pre_name"
              value={pre_name}
              control={Input}
              label="Name"
              placeholder="Name..."
              error={validator.isEmpty(pre_name)}
              onChange={handleChange}
            />
            <Form.Field
              id="form-input-pre_phone"
              name="pre_phone"
              value={pre_phone}
              control={Input}
              label="Phone"
              type="tel"
              placeholder="Phone..."
              error={!phoneVerify(pre_phone).ok}
              onChange={handleChange}
            />
          </Form.Group>
          <Header as="h3">Secretary</Header>
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-sec_name"
              name="sec_name"
              value={sec_name}
              control={Input}
              label="Name"
              placeholder="Name..."
              error={validator.isEmpty(sec_name)}
              onChange={handleChange}
            />
            <Form.Field
              id="form-input-sec_phone"
              name="sec_phone"
              value={sec_phone}
              control={Input}
              label="Phone"
              type="tel"
              placeholder="Phone..."
              error={!phoneVerify(sec_phone).ok}
              onChange={handleChange}
            />
          </Form.Group>
          <Message error content={lM} />
          <Message success content="Federation details saved!" />
          <Divider />
          <Form.Button primary floated="right">
            Create Federation
          </Form.Button>
        </Form>
        <div className="flex-1" />
      </div>
    </div>
  </div>
);

export default UI;
