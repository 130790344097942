import React from "react";
import ContentLoader from "./../../../../../components/Loader";
import MyList from "./../../../../../components/MyList";
import { Icon } from "semantic-ui-react";
import activityMessage from "./../../../../../utils/activityMessages";

const UI = ({ lL, lE, lM, lR, activity, history }) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Wait a moment..."
    showContent={activity.length !== 0}
  >
    <div className="pannel-scroll">
      <div className="padding">
        <div className="header">
          {history && history.goBack && (
            <div className="back" onClick={() => history.goBack()}>
              <Icon name="arrow left" fitted size="large" />
            </div>
          )}
          <span className="title">Recent Activity</span>
        </div>
        <MyList
          className="activity"
          items={activity}
          left="a_name"
          title="a_name"
          description={[
            "actioned_by",
            "activity",
            "resource_details",
            "a_time"
          ]}
          descriptionFn={t => {
            return [activityMessage(t[0], t[1], t[2], t[3])];
          }}
        />
      </div>
    </div>
  </ContentLoader>
);

export default UI;
