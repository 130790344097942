import React from "react";
import { NavLink, Switch, Route, Link } from "react-router-dom";

import { Button } from "semantic-ui-react";

import MyList from "./../../../../components/MyList";

import Activity from "./Activity";
import Users from "./Users";
import Settings from "./Settings";

var moment = require("moment");

const UI = props => (
  <div className="pannels-row">
    <div className="sidebar">
      <div className="header">
        <span className="title">Dashboard</span>
      </div>
      <NavLink to={`/`} exact activeClassName="active">
        Activity
      </NavLink>
      <NavLink to={`/users`} activeClassName="active">
        Users
      </NavLink>
      <NavLink to={`/settings`} exact activeClassName="active">
        Settings
      </NavLink>
    </div>
    <div className="pannels-column">
      <Switch>
        <Route
          exact
          path={`/`}
          render={props_ => <Activity {...props} {...props_} />}
        />
        <Route
          path={`/users`}
          render={props_ => <Users {...props} {...props_} />}
        />
        <Route
          exact
          path={`/settings`}
          render={props_ => <Settings {...props} {...props_} />}
        />
      </Switch>
    </div>
    <Switch>
      <Route
        exact
        path={`/`}
        render={props_ => (
          <div style={{ width: "320px" }}>
            <div className="pannel-scroll">
              <div className="padding">
                <div className="header">
                  <span className="title">Projects</span>
                  <Button
                    size="small"
                    as={Link}
                    to={`/projects`}
                    content="View All"
                  />
                </div>
              </div>
              <MyList
                items={[...props.projects].slice(0, 5)}
                left="name"
                title="name"
                description={["created_by", "date"]}
                descriptionFn={d => [
                  `Created on ${moment(d[1]).format("MMM DD, YYYY")} by ${d[0]}`
                ]}
                as={Link}
                link={["projects", "[(id)]"]}
              />
            </div>
          </div>
        )}
      />
    </Switch>
  </div>
);

export default UI;
