import nThLetter from "./nThLetter";

const abName = (t, limit = 0) => {
  if (t !== null) {
    let n = t.split(" ");
    return n.length > 1 ? `${nThLetter(t, 1)} ${n[1]}` : n[0];
  } else {
    return "";
  }
};

export default abName;
