import React from "react";
import {
  Form,
  Input,
  Message,
  Divider,
  Icon,
  Button,
  Select
} from "semantic-ui-react";
import phoneVerify from "../../../../../../utils/phoneVerify";
import ContentLoader from "./../../../../../../components/Loader";
import validator from "validator";

const UI = ({
  lL,
  lS,
  lE,
  lM,
  lR,
  lI,
  name,
  phone,
  partner,
  partners,
  handleSave,
  handleChange,
  addRow,
  removeRow,
  history
}) => {
  return (
    <ContentLoader
      lL={lL && lI === 0}
      lE={lE && lI === 0}
      lM={lM}
      lR={lR}
      message="Fetching cooperatives..."
      showContentMessage="You have no cooperative!"
      showContent={lI !== 0}
    >
      <div className="pannel-scroll">
        <div className="padding">
          <div className="header">
            {history && history.goBack && (
              <div className="back" onClick={() => history.goBack()}>
                <Icon name="arrow left" fitted size="large" />
              </div>
            )}
            <span className="title">Invite Admin{name.length > 1 && "s"}</span>
          </div>
          <Form
            loading={lL}
            success={lS}
            error={lE}
            onSubmit={handleSave}
            className="fluid"
          >
            {name.map((n, i) => (
              <Form.Group key={`fg-${i}`} widths="equal">
                <Form.Field
                  id={`form-input-name-${i}`}
                  name={`name-${i}`}
                  value={name[i]}
                  control={Input}
                  label="Name"
                  placeholder="Name..."
                  error={validator.isEmpty(name[i])}
                  onChange={handleChange}
                />
                <Form.Field
                  id={`form-input-phone-${i}`}
                  name={`phone-${i}`}
                  value={phone[i]}
                  control={Input}
                  label="Phone"
                  type="tel"
                  placeholder="Phone..."
                  error={!phoneVerify(phone[i]).ok}
                  onChange={handleChange}
                />
                <Form.Field
                  name={`partner-${i}`}
                  value={partner[i]}
                  control={Select}
                  fluid
                  options={partners}
                  label={{
                    children: "Cooperative",
                    htmlFor: `form-input-partner-${i}`
                  }}
                  placeholder="Cooperative..."
                  search
                  searchInput={{ id: `form-input-partner-${i}` }}
                  error={!validator.isInt(`${partner[i]}`)}
                  onChange={handleChange}
                />
                <Button
                  basic
                  color="red"
                  icon="close"
                  as="div"
                  disabled={i === 0 && name.length === 1}
                  onClick={() => removeRow(i)}
                />
              </Form.Group>
            ))}
            {lE && <Message error content={lM} />}
            <Message
              success
              content={`Admin${name.length > 1 && "s"} invited!`}
            />
            <Divider />
            <Form.Group widths="equal">
              <Button
                basic
                color="green"
                content="Add Row"
                labelPosition="left"
                icon="add"
                as="div"
                onClick={addRow}
              />
            </Form.Group>
            <Divider />
            <Form.Button
              primary
              floated="right"
              content={`Invite Admin${name.length > 1 ? "s" : ""}`}
              labelPosition="right"
              icon="check"
            />
          </Form>
        </div>
      </div>
    </ContentLoader>
  );
};

export default UI;
