import React from "react";

import { Switch, Route } from "react-router-dom";

import Overview from "./Overview";
import Members from "./Members";

const UI = props => (
  <Switch>
    <Route
      exact
      path={`/projects/${props.project_id}/saving-groups/${
        props.match.params.sg_id
      }`}
      render={props_ => (
        <Overview {...props} {...props_} sg_id={props.match.params.sg_id} />
      )}
    />
    <Route
      path={`/projects/${props.project_id}/saving-groups/${
        props.match.params.sg_id
      }/members`}
      render={props_ => (
        <Members {...props} {...props_} sg_id={props.match.params.sg_id} />
      )}
    />
  </Switch>
);

export default UI;
