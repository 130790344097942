import React from "react";
import ContentLoader from "./../../../../../../components/Loader";
import { Icon, Segment, Header, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

var actions = {
  "reset-password": { _: "Reset Password", __: "reset the password of" },
  deactivate: { _: "Deactivate", __: "deactivate" }
};

const UI = ({
  id,
  user,
  lL,
  lE,
  lM,
  lS,
  lR,
  action,
  handleAction,
  history
}) => (
  <ContentLoader
    lL={lL && Object.keys(user).length === 0}
    lE={lE && Object.keys(user).length === 0}
    lM={lM}
    lR={lR}
    message="Fetching user information..."
    showContent={Object.keys(user).length > 0}
  >
    <div className="padding pannels-column">
      <div className="header">
        {history && history.goBack && (
          <div className="back" onClick={() => history.goBack()}>
            <Icon name="arrow left" fitted size="large" />
          </div>
        )}
        <span className="title">
          {user.name}: {actions[action]._}
        </span>
      </div>
      <Segment placeholder>
        <Header icon>
          <Icon name={lS ? "check circle outline" : "warning sign"} />
          {lS
            ? lM
            : `Are you sure you want to ${actions[action].__} ${user.name}?`}
        </Header>
        {!lS && (
          <Segment.Inline>
            <Button
              color="red"
              labelPosition="left"
              icon="close"
              content="Cancel"
              as={Link}
              to={`/users/${id}`}
            />
            <div className="padder" />
            <Button
              color="green"
              labelPosition="right"
              icon="check"
              content="Yes"
              onClick={handleAction}
            />
          </Segment.Inline>
        )}
      </Segment>
    </div>
  </ContentLoader>
);

export default UI;
