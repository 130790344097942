import React from "react";
import {
  Form,
  Input,
  Message,
  Icon,
  Header,
} from "semantic-ui-react";
import phoneVerify from "../../../../../utils/phoneVerify";
import validator from "validator";
const UI = ({
  lL,
  lS,
  lE,
  lM,
  name,
  email,
  phone,
  address,
  country,
  type,
  handleSave,
  handleChange,
  history
}) => (
  <div className="pannel-scroll">
    <div className="padding">
      <div className="header">
        {history && history.goBack && (
          <div className="back" onClick={() => history.goBack()}>
            <Icon name="arrow left" fitted size="large" />
          </div>
        )}
        <span className="title">Organization Settings</span>
      </div>
      <Form loading={lL} success={lS} error={lE} onSubmit={handleSave}>
        <Header>Name</Header>
        <Form.Field
          id="form-input-name"
          name="name"
          value={name}
          control={Input}
          placeholder="Name..."
          error={validator.isEmpty(name)}
          onChange={handleChange}
        />
        <Header>Email</Header>
        <Form.Field
          id="form-input-email"
          name="email"
          value={email}
          control={Input}
          type="email"
          placeholder="Email..."
          error={!validator.isEmail(email)}
          onChange={handleChange}
        />
        <Header>Phone</Header>
        <Form.Field
          id="form-input-phone"
          name="phone"
          value={phone}
          control={Input}
          type="tel"
          placeholder="Phone..."
          error={!phoneVerify(phone).ok}
          onChange={handleChange}
        />
        <Header>Address</Header>
        <Form.Field
          id="form-input-address"
          name="address"
          value={address}
          control={Input}
          placeholder="Address..."
          error={validator.isEmpty(address)}
          onChange={handleChange}
        />

        <Header>Country</Header>
        <Form.Field
          id="form-input-country"
          name="country"
          value={country}
          control={Input}
          placeholder="Country..."
          error={validator.isEmpty(country)}
          onChange={handleChange}
        />

        {/* <Header>Type</Header>
        <Form.Field
          name="type"
          value={type}
          control={Select}
          options={organizationTypes}
          label={{ children: "Type", htmlFor: "form-input-type" }}
          placeholder="Type..."
          search
          searchInput={{ id: "form-input-type" }}
          error={!validator.isInt(`${type}`)}
          onChange={handleChange}
        />

        <div className="header" style={{ marginTop: "80px" }}>
          <span className="title">Payment Method</span>
          <span>
          <Button
              as={NavLink}
              to="/new-method"
              activeClassName="disabled"
              exact
              primary
              content="Add new"
            />
          </span>
        </div>

        <Header>Card number</Header>
          <Form.Field
            id="form-input-address"
            name="address"
            value={address}
            control={Input}
            placeholder="Card number..."
            error={validator.isEmpty(address)}
            onChange={handleChange}
          />
          <Form.Group widths="equal">
            <Form.Radio
              label='Visa Card'
              value='sm'
              checked={value === 'sm'}
              onChange={this.handleChange}
            />
            <Form.Radio
              label='Mastercard'
              value='md'
              checked={value === 'md'}
              onChange={this.handleChange}
            />
        </Form.Group>

        <Form.Group widths={3}>
            <Form.Field
              id="form-input-address"
              name="address"
              value={address}
              control={Input}
              placeholder="MM..."
              error={validator.isEmpty(address)}
              onChange={handleChange}
            />
            <Form.Field
              id="form-input-address"
              name="address"
              value={address}
              control={Input}
              placeholder="YY..."
              error={validator.isEmpty(address)}
              onChange={handleChange}
            />
            <Form.Field
              id="form-input-address"
              name="address"
              value={address}
              control={Input}
              placeholder="CVC..."
              error={validator.isEmpty(address)}
              onChange={handleChange}
            />
        </Form.Group>
        <Divider />

        <Header>Card Holder</Header>
        <Form.Field
            id="form-input-address"
            name="address"
            value={address}
            control={Input}
            placeholder="Card holder..."
            error={validator.isEmpty(address)}
            onChange={handleChange}
          />

        <Header>Billing Email</Header>
        <Form.Field
            id="form-input-address"
            name="address"
            value={address}
            control={Input}
            placeholder="Billing email..."
            error={validator.isEmpty(address)}
            onChange={handleChange}
          />

        <Header>Country</Header>
        <Form.Field
            id="form-input-address"
            name="address"
            value={address}
            control={Input}
            placeholder="Country..."
            error={validator.isEmpty(address)}
            onChange={handleChange}
        />

        <Header>City</Header>
        <Form.Field
            id="form-input-address"
            name="address"
            value={address}
            control={Input}
            placeholder="City..."
            error={validator.isEmpty(address)}
            onChange={handleChange}
        />

        <Header>Address</Header>
        <Form.Field
            id="form-input-address"
            name="address"
            value={address}
            control={Input}
            placeholder="Address..."
            error={validator.isEmpty(address)}
            onChange={handleChange}
        /> */}

        <Message error content={lM} />
        <Message success content="Organization details saved!" />
        <Form.Button primary style={{ width: "150px" }}>
          Save
        </Form.Button>
      </Form>
    </div>
  </div>
);

export default UI;
