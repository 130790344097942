import React from "react";
import ContentLoader from "./../../../../../../components/Loader";
import MyList from "./../../../../../../components/MyList";
import { Link } from "react-router-dom";
import phoneVerify from "./../../../../../../utils/phoneVerify";
import { Icon, Label, Button } from "semantic-ui-react";

const UI = ({ lL, lE, lM, lR, users, currentUser, history }) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Wait a moment..."
    showContent={users.length !== 0}
  >
    <div className="pannel-scroll">
      <div className="padding">
        <div className="header">
          {history && history.goBack && (
            <div className="back" onClick={() => history.goBack()}>
              <Icon name="arrow left" fitted size="large" />
            </div>
          )}
          <span className="title">Users</span>
          {/* {currentUser.is_admin === 1 && ( */}
            <Button
              as={Link}
              to="/users/add"
              content="Invite User"
              labelPosition="right"
              icon="add"
              primary
            />
          {/* )} */}
        </div>
        <MyList
          items={users}
          left="name"
          title="name"
          description={["phone", "work_email"]}
          descriptionFn={t => [
            <span>
              <Icon name="phone" fitted size="small" />
              &nbsp;{phoneVerify(t[0]).phone_}
            </span>,
            <span>
              <Icon name="at" fitted size="small" />
              &nbsp;{t[1]}
            </span>
          ]}
          descriptionJoin={<span className="padder">&nbsp;</span>}
          right="is_admin"
          rightFn={t =>
            t ? (
              <Label color="green" horizontal style={{ marginRight: 0 }}>
                Admin
              </Label>
            ) : (
              ""
            )
          }
          // {...(currentUser.is_admin === 0
          {...(currentUser.is_admin !== 0
            ? {}
            : { as: Link, link: ["users", "[(id)]"] })}
        />
      </div>
    </div>
  </ContentLoader>
);

export default UI;
