import React from "react";

import { LineChart, Line, BarChart, Bar } from "recharts";
import { Link } from "react-router-dom";

import CustomChart, {
  SimpleLineChart,
  SimpleLineChart2,
  SimpleRadialBarChart,
  SimpleRadialBarChart2,
  SimpleBarChart,
  SimpleBarChart3,
  CustomChart2,
  CustomChart3,
  ComplexAreaChart
} from "./../CustomCharts";

import { Segment, Icon } from "semantic-ui-react";
import numberFormat from "./../../utils/numberFormat";
import nThLetter from "./../../utils/nThLetter";

var moment = require("moment");

const WrapCol = props =>
  props.link !== null ? (
    <Link {...props} to={props.link}>
      {props.children}
    </Link>
  ) : (
    <div {...props}>{props.children}</div>
  );

const Box = ({
  title = null,
  value = null,
  unit = null,
  color = "",
  icon = null,
  name = null,
  noDate = false,
  noChart = false,
  lineChart = false,
  barChart = false,
  doubleLineChart = false,
  doubleLineChart2 = false,
  radialChart = false,
  radialChart2 = false,
  radialChart3 = false,
  radialBarChart = false,
  radialBarChart2 = false,
  doubleBarChart = false,
  complexChart = false,
  chart = { data: [], key: "", keys: [] },
  rows = [],
  // Codeeeeee
  className2 = "",
  slightHeight = false,
  barChart2 = false,
  barChart3 = false,
  chart2 = { data: [], key: "", keys: [] },
  singleBarChart = false
  // Codeeeeeee
}) => (
  <Segment
    className={`value-card ${className2}`}
    inverted={color !== ""}
    {...(color => (color === "" ? {} : { color }))(color)}
  >
    <div className="titleHolder titleHolderCustom">
      {name !== null && (
        <div className="abrevContainer">
          <div className="abrev">{nThLetter(name, 2)}</div>
        </div>
      )}
      <div
        className={`inner${icon !== null ? " hasIcon" : ""}${
          name !== null ? " hasAbrev" : ""
        }`}
      >
        {name !== null && (
          <h2>
            <span>{name}</span>
          </h2>
        )}
        {title !== null && (
          <div className="title">
            <span>{title}</span>
          </div>
        )}
        {!noDate && (
          <div className="date">
            <span>{moment().format("MMM DD, YYYY")}</span>
          </div>
        )}
      </div>
      {icon !== null && (
        <div className="iconContainer">
          <Icon name={icon} size="large" />
        </div>
      )}
    </div>

    {value !== null &&
      (!slightHeight ? (
        <div className="value">
          {numberFormat(value)}
          {unit !== null && <span className="unit">{unit}</span>}
        </div>
      ) : (
        <div className="value" style={{ height: "75px" }}>
          {numberFormat(value)}
          {unit !== null && <span className="unit">{unit}</span>}
        </div>
      ))}

    {rows.length > 0 && (
      <div className="rows">
        {rows.map((row, rIndex, arr) => (
          <div
            key={`row-${rIndex}`}
            className={`row ${arr[rIndex].length === 0 ? "flex-1" : ""}`}
          >
            {row.map(
              (
                {
                  label = null,
                  value = null,
                  className = "",
                  labelBadge = null,
                  link = null,
                  emptyLabel = null
                },
                cIndex
              ) => (
                <WrapCol
                  link={link}
                  key={`col-${cIndex}`}
                  className={`col ${className}`}
                >
                  {(label !== null || emptyLabel !== null) && (
                    <div className="label">
                      {label !== null && <span>{label}</span>}
                      {emptyLabel !== null && <span>&nbsp;</span>}
                      {labelBadge !== null && (
                        <i className="badge">{labelBadge}</i>
                      )}
                    </div>
                  )}
                  {value !== null && <div className="value">{value}</div>}
                </WrapCol>
              )
            )}
          </div>
        ))}
      </div>
    )}
    {!noChart && (
      <div className="chart">
        {lineChart && (
          <LineChart width={240} height={80} data={chart.data}>
            <Line
              type="monotone"
              dataKey={chart.key}
              stroke="#fff"
              strokeWidth={2}
            />
          </LineChart>
        )}

        {barChart && (
          <BarChart width={240} height={80} data={chart.data}>
            <Bar dataKey={chart.key} fill="#8886d6" />
          </BarChart>
        )}

        {barChart2 && (
          <div>
            <BarChart width={50} height={40} data={chart.data}>
              <Bar dataKey={chart.key} fill="#8886d6" />
            </BarChart>

            <BarChart width={50} height={40} data={chart2.data}>
              <Bar dataKey={chart2.key} fill="#8886d6" />
            </BarChart>
          </div>
        )}

        {doubleLineChart && (
          <SimpleLineChart data={chart.data} dataKeys={chart.keys} />
        )}

        {doubleLineChart2 && (
          <SimpleLineChart2 data={chart.data} dataKeys={chart.keys} />
        )}

        {radialChart && <CustomChart data={chart.data} dataKey={chart.key} />}

        {radialChart2 && <CustomChart2 data={chart.data} dataKey={chart.key} />}

        {radialChart3 && <CustomChart3 data={chart.data} dataKey={chart.key} />}

        {radialBarChart && (
          <SimpleRadialBarChart data={chart.data} dataKey={chart.key} />
        )}

        {radialBarChart2 && (
          <SimpleRadialBarChart2 data={chart.data} dataKey={chart.key} />
        )}

        {doubleBarChart && (
          <SimpleBarChart data={chart.data} dataKeys={chart.keys} />
        )}

        {singleBarChart && (
          <SimpleBarChart3 data={chart.data} dataKey={chart.key} />
        )}

        {complexChart && (
          <ComplexAreaChart data={chart.data} dataKeys={chart.keys} />
        )}
      </div>
    )}
  </Segment>
);

export default Box;
