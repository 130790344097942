import React from "react";
import UI from "./UI";

class List extends React.Component {
  state = {
    agents: [],
    lL: false,
    lE: false,
    lM: "",
    lR: null
  };
  componentDidMount = () => this.handleFetch();

  handleFetch = () => {
    let { profile_id, agents } = this.props;

    this.setState({ lL: true, lE: false, lM: "", lR: null });

    // GET(`v2/projects/${project_id}/agents`)
    //   .then(data => {
    //     this.setState({
    //       agents: data.map(agent => ({
    //         agent_name: agent.users.name,
    //         agent_id: agent.users.id,
    //         savings_group_count: agent.users.sg_count,
    //         partner: agent.partner_name,
    //         partner_id: agent.partner_id,
    //         project_id: project_id
    //       })),
    //       lL: false,
    //       lE: false,
    //       lM: "",
    //       lR: null
    //     });
    //   })
    //   .catch(error => {
    //     this.setState({ lL: false, lE: true, lM: error, lR: null });
    //   });

    this.setState({
      agents: agents.map(agent => ({
        agent_name: agent.name,
        // agent_id: agent.users.id,
        savings_group_count: agent.saving_groups,
        members_count: agent.members,
        organization_name: agent.organization_name,
        profile_id: profile_id
      })),
      lL: false,
      lE: false,
      lM: "",
      lR: null
    });    

  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default List;
