import React from "react";

import { GET } from "./../../../../../../utils/api";

import UI from "./UI";
var moment = require("moment");

class List extends React.Component {
  state = {
    projects: [],
    lL: false,
    lE: false,
    lM: "",
    lR: null
  };
  componentDidMount = () => this.handleFetch();

  handleFetch = () => {
    let { org_id } = this.props;

    this.setState({ lL: true, lE: false, lM: "", lR: null });

    GET(`save-${global.platform}/organization/projects/${org_id}`)
      .then(data => {
        this.setState({
          projects: data.map(proj => ({
            ...proj,
            ...proj.project,
            partner_name: proj.project_partner.partner_name,
            partner_id: proj.project_partner.partner_id,
            duration: (() => {
              let duration = "-";
              try {
                let x = new moment(proj.project.start);
                let y = new moment(proj.project.end);
                duration = moment.duration(x.diff(y)).humanize();
              } catch (exc) {}
              return duration;
            })()
          })),
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: null });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default List;
