import React from "react";

import { Modal, Button, Checkbox, Loader, Icon } from "semantic-ui-react";

class MySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, value: props.value };
  }

  static defaultProps = {
    name: "select",
    onChange: () => {},
    value: [],
    placeholder: "Select...",
    options: [],
    loading: false,
    disabled: false
  };

  UNSAFE_componentWillReceiveProps = ({ value }) => this.setState({ value });

  show = () => this.setState({ open: this.props.disabled ? false : true });
  close = () => this.setState({ open: false });

  toggle = val => {
    const { value } = this.state;

    let v = [...value];

    if (!v.includes(val)) {
      v.push(val);
    } else {
      v.splice(v.indexOf(val), 1);
    }

    this.setState({ value: v });
  };

  getText = () => {
    const { value } = this.state;
    const { options } = this.props;

    let texts = [];
    let opts = [];

    [...options].map(o => {
      if (!opts.includes(o.value)) {
        opts.push(o.value);
        if (value.includes(o.value)) texts.push(o.text);
      }
      return o;
    });

    return texts.join(", ");
  };

  isAll = () => {
    const { value } = this.state;
    const { options } = this.props;

    let opts = [];
    let notSelected = [];

    [...options].map(o => {
      if (!opts.includes(o.value)) {
        opts.push(o.value);
        if (!value.includes(o.value)) notSelected.push(o.value);
      }
      return o;
    });

    return notSelected.length === 0;
  };

  doAll = () => {
    const { options } = this.props;

    let opts = [];
    let value = [];

    if (!this.isAll()) {
      [...options].map(o => {
        if (!opts.includes(o.value)) {
          opts.push(o.value);
          value.push(o.value);
        }
        return o;
      });
    }

    this.setState({ value });
  };

  handleChange = () => {
    const { onChange, name } = this.props;
    const { value } = this.state;
    const { close } = this;

    let changed = false;

    this.props.value.map(v => {
      if (!value.includes(v)) changed = true;
      return v;
    });

    value.map(v => {
      if (!this.props.value.includes(v)) changed = true;
      return v;
    });

    if (changed) onChange(this, { name, value });

    close();
  };

  render = () => {
    const { open, value } = this.state;
    const { placeholder, options, disabled, loading } = this.props;
    const { show, close, getText, handleChange, toggle, isAll, doAll } = this;

    return (
      <div
        className={`ui fluid selection dropdown my-select${
          disabled ? " disabled" : ""
        }`}
      >
        <div
          className={
            value.length === 0 ? "my-select-text placeholder" : "my-select-text"
          }
          onClick={() => show()}
        >
          <span>{placeholder}</span>
          <span>{value.length === 0 ? placeholder : getText()}</span>
        </div>
        {loading ? (
          <div className="loader">
            <Loader size="small" inline />
          </div>
        ) : (
          <i
            aria-hidden="true"
            className="dropdown icon"
            onClick={() => show()}
          />
        )}
        <Modal
          size="mini"
          open={open}
          onClose={close}
          className="my-select-modal"
        >
          <Modal.Header>
            <div className="pannels-row my-select-header">
              <Checkbox
                fitted
                label={placeholder}
                checked={isAll()}
                onChange={() => doAll()}
              />
              <div className="flex-1" />
              <Icon name="close" fitted className="pointer" onClick={close} />
            </div>
          </Modal.Header>
          <Modal.Content>
            <div className="items">
              {(() => {
                let opts = [];
                let opts_ = [];

                [...options].map(o => {
                  if (!opts_.includes(o.value)) {
                    opts_.push(o.value);
                    opts.push(o);
                  }
                  return o;
                });

                return opts;
              })().map(option => (
                <div className="item" key={option.value}>
                  <Checkbox
                    label={option.text}
                    onChange={() => toggle(option.value)}
                    checked={value.includes(option.value)}
                  />
                </div>
              ))}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content="Yes"
              onClick={() => handleChange()}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  };
}

export default MySelect;
