import React from "react";
import { baseUrl, GET } from "./../../utils/api";
import UI from "./UI";

import {
  listItemNames,
  listItemsLinks,
  listItemsLinksFetch
} from "./../../utils/vars";

class Private extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: {},
      theSelectedItem: {},
      overview: {},
      listItems: [],
      defaultAreas: {},
      selectedItem: this.setSelected(window.location, true, true),
      searchStr: "",
      showList: false,
      lL: true,
      lE: false,
      lM: "",
      lR: null
    };
  }

  componentDidMount() {
    global.platform = ["ngo", "ssa", "sa", "", "fsp"][
      this.props.user.admin_type
    ];

    if (global.platform === "ssa")
      setTimeout(() => this.handleFetchOrganization(), 900);
    else if (global.platform === "ngo")
      setTimeout(() => this.handleFetchNGO(), 900);
    else if (global.platform === "fsp")
      setTimeout(() => this.handleFetchFSP(), 300000);
  }

  setSelected = (location, ret = false, noState = false) => {
    let { organization } = noState ? { organization: {} } : this.state;
    let { user } = this.props;
    let si = null;
    if (Object.keys(organization).length > 0) {
      if (
        location.pathname.includes(`/${listItemNames[user.admin_type]}s/`) &&
        !location.pathname.includes(`/${listItemNames[user.admin_type]}s/new`)
      ) {
        let path = location.pathname.split(
          `/${listItemNames[user.admin_type]}s/`
        );
        if (path.length > 1) {
          let sub = path[1].split(`/`);
          si = sub[0] * 1;
          if (!ret && this.state.selectedItem !== si) {
            this.setState({
              selectedItem: si
            });
          }
        }
      }
    } else if (!ret && this.state.selectedItem !== null) {
      this.setState({
        selectedItem: null
      });
    }

    return si;
  };

  selectItem = () => {
    let { selectedItem, listItems } = this.state;
    let selected = {};

    listItems.map(el => {
      if (el.id === selectedItem) {
        selected = { ...el };
      }
      return el;
    });

    return selected;
  };

  toggleItems = (isLink = false) => {
    let extra = {};
    if (isLink) {
      extra.theSelectedItem = {};
    }
    this.setState({ showList: !this.state.showList, ...extra });
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ searchStr: value });
  };

  listFilter = searchStr => {
    return this.state.listItems.filter(el =>
      el.name.toLowerCase().includes(searchStr.toLowerCase())
    );
  };

  updateOrg = data => {
    this.setState({
      organization: {
        ...this.state.organization,
        ...data
      }
    });
  };

  handleFetchFSP = () => {
    let { user } = this.props;
    let { selectedItem } = this.state;
    this.setState({ lL: true, lE: false });
    Promise.all([
      GET(`save-fsp/organization/${user.organization_id}/profiles/detail`),
      GET(`save-fsp/organization/${user.organization_id}/profiles/all`),
      GET(`save-fsp/organization/${user.organization_id}/fsp`),
      selectedItem !== null
        ? GET(
            `save-fsp/organization/${user.organization_id}/details/${selectedItem}`
          )
        : null,
      GET(`${baseUrl}location/provinces`)
    ])
      .then(data => {
        this.setState({
          lL: false,
          lE: false,
          lM: "",
          lR: null,
          organization: data[2],
          listItems: data[0],
          overview: data[1],

          defaultAreas: {
            village: [],
            villages: [],
            cell: [],
            cells: [],
            sector: [],
            sectors: [],
            district: [],
            districts: [],
            province: [],
            provinces: [
              ...data[4].map(a => ({
                text: a.name,
                label: a.name,
                value: a.id,
                key: a.value
              }))
            ]
          }
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.handleFetchFSP
        });
      });
  };

  handleFetchOrganization = () => {
    let { user } = this.props;
    let { selectedItem } = this.state;
    this.setState({ lL: true, lE: false });

    Promise.all([
      GET(`save-${global.platform}/organization/${user.organization_id}`),
      GET(`save-${global.platform}/${listItemsLinks[user.admin_type]}`),
      selectedItem !== null
        ? GET(
            `save-${global.platform}/${
              listItemsLinksFetch[user.admin_type]
            }/${selectedItem}`
          )
        : null,
      GET(`save-${global.platform}/organizations/summary`),
      selectedItem !== null
        ? GET(
            `save-${global.platform}/${
              listItemsLinksFetch[user.admin_type]
            }/mno_distribution/${selectedItem}`
          )
        : null,
      GET(`location/provinces`)
    ])
      .then(data => {
        this.setState({
          lL: false,
          lE: false,
          lM: "",
          lR: null,
          organization: { ...data[0].organization, more_data: data[0] },
          listItems: data[1],
          theSelectedItem:
            data[2] !== null ? { ...data[2], mno_distribution: data[4] } : {},
          overview: data[3],
          defaultAreas: {
            village: [],
            villages: [],
            cell: [],
            cells: [],
            sector: [],
            sectors: [],
            district: [],
            districts: [],
            province: [],
            provinces: [
              ...data[5].map(a => ({
                text: a.name,
                label: a.name,
                value: a.id,
                key: a.value
              }))
            ]
          }
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.handleFetchOrganization
        });
      });
  };

  handleFetchNGO = () => {
    let { user } = this.props;
    let { selectedItem } = this.state;
    this.setState({ lL: true, lE: false });

    Promise.all([
      GET(`save-ssa/organization/${user.organization_id}`),
      GET(`save-ssa/organization/projects/${user.organization_id}`),
      selectedItem !== null ? GET(`save-ssa/project/${selectedItem}`) : null,
      selectedItem !== null
        ? GET(`save-ssa/project/mno_distribution/${selectedItem}`)
        : null,
      GET(`location/provinces`)
    ])
      .then(data => {
        this.setState({
          lL: false,
          lE: false,
          lM: "",
          lR: null,
          organization: { ...data[0].organization, more_data: data[0] },
          listItems: [
            ...data[1].map(d => ({
              ...d,
              ...d.project,
              project_details: { project_partner: d.project_partner }
            }))
          ],
          theSelectedItem:
            data[2] !== null ? { ...data[2], mno_distribution: data[3] } : {},
          defaultAreas: {
            village: [],
            villages: [],
            cell: [],
            cells: [],
            sector: [],
            sectors: [],
            district: [],
            districts: [],
            province: [],
            provinces: [
              ...data[4].map(a => ({
                text: a.name,
                label: a.name,
                value: a.id,
                key: a.value
              }))
            ]
          }
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.handleFetchNGO
        });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default Private;
