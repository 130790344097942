import React from "react";
import { Form, Input, Select, Message, Divider, Icon } from "semantic-ui-react";
import phoneVerify from "./../../../../../utils/phoneVerify";
import validator from "validator";
import { organizationTypes, countries } from "./../../../../../utils/vars";

import Add from "./../Users/Add";

const UI = ({
  lL,
  lS,
  lE,
  lM,
  org_id,
  org_name,
  org_email,
  org_phone,
  org_type,
  org_address,
  org_country,
  handleSave,
  handleChange,
  history
}) => {
  return lS ? (
    <Add userOrg={{id:org_id}} orgName={org_name} orgAdmin={true} />
  ) : (
    <div className="pannel-scroll">
      <div className="padding">
        <div className="header">
          {history && history.goBack && (
            <div className="back" onClick={() => history.goBack()}>
              <Icon name="arrow left" fitted size="large" />
            </div>
          )}
          <span className="title">New Organization</span>
        </div>
        <Form loading={lL} success={lS} error={lE} onSubmit={handleSave}>
          <Form.Field
            id="form-input-org_name"
            name="org_name"
            value={org_name}
            control={Input}
            label="Name"
            placeholder="Name..."
            error={validator.isEmpty(org_name)}
            onChange={handleChange}
          />
          <Form.Field
            id="form-input-org_email"
            name="org_email"
            value={org_email}
            control={Input}
            label="Email"
            type="email"
            placeholder="Email..."
            error={!validator.isEmail(org_email)}
            onChange={handleChange}
          />
          <Form.Field
            id="form-input-org_phone"
            name="org_phone"
            value={org_phone}
            control={Input}
            label="Phone"
            type="tel"
            placeholder="Phone..."
            error={!phoneVerify(org_phone).ok}
            onChange={handleChange}
          />
          <Form.Field
            name="org_type"
            value={org_type}
            control={Select}
            fluid
            options={organizationTypes}
            label={{ children: "Type", htmlFor: "form-input-org_type" }}
            placeholder="Type..."
            search
            searchInput={{ id: "form-input-org_type" }}
            error={!validator.isInt(`${org_type}`)}
            onChange={handleChange}
          />
          <Form.Field
            id="form-input-org_address"
            name="org_address"
            value={org_address}
            control={Input}
            label="Address"
            placeholder="Address..."
            error={validator.isEmpty(org_address)}
            onChange={handleChange}
          />
          <Form.Field
            name="org_country"
            value={org_country}
            control={Select}
            fluid
            options={countries}
            label={{ children: "Country", htmlFor: "form-input-org_country" }}
            placeholder="Country..."
            search
            searchInput={{ id: "form-input-org_country" }}
            error={validator.isEmpty(`${org_country}`)}
            onChange={handleChange}
          />
          <Message error content={lM} />
          <Message success content={`Organization created!`} />
          <Divider />
          <Form.Button primary floated="right">
            Create
          </Form.Button>
        </Form>
      </div>
    </div>
  );
};

export default UI;
