import React from "react";

import { Loader, Header, Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

class ContentLoader extends React.Component {
  render() {
    let {
      children,
      lL,
      lE,
      lM,
      lR,
      message,
      showContent = false,
      showContentMessage = "Not found!",
      showContentAction = null,
      inverted = false
    } = this.props;

    if (showContent === true && lL === false && lE === false) {
      return children;
    } else {
      return (
        <div className={`content-loader ${inverted ? "inverted" : ""}`}>
          <div>
            {(lE || (!lL && !lE && !showContent)) && (
              <Icon
                name="warning circle"
                size="huge"
                disabled
                fitted
                inverted={inverted}
              />
            )}
            {lL && <Loader active inline="centered" inverted={inverted} />}
            {lL && <Header inverted={inverted}>{message}</Header>}
            {lE && <Header inverted={inverted}>{lM}</Header>}
            {!lL && !lE && !showContent && (
              <Header inverted={inverted}>{showContentMessage}</Header>
            )}
            {!lL && !lE && !showContent && showContentAction && (
              <Button primary as={Link} to={showContentAction.link}>
                {showContentAction.text}
              </Button>
            )}
            {lE && lR && (
              <Button icon labelPosition="left" onClick={() => lR()}>
                <Icon name="redo" />
                Retry
              </Button>
            )}
          </div>
        </div>
      );
    }
  }
}

export default ContentLoader;
