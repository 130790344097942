import React from "react";
import { Switch, Route } from "react-router-dom";
import List from "./List";
import Partner from "./Partner";
import New from "./New";

const UI = props => (
  <Switch>
    <Route
      exact
      path={`/projects/${props.project_id}/partners`}
      render={props_ => <List {...props} {...props_} />}
    />
    <Route
      exact
      path={`/projects/${props.project_id}/partners/new`}
      render={props_ => <New {...props} {...props_} />}
    />
    <Route
      path={`/projects/${props.project_id}/partners/:partner_id`}
      render={props_ => (
        <Partner
          {...props}
          {...props_}
          partner_id={props_.match.params.partner_id}
        />
      )}
    />
  </Switch>
);

export default UI;
