import React from "react";
import UI from "./UI";

import { GET, baseUrl } from "./../../../../../../../utils/api";

class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sg: {
        saving_group: {},
        savings_vs_loans_distribution: [],
        mno_distribytion_by_province: [],
        sg_share_details: {},
        location: {
          province: {},
          district: {},
          sector: {},
          cell: {},
          village: {}
        }
      },
      lL: false,
      lE: false,
      lM: "",
      lR: null
    };
  }

  componentDidMount = () => this.fetchData();

  fetchData = () => {
    let { sg_id } = this.props;
    this.setState({ lL: true, lE: false });

    GET(`save-${global.platform}/sg/${sg_id}`)
      .then(this.fetchLocation)
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.fetchData
        });
      });
  };

  fetchLocation = sg => {
    GET(`${baseUrl}location/village/${sg.saving_group.village_id}`)
      .then(data => {
        this.setState({
          sg: { ...sg, location: data },
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.fetchLocation(sg)
        });
      });
  };

  render = () =>{
    const count=this.state.sg.female_sg_members_count + this.state.sg.male_sg_members_count;
  
  return (<UI {...this.props} {...this.state} {...this} count={count} />)

  }
}

export default Overview;
