import React, { Component } from "react";
import "./App.scss";
import Private from "./pages/private";
import Public from "./pages/public";
import updateSession from "./utils/update-session";
import { Loader } from "semantic-ui-react";
import dotenv from 'dotenv';


dotenv.config();


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingSession: true,
      signedIn: false,
      user: {}
    };

    global.updateSession = user => {
      updateSession(this, user);
    };
  }

  componentDidMount() {
    setTimeout(() => global.updateSession(true), 2000);
  }

  render() {
    let { signedIn, user, loadingSession } = this.state;
    if (loadingSession) {
      return (
        <div id="app" className="public-forms">
          <Loader active inverted inline="centered" />
        </div>
      );
    } else {
      return signedIn ? <Private {...this.props} user={user} /> : <Public />;
    }
  }
}

export default App;
