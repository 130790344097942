import React from "react";
import { Switch, Route } from "react-router-dom";
import List from "./List";
import SavingGroup from "./SavingGroup";
import { Grid } from "semantic-ui-react";

import Box from "./../../../../../components/Box";
import Boxxx from "./../../../../../components/Boxxx";
import numberFormat from "./../../../../../utils/numberFormat";

const UI = (props) => (
  <div className="pannels-row">
    <Switch>
      <div className="pannels-column">
        <Route
          exact
          path={`/profiles/${props.profile_id}/saving-groups`}
          render={props_ => (
            <div className="pannel-scroll-inside">
              <List {...props} {...props_} />
            </div>
          )}
        />
        <Route
        path={`/profiles/${props.profile_id}/saving-groups/:sg_id`}
          render={props_ => <SavingGroup {...props} {...props_} />}
        />
      </div>
    </Switch>

    <Switch>
      <Route
        exact
        path={`/profiles/${props.profile_id}/saving-groups`}
        render={() => (
          <div className="aside-saving-groups">
            <div className="in-aside-savings-groups">
              <Grid columns={1}>
                <Grid.Row>
                    <Box
                      title="Male Members Count"
                      value={numberFormat(props.profile.savings_result.total_male_count)}
                      chart={{
                        data: props.profile.male_member_enrollment_per_month,
                        key: "count"
                      }}
                      icon="male"
                      barChart
                    />
                </Grid.Row>

                <Grid.Row>
                    <Box
                      title="Female Members Count"
                      value={numberFormat(props.profile.savings_result.total_female_count)}
                      chart={{
                        data: props.profile.female_member_enrollment_per_month,
                        key: "count"
                      }}
                      icon="female"
                      barChart
                    />
                </Grid.Row>

                <Grid.Row stretched>
                  <Boxxx
                      title="Total Savings"
                      value={numberFormat(props.profile.savings_result.total_savings)}
                      chart={{
                        data: props.profile.cumulative_vs_current_savings.current_savings,
                        key: "savings"
                      }}
                      
                      title2="Total Loans"
                      value2={numberFormat(props.profile.savings_result.total_loans)}
                      chart2={{
                        data: props.profile.cumulative_loans_vs_current_loans.current_loans,
                        key: "amount"
                      }}
                      lineChart
                    />
                </Grid.Row>

              </Grid>
            </div>
          </div> 
        )}
      />
    </Switch>
  </div>
);

export default UI;
