import React from "react";
import UI from "./UI";

class Overview extends React.Component {
  state = {
    agentEnrollmentByGender: [],
    cumulativeVsCurrentSavings: [],
    cumulativeVsOutstandingLoans: []
  };

  componentDidMount = () => {
    this.setState({
      agentEnrollmentByGender: this.getFormattedAgentEnrolmentByGender(),
      cumulativeVsCurrentSavings: this.getFormattedCumulativeVsCurrentSavings(),
      cumulativeVsOutstandingLoans: this.getFormattedCumulativeVsOutstandingLoans()
    });
  };

  getFormattedAgentEnrolmentByGender = () => {
    let { profile } = this.props;
    const c = [];
    const d = [];

    profile.male_agents_enrollment_per_month.forEach(function(element) {
      c.push({
        month: element.month,
        male_count: element.count !== undefined ? element.count : 0,
        female_count:
          profile.female_agents_enrollment_per_month.find(
            e => e.month === element.month
          ) !== undefined
            ? profile.female_agents_enrollment_per_month.find(
                e => e.month === element.month
              ).count
            : 0
      });
    });

    profile.female_agents_enrollment_per_month.forEach(function(element) {
      d.push({
        month: element.month,
        male_count:
          profile.male_agents_enrollment_per_month.find(
            e => e.month === element.month
          ) !== undefined
            ? profile.male_agents_enrollment_per_month.find(
                e => e.month === element.month
              ).count
            : 0,
        female_count: element.count !== undefined ? element.count : 0
      });
    });

    const e = [...c, ...d];
    const result = [];

    const map = new Map();
    for (const item of e) {
      if (!map.has(item.month)) {
        map.set(item.month, true);
        result.push({
          month: item.month,
          male_count: item.male_count,
          female_count: item.female_count
        });
      }
    }

    return result;
  };

  getFormattedCumulativeVsCurrentSavings = () => {
    let { profile } = this.props;
    const c = [];
    const d = [];

    profile.cumulative_vs_current_savings.cumulative_savings.forEach(function(
      element
    ) {
      c.push({
        month: element.month,
        cumulative_savings: element.savings !== undefined ? element.savings : 0,
        current_savings:
          profile.cumulative_vs_current_savings.current_savings.find(
            e => e.month === element.month
          ) !== undefined
            ? profile.cumulative_vs_current_savings.current_savings.find(
                e => e.month === element.month
              ).savings
            : 0
      });
    });

    profile.cumulative_vs_current_savings.current_savings.forEach(function(
      element
    ) {
      d.push({
        month: element.month,
        cumulative_savings:
          profile.cumulative_vs_current_savings.current_savings.find(
            e => e.month === element.month
          ) !== undefined
            ? profile.cumulative_vs_current_savings.current_savings.find(
                e => e.month === element.month
              ).savings
            : 0,
        current_savings: element.savings !== undefined ? element.savings : 0
      });
    });

    const e = [...c, ...d];
    const result = [];

    const map = new Map();
    for (const item of e) {
      if (!map.has(item.month)) {
        map.set(item.month, true);
        result.push({
          month: item.month,
          cumulative_savings: item.cumulative_savings,
          current_savings: item.current_savings
        });
      }
    }

    return result;
  };

  getFormattedCumulativeVsOutstandingLoans = () => {
    let { profile } = this.props;
    const c = [];
    const d = [];

    profile.cumulative_loans_vs_current_loans.cumulative_loans.forEach(function(
      element
    ) {
      c.push({
        month: element.month,
        cumulative_loans: element.amount !== undefined ? element.amount : 0,
        outstanding_loans:
          profile.cumulative_loans_vs_current_loans.current_loans.find(
            e => e.month === element.month
          ) !== undefined
            ? profile.cumulative_loans_vs_current_loans.current_loans.find(
                e => e.month === element.month
              ).amount
            : 0
      });
    });

    profile.cumulative_loans_vs_current_loans.current_loans.forEach(function(
      element
    ) {
      d.push({
        month: element.month,
        cumulative_loans:
          profile.cumulative_loans_vs_current_loans.current_loans.find(
            e => e.month === element.month
          ) !== undefined
            ? profile.cumulative_loans_vs_current_loans.current_loans.find(
                e => e.month === element.month
              ).amount
            : 0,
        outstanding_loans: element.amount !== undefined ? element.amount : 0
      });
    });

    const e = [...c, ...d];
    const result = [];

    const map = new Map();
    for (const item of e) {
      if (!map.has(item.month)) {
        map.set(item.month, true);
        result.push({
          month: item.month,
          cumulative_loans: item.cumulative_loans,
          outstanding_loans: item.outstanding_loans
        });
      }
    }

    return result;
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default Overview;
