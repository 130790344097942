import React from "react";
import { NavLink, Switch, Route } from "react-router-dom";
import Details from "./Details";
import Users from "./Users";

const UI = props => (
  <div className="pannels-row">
    <div className="sidebar">
      <div className="header">
        <span className="title">Organization</span>
      </div>
      <NavLink
        to={`/organizations/${props.id}/settings`}
        exact
        activeClassName="active"
      >
        Details
      </NavLink>
      <NavLink
        to={`/organizations/${props.id}/settings/users`}
        activeClassName="active"
      >
        Users
      </NavLink>
    </div>
    <div className="pannels-column">
      <Switch>
        <Route
          exact
          path={`/organizations/${props.id}/settings`}
          render={props_ => <Details {...props} {...props_} id={props.id} />}
        />
        <Route
          path={`/organizations/${props.id}/settings/users`}
          render={props_ => <Users {...props} {...props_} id={props.id} />}
        />
      </Switch>
    </div>
  </div>
);

export default UI;
