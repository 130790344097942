import React from "react";

import { GET } from "../../../../../../../../utils/api";

import UI from "./UI";

class List extends React.Component {
  state = {
    members: [],
    lL: false,
    lE: false,
    lM: "",
    lR: null
  };
  componentDidMount = () => this.handleFetch();

  handleFetch = () => {
    let { sg_id, org_id } = this.props;

    this.setState({ lL: true, lE: false, lM: "", lR: null });

    GET(`save-${global.platform}/sg/members/${sg_id}`)
      .then(data => {
        this.setState({
          members: data.map(item => ({
            ...item.user_details,
            id: item.id,
            admin: item.admin,
            user_id: item.user_id,
            sg_id: sg_id,
            org_id: org_id
          })),
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: null });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default List;
