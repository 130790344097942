import React from "react";
import { Switch, Route } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import List from "./List";
import Agent from "./Agent";

import Box from "./../../../../../components/Box";

var moment = require("moment");

const UI = props => (
  // <div className="pannels-row" style={{ overflowY: "auto" }}>
  <div className="pannels-row">
    <Switch>
      <div className="pannels-column">
        <Route
          exact
          // path={`/projects/${props.project_id}/agents`}
          path={`/profiles/${props.profile_id}/agents`}
          render={props_ => (
            <div className="pannel-scroll-inside">
              <List {...props} {...props_} />
            </div>
          )}
        />
        {/* <Route
        path={`/projects/${props.project_id}/agents/invite`}
        render={props_ => <Invite {...props} {...props_} />}
        /> */}
        <Route
          // path={`/projects/${props.project_id}/agents/:agent_id`}
          path={`/profiles/${props.profile_id}/agents/:agent_id`}
          render={props_ => (
            <Agent
              {...props}
              {...props_}
              agent_id={props_.match.params.agent_id}
            />
          )}
        />
      </div>
    </Switch>
    <Switch>
      <Route
        exact
        path={`/profiles/${props.profile_id}/agents`}
        render={props_ => (
          <div className="aside-agents">
            <div className="in-aside-agents">
              <Grid columns={2} style={{ marginRight: "0px" }}>
                <Grid.Row>
                  <Grid.Column
                    style={{ paddingLeft: "0px", paddingRight: "1rem" }}
                  >
                    <Box
                      title="Male Agent Count"
                      // value={profile.savings_result.total_male_count}
                      value={props.profile.male_agent_count}
                      chart={{
                        data: props.profile.male_agents_enrollment_per_month,
                        key: "count"
                      }}
                      icon="male"
                      barChart
                    />
                  </Grid.Column>

                  <Grid.Column
                    style={{ paddingLeft: "1rem", paddingRight: "0px" }}
                  >
                    <Box
                      title="Female Agent Count"
                      // value={profile.savings_result.total_male_count}
                      value={props.profile.female_agent_count}
                      chart={{
                        data: props.profile.female_agents_enrollment_per_month,
                        key: "count"
                      }}
                      icon="female"
                      barChart
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Box
                    title="Agent Level Of Education"
                    chart={{
                      data: [
                        ...props.profile.agent_education.map(d => ({
                          name: d.month,
                          caption: "Agents:",
                          count: d.count
                        }))
                      ],
                      key: "count"
                    }}
                    radialChart3
                  />
                </Grid.Row>

                <Grid.Row>
                  <Box
                    title="Agent enrolment per gender"
                    chart={{
                      data: [...props.agentEnrollmentByGender.map(d => ({
                        Male: d.male_count,
                        Female: d.female_count,
                        name: moment(d.month).format("MMM")
                      }))],
                      keys: ["Male", "Female"]
                    }}
                    doubleLineChart2
                  />
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}
      />
    </Switch>
  </div>
);

export default UI;
