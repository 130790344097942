import React from "react";

import { POST } from "./../../../../../../utils/api";
import formErrors from "./../../../../../../utils/formErrors";

import UI from "./UI";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      old_password: "",
      new_password: "",
      new_password_repeat: "",
      lL: false,
      lE: false,
      lM: "",
      lS: false
    };
  }

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, lE: false, lS: false });

  handleSave = (...e) => {
    let { currentUser } = this.props;
    let { old_password, new_password, new_password_repeat } = this.state;

    if (formErrors(e).ok && new_password === new_password_repeat) {
      this.setState({
        lL: true,
        lE: false,
        lS: false,
        lM: ""
      });

      POST(`save-ssa/user/change_pwd/${currentUser.id}`, {
        old_password,
        new_password
      })
        .then(data => {
          this.setState({
            lL: false,
            lE: false,
            lS: true,
            lM: "Password updated!"
          });
        })
        .catch(error => {
          this.setState({
            lL: false,
            lE: true,
            lS: false,
            lM: error
          });
        });
    }
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default ChangePassword;
