import React from "react";
import ContentLoader from "./../../../../../../components/Loader";
import DataTable from "./../../../../../../components/DataTable";

const UI = ({ 
    lL, lE, lM, lR, 
    history,
    members_requests,
    sg_name
}) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Fetching requests..."
    showContent={members_requests.length !== 0}
  >
  <div className="pannel-scroll">
    <DataTable
      title={`${sg_name} / Requests`}
      history={history}
      dataKey="saving_group_id"
      empty="No requests."
      data={members_requests}
      columns={[
        {
          dataField: "member_name",
          text: "Saving group",
          sort: true,
          formatter: "name"
        },
        {
          dataField: "amount_requested",
          text: "Amount",
          sort: true,
          textPrefix: " (RWF)",
          formatter: "number"
        },
        {
          dataField: "repayment_date",
          text: "Repayment Date",
          sort: true
        },
        {
          dataField: "savings",
          text: "Savings",
          sort: true,
          textPrefix: " (RWF)",
          formatter: "number"
        },
        {
          dataField: "status",
          text: "Status",
          sort: true,
          formatter: "status",
          badge2: {
            statusArray: ["Declined", "Approved", "Pending"],
            status: 
              ({status}) => 
                status.toLowerCase() === "approved" ? 
                  1 : (
                    status.toLowerCase() === "pending" ?
                      2 :
                      0
                  )
            ,
            request: true
          }
        }
      ]}
    />
    </div>
  </ContentLoader>
);

export default UI;
