import React from "react";

import {
  Icon,
  Button,
  Sidebar,
  Segment,
  Form,
  Input,
  Select,
  Header
} from "semantic-ui-react";

import ContentLoader from "./../../../../../../../components/Loader";
import MyList from "./../../../../../../../components/MyList";
import capitalize from "./../../../../../../../utils/capitalize";
import numberFormat from "./../../../../../../../utils/numberFormat";

var moment = require("moment");

const UI = ({
  lL,
  lE,
  lM,
  lR,
  filter,
  activateFilter,
  clearFilters,
  activity,
  sg,
  tab,
  tabs,
  activate,
  messages,
  history,
  handleChange,
  name,
  type,
  options,
  items,
  opt
}) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Fetching activity..."
    showContent={activity.length !== 0}
  >
    <Sidebar.Pushable className="my-filter-panel">
      <Sidebar
        as={Segment}
        direction="right"
        animation="overlay"
        visible={filter}
        width="wide"
      >
        <div className="padding">
          <div className="header">
            <div className="back" onClick={() => activateFilter(!filter)}>
              <Icon name="arrow left" fitted size="large" />
            </div>
            <span className="title">Filter</span>
            <Button size="small" onClick={clearFilters} content="Clear" />
          </div>
        </div>
        <div className="padding">
          <Form>
            <Form.Field
              id="input-name"
              name="name"
              value={name}
              control={Input}
              label="Name"
              placeholder="Name..."
              onChange={handleChange}
            />
            <Form.Field
              name="type"
              value={type}
              control={Select}
              fluid
              options={options()}
              label="Type"
              placeholder={options()[0].text}
              onChange={handleChange}
            />
          </Form>
        </div>
      </Sidebar>
      <Sidebar.Pusher>
        <div className="pannel-scroll">
          <div className="padding">
            <div className="header">
              {history && history.goBack && (
                <div className="back" onClick={() => history.goBack()}>
                  <Icon name="arrow left" fitted size="large" />
                </div>
              )}
              <span className="title">Saving Group: {sg.name}</span>
              <Button
                size="small"
                basic={tab !== 0}
                color="blue"
                onClick={() => activate(0)}
              >
                {tabs[0]}
              </Button>
              <div className="padder" />
              <Button
                size="small"
                basic={tab !== 1}
                color="blue"
                onClick={() => activate(1)}
              >
                {tabs[1]}
              </Button>
              <div className="padder" />
              <Button
                icon="filter"
                size="small"
                basic={!filter}
                color="blue"
                onClick={() => activateFilter(!filter)}
              />
            </div>
            <Header as="h2">
              {tabs[tab]} Log
              {name !== "" && <span className="nameFilter">"{name}"</span>}
              {type !== "" && (
                <span className="typeFilter">"{capitalize(opt(type))}"</span>
              )}
            </Header>
            <MyList
              className="activity"
              items={items()}
              left="member_name"
              title="transaction_type"
              titleFn={t => capitalize(opt(t))}
              description={[
                "member_name",
                "amount",
                "transaction_time",
                "transaction_type"
              ]}
              descriptionFn={t => [
                <span>
                  <span className="name">{t[0]}</span> {messages[t[3]]}{" "}
                  <span className="amount">
                    {numberFormat(t[1], "", "RWF")}
                  </span>{" "}
                  <span className="time">{moment(t[2]).fromNow()}</span>
                </span>
              ]}
            />
          </div>
        </div>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  </ContentLoader>
);

export default UI;
