import React from "react";
import { Route,NavLink,Switch, Link } from "react-router-dom";
import {Button} from "semantic-ui-react";
import MyList from "./../../../../components/MyList";

import Activity from "./Activity";
import Overview from "./Overview";
import Users from "./Users";
import Wallet from "./Wallet";
import Settings from "./Settings";
import NewOrganization from "./NewOrganization";

import { organizationTypes } from "./../../../../utils/vars";

const UI = props => (
  <div className = "pannels-row">
    <div className="sidebar">
      <div className="header">
        <span className="title">Dashboard</span>
      </div>
      <NavLink to={`/`} exact activeClassName="active">
        Activity
      </NavLink>
      <NavLink to={`/overview`} exact activeClassName="active">
        Overview
      </NavLink>
      <NavLink to={`/users`} activeClassName="active">
        Users
      </NavLink>
      {props.currentUser.admin_type === 1 && props.currentUser.is_admin === 1 && (
        <NavLink to={`/wallet`} exact activeClassName="active">
          Wallet
        </NavLink>
      )}
      <NavLink to={`/settings`} exact activeClassName="active">
        Settings
      </NavLink>
    </div>
    <div className="pannels-column">
      <Switch>
        <Route
          exact
          path={`/`}
          render={props_ => <Activity {...props} {...props_} />}
        />
        <Route
          exact
          path={`/overview`}
          render={props_ => <Overview {...props} {...props_} />}
        />
        <Route
          path={`/users`}
          render={props_ => <Users {...props} {...props_} />}
        />
        <Route
          path={`/wallet`}
          render={props_ => <Wallet {...props} {...props_} />}
        />
        <Route
          exact
          path={`/settings`}
          render={props_ => <Settings {...props} {...props_} />}
        />
        <Route
          exact
          path={`/new`}
          render={props_ => <NewOrganization {...props} {...props_} />}
        />
      </Switch>
    </div>
    <Switch>
      <Route
        exact
        path={`/`}
        render={() => (
          <div style={{ width: "400px" }}>
            <div className="pannel-scroll">
              <div className="padding">
                <div className="header">
                  <span className="title">Organizations</span>
                  <Button
                    size="small"
                    as={Link}
                    to="/organizations"
                    content="View All"
                  />
                </div>
              </div>
              <MyList
                items={(() => {
                  let orgs = [];
                  props.organizations.map(org => {
                    if (org.type <= 4) orgs.push(org);
                    return org;
                  });
                  return orgs;
                })().slice(0, 5)}
                left="name"
                title="name"
                description={["type"]}
                descriptionFn={t => {   
                  return organizationTypes[t[0]-1] ? [organizationTypes[t[0]-1].text] : [];
                }}
                as={Link}
                link={["organizations", "[(id)]"]}
              />
            </div>
          </div>
        )}
      />
    </Switch>
  </div>
);

export default UI;
