import React from "react";
import { Icon, Grid, Button } from "semantic-ui-react";
import ContentLoader from "./../../../../../../../components/Loader";
import Box from "./../../../../../../../components/Box";
import numberFormat from "./../../../../../../../utils/numberFormat";
import nTh from "./../../../../../../../utils/nTh";
import { Link } from "react-router-dom";

var moment = require("moment");

const UI = ({ sg,count, history, lL, lE, lM, lR }) =>(
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Wait a moment..."
    showContent={Object.keys(sg).includes("sg_members_count")}
  >
    <div className="pannel-scroll">
      <div className="padding">
        <div className="header">
          {history && history.goBack && (
            <div className="back" onClick={() => history.goBack()}>
              <Icon name="arrow left" fitted size="large" />
            </div>
          )}
          <span className="title">Saving Group: {sg.saving_group.name}</span>
          <Button
            size="small"
            as={Link}
            to={`/organizations/${
              sg.saving_group.organization_id
            }/saving-groups/${sg.saving_group.id}/activity`}
          >
            Recent Activity
          </Button>
          <Button
            size="small"
            as={Link}
            to={`/organizations/${
              sg.saving_group.organization_id
            }/saving-groups/${sg.saving_group.id}/members`}
            content="View Members"
            label={count.toString()}
          />
        </div>
        <Grid columns={4}>
          <Grid.Row stretched>
            <Grid.Column width={4}>
              <Box
                title="CUMULATIVE SAVINGS"
                value={sg.total_savings}
                unit="RWF"
                color="green"
                chart={{
                  data: sg.savings_vs_loans_distribution,
                  key: "savings_amount"
                }}
                lineChart
              />
              <Box
                title="CUMULATIVE LOANS"
                value={sg.total_loans}
                unit="RWF"
                color="orange"
                chart={{
                  data: sg.savings_vs_loans_distribution,
                  key: "loans_amount"
                }}
                lineChart
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Box
                title="SOCIAL FUND"
                value={sg.total_social_fund}
                unit="RWF"
                color="blue"
                chart={{
                  data: sg.savings_vs_loans_distribution,
                  key: "social_fund_amount"
                }}
                lineChart
              />
              <Box
                title="OUTSTANDING LOANS"
                value={sg.total_outstanding_loans}
                unit="RWF"
                color="red"
                chart={{
                  data: sg.savings_vs_loans_distribution,
                  key: "outstanding_loans_amount"
                }}
                lineChart
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Box
                name={sg.saving_group.name}
                rows={[
                  [
                    {
                      label: "Minimum Share:"
                    },
                    {
                      label: numberFormat(sg.sg_share_details.share, "", "RWF"),
                      className: "labelFlat"
                    }
                  ],
                  [
                    {
                      label: "Maximum Share:"
                    },
                    {
                      label: numberFormat(
                        sg.sg_share_details.max_share,
                        "",
                        "RWF"
                      ),
                      className: "labelFlat"
                    }
                  ],
                  [
                    {
                      label: "Social Fund Contribution:"
                    },
                    {
                      label: numberFormat(
                        sg.sg_share_details.social_fund,
                        "",
                        "RWF"
                      ),
                      className: "labelFlat"
                    }
                  ],
                  [
                    {
                      label: "Location:"
                    },
                    {
                      label: sg.location.province.name,
                      className: "labelFlat"
                    }
                  ],
                  [
                    { label: "" },
                    {
                      label: sg.location.district.name,
                      className: "labelFlat noMargin"
                    }
                  ],
                  [
                    { label: "" },
                    {
                      label: sg.location.sector.name,
                      className: "labelFlat noMargin"
                    }
                  ],
                  [
                    { label: "" },
                    {
                      label: sg.location.cell.name,
                      className: "labelFlat noMargin"
                    }
                  ],
                  [
                    { label: "" },
                    {
                      label: sg.location.village.name,
                      className: "labelFlat noMargin"
                    }
                  ],
                  [],
                  [
                    {
                      label: `${nTh(sg.sg_cycle_count)} Cycle`,
                      className: "labelBadge"
                    }
                  ],
                  [
                    {
                      label: `Created on ${moment(
                        sg.saving_group.creation_date
                      ).format("MMM DD, YYYY")}`,
                      className: "labelFlat footer right"
                    }
                  ]
                ]}
                noDate
                noChart
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Box
                title="Male Members"
                value={sg.male_sg_members_count}
                chart={{
                  data: sg.male_members_distribution,
                  key: "count"
                }}
                barChart
              />
              <Box
                title="Female Members"
                value={sg.female_sg_members_count}
                chart={{
                  data: sg.male_members_distribution,
                  key: "count"
                }}
                barChart
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column width={8}>
              <Box
                title="Savings VS Loans"
                chart={{
                  data: [
                    ...(sg.savings_vs_loans_distribution || []).map(d => ({
                      name: moment(d.month).format("MMM"),
                      Savings: d.savings_amount,
                      Loans: d.loans_amount
                    }))
                  ],
                  keys: ["Savings", "Loans"]
                }}
                doubleBarChart
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Box
                title="MNO Distribution Per Province"
                chart={{
                  data: [
                    ...sg.mno_distribytion_by_province.map(d => ({
                      name: d.province_name,
                      MTN: d.mtn_count,
                      TIGO: d.tigo_count
                    }))
                  ],
                  keys: ["MTN", "TIGO"]
                }}
                doubleBarChart
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  </ContentLoader>
);

export default UI;
