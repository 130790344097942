import React from "react";
import Toolbar from "./../../../../components/Toolbar";
import { ngoToolbar } from "./../../../../utils/vars";
import { Switch, Route } from "react-router-dom";
import { GET } from "./../../../../utils/api";
import ContentLoader from "./../../../../components/Loader";

import Overview from "./Overview";
import Partners from "./Partners";
import Agents from "./Agents";
import SavingGroups from "./SavingGroups";
import Settings from "./Settings";

class Project extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      project: {},
      lL: false,
      lE: false,
      lM: "",
      lR: null
    };
  }

  componentDidMount = () => this.fetchData();

  fetchData = () => {
    let { project_id, defaultAreas, projects } = this.props;
    this.setState({ lL: true, lE: false });

    Promise.all([
      GET(`save-ssa/project/${project_id}`),
      GET(`save-ssa/project/mno_distribution/${project_id}`),
      GET(`save-ssa/project/${project_id}/intervention_areas`)
    ])
      .then(data => {
        let p = {};

        projects.map(pr => {
          if (pr.id === project_id * 1) p = { created_by: pr.created_by };
          return pr;
        });

        this.setState({
          lL: false,
          lE: false,
          lM: "",
          lR: null,
          project: {
            ...p,
            ...(() => {
              let d = { ...data[0] };
              d.project_details.project_partner = d.project_partner;
              return d;
            })(),
            mno_distribution: data[1],
            areas: (() => {
              let areas = { ...defaultAreas, provinces: [] };

              let villages = [],
                cells = [],
                sectors = [],
                districts = [],
                provinces = [];

              data[2].map(a => {
                let {
                  village,
                  cell,
                  sector,
                  district,
                  province
                } = a.location_details;

                villages.push(village.id);
                areas.village.push(village.id);
                areas.villages.push({
                  text: village.name,
                  value: village.id,
                  key: village.id,
                  cell: cell.id,
                  sector: sector.id,
                  district: district.id,
                  province: province.id
                });

                if (!cells.includes(cell.id)) {
                  cells.push(cell.id);
                  areas.cell.push(cell.id);
                  areas.cells.push({
                    text: cell.name,
                    value: cell.id,
                    key: cell.id,
                    sector: sector.id,
                    district: district.id,
                    province: province.id
                  });
                }

                if (!sectors.includes(sector.id)) {
                  sectors.push(sector.id);
                  areas.sector.push(sector.id);
                  areas.sectors.push({
                    text: sector.name,
                    value: sector.id,
                    key: sector.id,
                    district: district.id,
                    province: province.id
                  });
                }

                if (!districts.includes(district.id)) {
                  districts.push(district.id);
                  areas.district.push(district.id);
                  areas.districts.push({
                    text: district.name,
                    value: district.id,
                    key: district.id,
                    province: province.id
                  });
                }

                if (!provinces.includes(province.id)) {
                  provinces.push(province.id);
                  areas.province.push(province.id);
                  areas.provinces.push({
                    text: province.name,
                    value: province.id,
                    key: province.id
                  });
                }

                return a;
              });

              return areas;
            })()
          }
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.fetchData
        });
      });
  };

  render() {
    let { project_id } = this.props.match.params;
    let { project, lL, lE, lM, lR } = this.state;
    return (
      <ContentLoader
        lL={lL}
        lE={lE}
        lM={lM}
        lR={lR}
        message="Wait a moment..."
        showContent={Object.keys(project).length !== 0}
      >
        <div className="pannels-column">
          <Toolbar prefix={`/projects/${project_id}`} links={ngoToolbar} />
          <Switch>
            <Route
              exact
              path={`/projects/${project_id}`}
              render={props => (
                <Overview
                  {...this.props}
                  project={project}
                  update={() => this.fetchData()}
                  {...props}
                  project_id={project_id}
                />
              )}
            />
            <Route
              path={`/projects/${project_id}/partners`}
              render={props => (
                <Partners
                  {...this.props}
                  project={project}
                  update={() => this.fetchData()}
                  {...props}
                  project_id={project_id}
                />
              )}
            />
            <Route
              path={`/projects/${project_id}/agents`}
              render={props => (
                <Agents
                  {...this.props}
                  project={project}
                  update={() => this.fetchData()}
                  {...props}
                  project_id={project_id}
                />
              )}
            />
            <Route
              path={`/projects/${project_id}/saving-groups`}
              render={props => (
                <SavingGroups
                  {...this.props}
                  project={project}
                  update={() => this.fetchData()}
                  {...props}
                  project_id={project_id}
                />
              )}
            />
            <Route
              path={`/projects/${project_id}/settings`}
              render={props => (
                <Settings
                  {...this.props}
                  project={project}
                  update={() => this.fetchData()}
                  {...props}
                  project_id={project_id}
                />
              )}
            />
          </Switch>
        </div>
      </ContentLoader>
    );
  }
}

export default Project;
