import React from "react";
import { GET } from "./../../../../../../utils/api";
import UI from "./UI";

class List extends React.Component {
  state = {
    partners: [],
    lL: false,
    lE: false,
    lM: "",
    lR: null
  };

  componentDidMount = () => this.handleFetch();

  handleFetch = () => {
    let { project_id } = this.props;

    this.setState({ lL: true, lE: false, lM: "", lR: null });
    GET(`save-ssa/project/${project_id}/partners`)
      .then(data => {
        this.setState({
          partners: [
            ...data.map(p => ({
              id: p.partner_id,
              name: p.partner_name,
              agents: p.agents_count,
              saving_groups: p.sgs_count,
              budget: p.budget,
              members: p.sg_members_count,
              project_id
            }))
          ],
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: null });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default List;
