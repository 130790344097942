import React from "react";

import { POST } from "./../../../utils/api";

import UI from "./UI";

class SetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      new_password: "",
      new_password_repeat: "",
      lL: false,
      lE: false,
      lM: "",
      lS: false
    };
  }

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, lE: false, lS: false });

  handleSubmit = (...e) => {
    let { code } = this.props;
    let { new_password } = this.state;

    this.setState({
      lL: true,
      lE: false,
      lS: false,
      lM: ""
    });

    POST(`save-ssa/reset_pwd/${code.replace("pwd-", "")}`, {
      password: new_password
    })
      .then(data => {
        this.setState({
          lL: false,
          lE: false,
          lS: true,
          lM: "Password updated!",
          new_password: "",
          new_password_repeat: ""
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lS: false,
          lM: error
        });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default SetPassword;
