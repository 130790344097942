import React from "react";
import UI from "./UI";

import { GET, baseUrl } from "./../../../../../../../utils/api";

class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sg: {
        saving_group: { status: 0 },
        savings_vs_loans_distribution: [],
        mno_distribytion_by_province: [],
        sg_share_details: {},
        location: {
          province: {},
          district: {},
          sector: {},
          cell: {},
          village: {}
        }
      },
      lL: false,
      lE: false,
      lM: "",
      lR: null
    };
  }

  componentDidMount = () => this.fetchData();

  fetchData = () => {
    let { sg_id } = this.props;
    this.setState({ lL: true, lE: false });

    GET(`save-ssa/sg/${sg_id}`)
      .then(this.fetchLocation)
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.fetchData
        });
      });
  };

  fetchLocation = sg => {
    GET(`${baseUrl}location/village/${sg.saving_group.village_id}`)
      .then(data => {
        this.setState({
          sg: {
            ...sg,
            savings_vs_loans_distribution: sg.savings_vs_loans_distribution
              ? sg.savings_vs_loans_distribution
              : [],
            location: data
          },
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.fetchLocation(sg)
        });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default Overview;
