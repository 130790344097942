import React from "react";
import { GET } from "./../../../../../../utils/api";
import UI from "./UI";
import _ from "lodash";

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      currentFilter: "active",
      pages: 1,
      page: 1,
      lL: false,
      lE: false,
      lM: "",
      lR: null
    };
  }

  componentDidMount = () => {
    let { currentFilter, page } = this.state;
    this.handleFetch(currentFilter, page);
  };

  handleFetch = (currentFilter, page) => {
    let { id } = this.props.userOrg;

    this.setState({
      currentFilter,
      page,
      lL: true,
      lE: false,
      lM: "",
      lR: null
    });

    GET(
      `save-ssa/organization/users/${id}/?filter_by=${currentFilter}&page=${page}`
    )
      .then(data => {
        this.setState({
          pages: data.meta.pages,
          lL: false,
          lE: false,
          lM: "",
          lR: null,
          users: _.orderBy(data.data, ["is_admin","first_login", "name"], ["asc", "asc"])
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: this.handleFetch });
      });
  };

  render() {
    return <UI {...this.props} {...this} {...this.state} />;
  }
}

export default List;
