import React from "react";
import { GET, POST } from "./../../../../../../utils/api";
import formErrors from "./../../../../../../utils/formErrors";
import phoneVerify from "./../../../../../../utils/phoneVerify";
import UI from "./UI";

class Invite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: [""],
      phone: [""],
      partner: [],
      partners: [],
      lL: false,
      lE: false,
      lM: "",
      lR: null,
      lS: false,
      lI: 0
    };
  }

  handleChange = (e, { name, value }) => {
    let n = name.split("-");
    let v = [...this.state[n[0]]];
    v[n[1] * 1] = value;
    this.setState({ [n[0]]: v, lE: false, lS: false });
  };

  addRow = () => {
    const { name, phone, partner } = this.state;
    this.setState({
      name: [...name, ""],
      phone: [...phone, ""],
      partner: [...partner, 0]
    });
  };

  removeRow = i => {
    let { name, phone, partner } = this.state;
    name.splice(i, 1);
    phone.splice(i, 1);
    partner.splice(i, 1);

    this.setState({ name, phone, partner });
  };

  componentDidMount = () => this.handleFetch();

  handleFetch = () => {
    let { project_id } = this.props;

    this.setState({ lL: true, lE: false, lM: "", lR: null, lI: 0 });

    GET(`v2/projects/${project_id}/partners`)
      .then(data => {
        let partners = [];

        data.partners.map(p => {
          if (p.partner.type === 7) {
            partners.push({
              text: p.partner.name,
              value: p.partner.id,
              key: p.partner.id
            });
          }

          return p;
        });

        this.setState({
          partners,
          lL: false,
          lE: false,
          lM: "",
          lR: null,
          lI: 1
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: null, lI: 0 });
      });
  };

  handleSave = (...e) => {
    if (formErrors(e).ok) {
      let data = [];
      const { name, phone, partner } = this.state;

      name.map((n, i) => {
        data.push({
          username: null,
          password: null,
          name: n,
          email: null,
          phone: phoneVerify(phone[i]).phone,
          type: null,
          birth_date: null,
          gender: null,
          education: null,
          id_number: null,
          location: null
        });
        return n;
      });

      const { project_id } = this.props;

      this.setState({ lL: true, lE: false, lM: "", lS: false });

      Promise.all([
        ...data.map((d, i) =>
          POST(`v2/organizations/${partner[i]}/project/${project_id}/inv/2`, d)
        )
      ])
        .then(data => {
          this.setState({
            name: [""],
            phone: [""],
            partner: [0],
            lL: false,
            lE: false,
            lM: `Admin${name.length > 1 && "s"} successfully invited!`,
            lS: true
          });
        })
        .catch(error => {
          this.setState({ lL: false, lE: true, lM: error, lS: false });
        });
    }
  };

  render() {
    return <UI {...this} {...this.props} {...this.state} />;
  }
}

export default Invite;
