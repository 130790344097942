import React from "react";
import { Icon, Grid, Button } from "semantic-ui-react";
import ContentLoader from "./../../../../../../../components/Loader";
import Box from "./../../../../../../../components/Box";
import phoneVerify from "./../../../../../../../utils/phoneVerify";
import { Link } from "react-router-dom";

var moment = require("moment");

const UI = ({ agent, org_id, agent_id, history, lL, lE, lM, lR }) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Wait a moment..."
    showContent={Object.keys(agent).includes("members_count")}
  >
    <div className="pannel-scroll">
      <div className="padding">
        <div className="header">
          {history && history.goBack && (
            <div className="back" onClick={() => history.goBack()}>
              <Icon name="arrow left" fitted size="large" />
            </div>
          )}
          <span className="title">Agent: {agent.user_details.name}</span>
          <Button
            size="small"
            as={Link}
            to={`/organizations/${org_id}/agents/${agent_id}/saving-groups`}
            content="View SGs"
            label={agent.sg_count}
          />
        </div>

        <Grid columns={4}>
          <Grid.Row stretched>
            <Grid.Column width={4}>
              <Box
                title="SAVING GROUPS"
                value={agent.sg_count}
                color="green"
                chart={{
                  data: agent.sg_enrollment_per_month,
                  key: "count"
                }}
                lineChart
              />
              <Box
                title="SAVINGS"
                value={agent.savings_amount}
                unit="RWF"
                color="orange"
                chart={{
                  data: agent.savings_vs_loans_distribution,
                  key: "savings_amount"
                }}
                lineChart
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Box
                title="MEMBERS ENROLLMENT"
                value={agent.members_count}
                color="blue"
                chart={{
                  data: agent.members_enrollment_by_month,
                  key: "count"
                }}
                lineChart
              />
              <Box
                title="OUTSTANDING LOANS"
                value={agent.outstanding_loans_amount}
                unit="RWF"
                color="red"
                chart={{
                  data: agent.savings_vs_loans_distribution,
                  key: "outstanding_loans_amount"
                }}
                lineChart
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Box
                name={agent.user_details.name}
                rows={[
                  [
                    {
                      label: "Phone Number",
                      value: phoneVerify(agent.user_details.phone).phone_
                    }
                  ],
                  [
                    {
                      label: "Address",
                      value: agent.user_details.location
                    }
                  ],
                  [
                    {
                      label: "Email Address",
                      value: agent.user_details.email
                    }
                  ],
                  [],
                  [
                    {
                      label: "Agent",
                      className: "labelBadge"
                    }
                  ],
                  [
                    {
                      label: `Joined on ${moment(
                        agent.user_details.date
                      ).format("MMM DD, YYYY")}`,
                      className: "labelFlat footer right"
                    }
                  ]
                ]}
                noDate
                noChart
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Box
                title="Male Members"
                value={agent.male_sg_members_count}
                chart={{
                  data: agent.enrollment_by_gender_per_month,
                  key: "male_count"
                }}
                barChart
              />
              <Box
                title="Female Members"
                value={agent.female_sg_members_count}
                chart={{
                  data: agent.enrollment_by_gender_per_month,
                  key: "female_count"
                }}
                barChart
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column width={8}>
              <Box
                title="Members Level Of Education"
                chart={{
                  data: [
                    ...agent.members_education_grouping.map(d => ({
                      name: d.education,
                      caption: "Members:",
                      count: d.count
                    }))
                  ],
                  key: "count"
                }}
                radialChart
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Box
                title="Member Enrolment Per Gender"
                chart={{
                  data: [
                    ...agent.enrollment_by_gender_per_month.map(d => ({
                      Male: d.male_count,
                      Female: d.female_count,
                      name: moment(d.month).format("MMM")
                    }))
                  ],
                  keys: ["Male", "Female"]
                }}
                doubleLineChart
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  </ContentLoader>
);

export default UI;
