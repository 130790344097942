import React from "react";

import UI from "./UI";

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overview: props.overview
    };
  }

  componentDidMount = () => this.handleData();

  handleData = () => {};

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default Overview;
