import React from "react";
import { Switch, Route } from "react-router-dom";
import List from "./List";
import Invite from "./Invite";
import Agent from "./Agent";

const UI = props => (
  <Switch>
    <Route
      exact
      path={`/projects/${props.project_id}/agents`}
      render={props_ => <List {...props} {...props_} />}
    />
    <Route
      path={`/projects/${props.project_id}/agents/invite`}
      render={props_ => <Invite {...props} {...props_} />}
    />
    <Route
      path={`/projects/${props.project_id}/agents/:agent_id`}
      render={props_ => (
        <Agent {...props} {...props_} agent_id={props_.match.params.agent_id} />
      )}
    />
  </Switch>
);

export default UI;
