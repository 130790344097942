import React from "react";

import UI from "./UI";

class Agents extends React.Component {
  state = {
    agentEnrollmentByGender: []
  }
  
  componentDidMount = () => {
    this.setState({
      agentEnrollmentByGender: this.getFormattedAgentEnrolmentByGender()
    });
  }
  
  getFormattedAgentEnrolmentByGender = () => {
      let { profile } = this.props;
      const c = [];
      const d = [];

      profile.male_agents_enrollment_per_month.forEach(function(element){
        c.push({
          month: element.month,
          male_count: element.count !== undefined ? element.count : 0,
          female_count: (profile.female_agents_enrollment_per_month.find(e=>e.month===element.month)) !== undefined ?
            (profile.female_agents_enrollment_per_month.find(e=>e.month===element.month)).count :
            0
        })
      });

      profile.female_agents_enrollment_per_month.forEach(function(element){
        d.push({
          month: element.month,
          male_count: (profile.male_agents_enrollment_per_month.find(e=>e.month===element.month)) !== undefined ?
            (profile.male_agents_enrollment_per_month.find(e=>e.month===element.month)).count :
            0,
          female_count: element.count !== undefined ? element.count : 0,
        })
      });

      const e = [...c, ...d];
      const result = [];

      const map = new Map();
      for (const item of e) {
          if(!map.has(item.month)){
              map.set(item.month, true);
              result.push({
                  month: item.month,
                  male_count: item.male_count,
                  female_count: item.female_count
              });
          }
      }

      return result;
    }
  
  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default Agents;
