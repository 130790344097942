import React from "react";
import MyList from "./../../../../components/MyList";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

var moment = require("moment");

class Projects extends React.Component {
  render() {
    let { projects } = this.props;
    return (
      <div className="pannel-scroll flex-1">
        <div className="padding">
          <div className="header">
            {this.props.history && this.props.history.goBack && (
              <div className="back" onClick={() => this.props.history.goBack()}>
                <Icon name="arrow left" fitted size="large" />
              </div>
            )}
            <span className="title">Federations ({projects.length})</span>
          </div>
        </div>
        <MyList
          items={projects}
          left="name"
          title="name"
          description={["created_by", "date"]}
          descriptionFn={d => [
            `Created on ${moment(d[1]).format("MMM DD, YYYY")} by ${d[0]}`
          ]}
          as={Link}
          link={["federations", "[(id)]"]}
        />
      </div>
    );
  }
}

export default Projects;
