import React from "react";

import ContentLoader from "../../../../../../../../components/Loader";
import DataTable from "../../../../../../../../components/DataTable";

const UI = ({ lL, lE, lM, lR, members, history }) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Fetching saving groups..."
    showContent={members.length !== 0}
  >
    <DataTable
      title="Members"
      history={history}
      dataKey="id"
      empty="No member."
      data={members}
      columns={[
        {
          dataField: "name",
          text: "Member",
          sort: true,
          formatter: "name",
          link: [
            "organizations",
            "[(org_id)]",
            "saving-groups",
            "[(sg_id)]",
            "members",
            "[(id)]"
          ]
        }
      ]}
    />
  </ContentLoader>
);

export default UI;
