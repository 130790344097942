import React from "react";
import Toolbar from "./../../../../components/Toolbar";
import { ssaToolbar, ssanccrToolbar } from "./../../../../utils/vars";
import { Switch, Route } from "react-router-dom";
import { GET } from "./../../../../utils/api";
import ContentLoader from "./../../../../components/Loader";

import Overview from "./Overview";
import Projects from "./Projects";
import Agents from "./Agents";
import SavingGroups from "./SavingGroups";
import Settings from "./Settings";

class Organization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: props.selectedOrganization,
      lL: false,
      lE: false,
      lM: "",
      lR: null
    };
  }

  componentDidMount = () => {
    if (Object.keys(this.state.organization).length === 0) this.fetchData();
  };

  fetchData = () => {
    let { main_id } = this.props.match.params;
    this.setState({ lL: true, lE: false });

    Promise.all([
      GET(`save-${global.platform}/organization/${main_id}`),
      GET(`save-${global.platform}/organization/mno_distribution/${main_id}`)
    ])
      .then(data => {
        this.setState({
          lL: false,
          lE: false,
          lM: "",
          lR: null,
          organization: { ...data[0], mno_distribution: data[1] }
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.fetchData
        });
      });
  };

  render() {
    let { main_id } = this.props.match.params;
    let { organization, lL, lE, lM, lR } = this.state;

    return (
      <ContentLoader
        lL={lL}
        lE={lE}
        lM={lM}
        lR={lR}
        message="Wait a moment..."
        showContent={Object.keys(organization).length !== 0}
      >
        <div className="pannels-column">
          <Toolbar
            prefix={`/organizations/${main_id}`}
            links={
              Object.keys(organization).length !== 0
                ? organization.organization.type === 4
                  ? ssanccrToolbar
                  : ssaToolbar
                : []
            }
          />
          <Switch>
            <Route
              exact
              path={`/organizations/${main_id}`}
              render={props => (
                <Overview
                  {...this.props}
                  organization={organization}
                  update={() => this.fetchData()}
                  {...props}
                  org_id={main_id}
                />
              )}
            />
            <Route
              path={`/organizations/${main_id}/projects`}
              render={props => (
                <Projects
                  {...this.props}
                  organization={organization}
                  update={() => this.fetchData()}
                  {...props}
                  org_id={main_id}
                />
              )}
            />
            <Route
              path={`/organizations/${main_id}/federations`}
              render={props => (
                <Projects
                  {...this.props}
                  organization={organization}
                  update={() => this.fetchData()}
                  {...props}
                  org_id={main_id}
                />
              )}
            />
            <Route
              path={`/organizations/${main_id}/agents`}
              render={props => (
                <Agents
                  {...this.props}
                  organization={organization}
                  update={() => this.fetchData()}
                  {...props}
                  org_id={main_id}
                />
              )}
            />
            <Route
              path={`/organizations/${main_id}/admins`}
              render={props => (
                <Agents
                  {...this.props}
                  organization={organization}
                  update={() => this.fetchData()}
                  {...props}
                  org_id={main_id}
                />
              )}
            />
            <Route
              path={`/organizations/${main_id}/saving-groups`}
              render={props => (
                <SavingGroups
                  {...this.props}
                  organization={organization}
                  update={() => this.fetchData()}
                  {...props}
                  id={main_id}
                />
              )}
            />
            <Route
              path={`/organizations/${main_id}/settings`}
              render={props => (
                <Settings
                  {...this.props}
                  organization={organization}
                  update={() => this.fetchData()}
                  {...props}
                  id={main_id}
                />
              )}
            />
          </Switch>
        </div>
      </ContentLoader>
    );
  }
}

export default Organization;
