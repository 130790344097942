import React from "react";
import { baseUrl, GET, POST } from "./../../../../../../utils/api";
import formErrors from "./../../../../../../utils/formErrors";
import phoneVerify from "./../../../../../../utils/phoneVerify";
import UI from "./UI";

class User extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      id_number: "",
      birth_date: "",
      gender: "",
      ocupation: "",
      martial_status: "",
      phone: "",
      education: "",
      email: "",
      cp_name: "",
      cp_relationship: "",
      cp_phone: "",
      province: "",
      district: "",
      sector: "",
      cell: "",
      village: "",
      provinces: [],
      districts: [],
      sectors: [],
      cells: [],
      villages: [],
      is_admin: null,
      work_email: "",
      position: "",
      lL: false,
      lE: false,
      lM: "",
      lR: null,
      lS: false,
      fL: false,
      fE: false,
      fM: "",
      fR: null,
      aL: false,
      aE: false,
      aM: "",
      aR: null,
      aI: null
    };
  }

  componentDidMount = () => this.handleFetch();

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, lE: false, lS: false }, () => {
      setTimeout(() => {
        if (this.areas.includes(name)) {
          let next = this.areas.indexOf(name) + 1;
          if (next < this.areas.length) {
            this.handleLocation(next);
          }
        }
      }, 500);
    });
  };

  me = () => {
    let { currentUser } = this.props;
    let { id } = this.props;

    let ok = false;

    let id_ = id;

    let str = "";

    if (currentUser.id === id * 1 || id === "me") {
      id_ = currentUser.id;
      str = " (me)";
      ok = true;
    }

    return { id: id_, text: str, ok };
  };

  handleFetch = () => {
    let { id } = this.me();

    this.setState({ fL: true, fE: false, fM: "", fR: null });

    GET(`save-ssa/user/${id}`)
      .then(data => {
        this.setState(
          {
            ...(({
              name = "",
              id_number = "",
              birth_date = "",
              gender = "",
              ocupation = "",
              martial_status = "",
              phone = "",
              education = "",
              email = "",
              contact_person = "",
              is_admin = null,
              position = "",
              work_email = ""
            }) => ({
              name,
              id_number,
              birth_date: birth_date.substring(0, 10),
              gender,
              ocupation,
              martial_status,
              phone,
              education,
              email,
              ...JSON.parse(
                contact_person.length > 0
                  ? contact_person
                  : '{"cp_name": "","cp_relationship": "","cp_phone": ""}'
              ),
              is_admin,
              position,
              work_email
            }))(data)
          },
          () => this.handleFetchAllLocations(data.location)
        );
      })
      .catch(error => {
        this.setState({
          fL: false,
          fE: true,
          fM: error,
          fR: this.handleFetch
        });
      });
  };

  handleFetchAllLocations = location => {
    this.setState({ fL: true, fE: false, fM: "", fR: null });

    GET(`${baseUrl}location/village/${location}`)
      .then(data => {
        let locs = (({ village, cell, sector, district, province }) => ({
          village: village.id,
          cell: cell.id,
          sector: sector.id,
          district: district.id,
          province: province.id
        }))(data);

        this.setState(
          {
            ...locs,
            fL: false,
            fE: false,
            fM: "",
            fR: null
          },
          () => this.handleFetchAllLocationsAll(data)
        );
      })
      .catch(error => {
        this.setState({
          fL: false,
          fE: true,
          fM: error,
          fR: () => this.handleFetchAllLocations(location)
        });
      });
  };

  handleFetchAllLocationsAll = location => {
    this.setState({ fL: true, fE: false, fM: "", fR: null });

    Promise.all([
      GET(`${baseUrl}location/provinces`, []),
      GET(`${baseUrl}location/provinces_districts/${location.province.id}`, []),
      GET(`${baseUrl}location/districts_sectors/${location.district.id}`, []),
      GET(`${baseUrl}location/sectors_cells/${location.sector.id}`, []),
      GET(`${baseUrl}location/cells_villages/${location.cell.id}`, [])
    ])
      .then(data => {
        let locs = {
          provinces: data[0].map(({ name, id }) => ({
            text: name,
            key: id,
            value: id
          })),
          districts: data[1].map(({ name, id }) => ({
            text: name,
            key: id,
            value: id
          })),
          sectors: data[2].map(({ name, id }) => ({
            text: name,
            key: id,
            value: id
          })),
          cells: data[3].map(({ name, id }) => ({
            text: name,
            key: id,
            value: id
          })),
          villages: data[4].map(({ name, id }) => ({
            text: name,
            key: id,
            value: id
          }))
        };

        this.setState({
          fL: false,
          fE: false,
          fM: "",
          fR: null,
          ...locs
        });
      })
      .catch(error => {
        this.setState({
          fL: false,
          fE: true,
          fM: error,
          fR: () => this.handleFetchAllLocationsAll(location)
        });
      });
  };

  areas = ["province", "district", "sector", "cell", "village"];

  handleLocation = index => {
    let add_ = this.state[this.areas[index - 1]];

    this.setState({
      aL: true,
      aE: false,
      aM: "",
      aR: null,
      aI: index
    });

    let location = `${baseUrl}location/${this.areas[index - 1]}/${add_}`;

    GET(location)
      .then(data => {
        let items = [];
        data[`${this.areas[index]}s`].map(item => {
          items.push({
            label: item.name,
            value: item.id,
            key: item.id
          });
          return item;
        });

        let obj = {};

        obj[`${this.areas[index]}`] = "";
        obj[`${this.areas[index]}s`] = items;

        for (let i = index + 1; i < this.areas.length; i++) {
          obj[`${this.areas[i]}`] = "";
          obj[`${this.areas[i]}s`] = [];
        }

        this.setState({
          aL: false,
          aE: false,
          aM: "",
          aR: null,
          aI: null,
          ...obj
        });
      })
      .catch(error => {
        this.setState({
          aL: false,
          aE: true,
          aM: `Failed to get ${this.areas[index]}s.`,
          aR: () => this.handleLocation(index),
          aI: index
        });
      });
  };

  handleSave = (...e) => {
    if (formErrors(e).ok) {
      let data_ = (({
        name,
        id_number,
        birth_date,
        gender,
        ocupation,
        martial_status,
        phone,
        education,
        email,
        cp_name,
        cp_relationship,
        cp_phone,
        village,
        is_admin,
        work_email,
        position
      }) => ({
        name,
        id_number,
        birth_date,
        gender,
        ocupation,
        martial_status,
        phone: phoneVerify(phone).phone,
        education,
        email,
        contact_person: JSON.stringify({
          cp_name,
          cp_relationship,
          cp_phone: phoneVerify(cp_phone).phone
        }),
        location: village,
        is_admin,
        work_email,
        position
      }))(this.state);

      this.setState({ lL: true, lE: false, lM: "", lS: false });

      // let { id } = this.props;
      let { id } = this.me();

      POST(`save-ssa/user/update/${id}`, data_)
        .then(data => {
          this.setState({ lL: false, lE: false, lM: "", lS: true });
        })
        .catch(error => {
          this.setState({ lL: false, lE: true, lM: error, lS: false });
        });
    }
  };

  render() {
    return <UI {...this} {...this.props} {...this.state} />;
  }
}

export default User;
