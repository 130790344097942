import React from "react";

import { GET } from "../../../../../../../utils/api";

import UI from "./UI";

class List extends React.Component {
  state = {
    sgs: [],
    lL: false,
    lE: false,
    lM: "",
    lR: null
  };
  componentDidMount = () => this.handleFetch();

  handleFetch = () => {
    let { agent_id } = this.props;

    this.setState({ lL: true, lE: false, lM: "", lR: null });

    GET(`save-${global.platform}/agent/sgs/${agent_id}`)
      .then(data => {
        this.setState({
          sgs: [...data.map(sg => ({ ...sg, ...sg.saving_group }))],
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: null });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default List;
