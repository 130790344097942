import React from "react";

import { Grid, Icon } from "semantic-ui-react";

import Box from "./../../../../../../../components/Box";
import { organizationTypes } from "./../../../../../../../utils/vars";
import phoneVerify from "./../../../../../../../utils/phoneVerify";
var moment = require("moment");

const UI = ({ org, history }) => (
  <div className="pannel-scroll">
    <div className="padding">
      <div className="header">
        {history && history.goBack && (
          <div className="back" onClick={() => history.goBack()}>
            <Icon name="arrow left" fitted size="large" />
          </div>
        )}
        <span className="title">Partner: {org.organization.name}</span>
      </div>

      <Grid columns={4}>
        <Grid.Row stretched>
          <Grid.Column width={4}>
            <Box
              title="SAVING GROUPS"
              value={org.savings_group_count}
              color="green"
              chart={{ data: org.sg_enrollment_per_month, key: "count" }}
              lineChart
            />
            <Box
              title="SAVINGS"
              value={org.total_savings}
              unit="RWF"
              color="orange"
              chart={{
                data: org.savings_vs_loans_distribution,
                key: "savings_amount"
              }}
              lineChart
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Box
              title="AGENTS"
              value={org.agents_count}
              color="blue"
              chart={{ data: org.agent_enrollment_per_month, key: "count" }}
              lineChart
            />
            <Box
              title="OUTSTANDING LOANS"
              value={org.outstanding_loans}
              unit="RWF"
              color="red"
              chart={{
                data: org.savings_vs_loans_distribution,
                key: "outstanding_loans_amount"
              }}
              lineChart
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Box
              name={org.organization.name}
              rows={[
                [
                  {
                    label: "Total Projects",
                    value: org.org_projects_count,
                    link: `/organizations/${org.organization.id}/projects`
                  }
                ],
                [
                  {
                    label: "Partners",
                    labelBadge: org.organization_partners.partners_count,
                    value: org.organization_partners.partner_names
                      .map(({ name }) => name)
                      .join(", "),
                    link: `/organizations/${org.organization.id}/partners`
                  }
                ],
                [
                  {
                    label: "Address",
                    value: `${org.organization.address}, ${
                      org.organization.country
                    }`
                  }
                ],
                [
                  {
                    label: organizationTypes[org.organization.type - 1].text,
                    className: "labelBadge"
                  },
                  {
                    label: org.organization.email,
                    value: phoneVerify(org.organization.phone).phone_,
                    className: "labelFlat"
                  }
                ],
                [],
                [
                  {
                    label: `Joined on ${moment(org.organization.date).format(
                      "MMM DD, YYYY"
                    )}`,
                    className: "labelFlat footer right"
                  }
                ]
              ]}
              noDate
              noChart
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Box
              title="Male members count"
              value={org.male_members_count}
              icon="male"
              chart={{ data: org.male_members_distribution, key: "count" }}
              barChart
            />
            <Box
              title="Female members count"
              value={org.female_members_count}
              icon="female"
              chart={{ data: org.female_members_distribution, key: "count" }}
              barChart
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched>
          <Grid.Column width={8}>
            <Box
              title="SG Distribution Per Agent"
              chart={{
                data: [
                  ...org.sg_distribution_per_agent.map(d => ({
                    name: d.agent_name,
                    caption: "SGs:",
                    count: d.sg_count
                  }))
                ],
                key: "count"
              }}
              radialChart
            />
            <Box
              title="SG Enrollment Per Gender"
              chart={{
                data: [
                  ...org.sg_enrollment_by_gender_per_month.map(d => ({
                    Male: d.male_count,
                    Female: d.female_count,
                    name: moment(d.month).format("MMM")
                  }))
                ],
                keys: ["Male", "Female"]
              }}
              doubleLineChart
            />
            <Box
              title="Members Age Range"
              chart={{
                data: [
                  {
                    name: "18-24",
                    caption: "Members:",
                    count: org.member_age_groups.from1824
                  },
                  {
                    name: "25-29",
                    caption: "Members:",
                    count: org.member_age_groups.from2529
                  },
                  {
                    name: "30-34",
                    caption: "Members:",
                    count: org.member_age_groups.from3034
                  },
                  {
                    name: "35-39",
                    caption: "Members:",
                    count: org.member_age_groups.from3539
                  },
                  {
                    name: "40-49",
                    caption: "Members:",
                    count: org.member_age_groups.from4049
                  },
                  {
                    name: "50+",
                    caption: "Members:",
                    count: org.member_age_groups.above50
                  }
                ],
                key: "count"
              }}
              radialBarChart
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Box
              title="MNO Distribution Per Province"
              chart={{
                data: [
                  ...org.mno_distribution.map(d => ({
                    name: d.province_name,
                    MTN: d.mtn_count,
                    TIGO: d.tigo_count
                  }))
                ],
                keys: ["MTN", "TIGO"]
              }}
              doubleBarChart
            />
            <Box
              title="Agent Level Of Education"
              chart={{
                data: [
                  ...org.agent_education_grouping.map(d => ({
                    name: d.education,
                    caption: "Agents:",
                    count: d.count
                  }))
                ],
                key: "count"
              }}
              radialChart
            />
            <Box
              title="Savings VS Loans"
              chart={{
                data: [
                  ...org.savings_vs_loans_distribution.map(d => ({
                    Savings: d.savings_amount,
                    Loans: d.loans_amount,
                    name: moment(d.month).format("MMM")
                  }))
                ],
                keys: ["Savings", "Loans"]
              }}
              doubleBarChart
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  </div>
);

export default UI;
