import React from "react";

import { GET } from "./../../../../../../utils/api";

import UI from "./UI";

class Pertner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      org: {
        organization: {
          type: 3
        },
        sg_distribution_per_agent: [],
        sg_enrollment_by_gender_per_month: [],
        member_age_groups: {},
        mno_distribution: [],
        agent_education_grouping: [],
        savings_vs_loans_distribution: [],
        organization_partners: { partner_names: [] },
        organization_contact_person: {
          gender: 0
        }
      },
      lL: false,
      lE: false,
      lM: "",
      lR: null
    };
  }

  componentDidMount = () => this.fetchData();

  fetchData = () => {
    let { partner_id } = this.props.match.params;
    this.setState({ lL: true, lE: false });

    Promise.all([
      GET(`save-ssa/organization/${partner_id}`),
      GET(`save-ssa/organization/mno_distribution/${partner_id}`)
    ])
      .then(data => {
        this.setState({
          lL: false,
          lE: false,
          lM: "",
          lR: null,
          org: { ...data[0], mno_distribution: data[1] }
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lR: this.fetchData
        });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default Pertner;
