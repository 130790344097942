import React from "react";

import { Link } from "react-router-dom";

import { Button } from "semantic-ui-react";

import ContentLoader from "./../../../../../../components/Loader";
import DataTable from "./../../../../../../components/DataTable";

const UI = ({ lL, lE, lM, lR, partners, project_id, history }) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Fetching unions..."
    showContentMessage="You have no union!"
    showContentAction={{
      link: `/federations/${project_id}/unions/new`,
      text: "Add Union"
    }}
    showContent={partners.length !== 0}
  >
    <DataTable
      title="Unions"
      history={history}
      more={
        <Button as={Link} to={`/federations/${project_id}/unions/new`} primary>
          Add Union
        </Button>
      }
      dataKey="id"
      empty="No union."
      data={partners}
      columns={[
        {
          dataField: "name",
          text: "Union",
          sort: true,
          formatter: "name",
          link: ["federations", "[(project_id)]", "unions", "[(id)]"]
        },
        {
          dataField: "cooperatives_count",
          text: "Cooperatives",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "admins_count",
          text: "Admins",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "sg_count",
          text: "Saving Groups",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "member_count",
          text: "Number Of Members",
          sort: true,
          formatter: "number"
        }
      ]}
    />
  </ContentLoader>
);

export default UI;
