import React from "react";
import { Form, Input, Header, Message, Icon, Divider, Label } from "semantic-ui-react";
import validator from "validator";
import { DateInput } from "semantic-ui-calendar-react";

import MySelect from "./../../../../../components/MySelect";

const UI = ({
  lL,
  lS,
  lE,
  lM,
  aL,
  aE,
  aM,
  aR,
  aI,
  history,
  name,
  end,
  start,
  budget,
  donor,
  province,
  district,
  sector,
  cell,
  village,
  provinces,
  districts,
  sectors,
  cells,
  villages,
  handleSave,
  handleChange,
  isEndDateInvalid
}) => (
  <div className="pannel-scroll">
    <div className="padding">
      <div className="header">
        {history && history.goBack && (
          <div className="back" onClick={() => history.goBack()}>
            <Icon name="arrow left" fitted size="large" />
          </div>
        )}
        <span className="title">Project Settings</span>
      </div>
      <div className="pannels-row">
        <div className="flex-1" />
        <Form loading={lL} success={lS} error={lE} onSubmit={handleSave}>
          <Header>Project Information</Header>
          <Form.Field
            id="form-input-name"
            name="name"
            value={name}
            control={Input}
            label="Name"
            placeholder="Name..."
            error={validator.isEmpty(name)}
            onChange={handleChange}
          />
          <Form.Group widths="equal">
            <DateInput
              id="form-input-start"
              name="start"
              value={start}
              label="Start"
              error={validator.isEmpty(start)}
              placeholder="Start..."
              iconPosition="left"
              dateFormat="YYYY-MM-DD"
              closeOnMouseLeave={false}
              closable={true}
              onChange={handleChange}
            />
            <DateInput
              id="form-input-end"
              name="end"
              value={end}
              fluid
              label="End"
              error={validator.isEmpty(end)}
              placeholder="End..."
              dateFormat="YYYY-MM-DD"
              closeOnMouseLeave={false}
              closable={true}
              onChange={handleChange}
            />
          </Form.Group>
            {isEndDateInvalid?(
            <Label pointing="above" color="red" basic>Project End date should be greater than the start date</Label>
          ):null}
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-budget"
              className="rwf"
              name="budget"
              value={budget}
              control={Input}
              label="Budget"
              placeholder="Budget..."
              error={!validator.isInt(`${budget}`)}
              onChange={handleChange}
            />
            <Form.Field
              id="form-input-donor"
              name="donor"
              value={donor}
              control={Input}
              label="Donor"
              placeholder="Donor..."
              error={validator.isEmpty(donor)}
              onChange={handleChange}
            />
          </Form.Group>
          <Header>Intervention Area</Header>
          <Form.Group widths="equal">
            <Form.Field
              name="province"
              value={province}
              control={MySelect}
              fluid
              options={provinces}
              label={{
                children: "Provinces",
                htmlFor: "form-input-province"
              }}
              placeholder="Provinces..."
              error={province.length === 0 || (aE && aI === 0)}
              onChange={handleChange}
              disabled={aL}
              loading={aL && aI === 0}
            />
            <Form.Field
              name="district"
              value={district}
              control={MySelect}
              fluid
              options={districts}
              label={{
                children: "Districts",
                htmlFor: "form-input-district"
              }}
              placeholder="Districts"
              error={district.length === 0 || (aE && aI === 1)}
              onChange={handleChange}
              disabled={aL}
              loading={aL && aI === 1}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              name="sector"
              value={sector}
              control={MySelect}
              fluid
              options={sectors}
              label={{
                children: "Sectors",
                htmlFor: "form-input-sector"
              }}
              placeholder="Sectors..."
              error={sector.length === 0 || (aE && aI === 2)}
              onChange={handleChange}
              disabled={aL}
              loading={aL && aI === 2}
            />
            <Form.Field
              name="cell"
              value={cell}
              control={MySelect}
              fluid
              options={cells}
              label={{
                children: "Cells",
                htmlFor: "form-input-cell"
              }}
              placeholder="Cells..."
              error={cell.length === 0 || (aE && aI === 3)}
              onChange={handleChange}
              disabled={aL}
              loading={aL && aI === 3}
            />
          </Form.Group>
          <Form.Field
            name="village"
            value={village}
            control={MySelect}
            fluid
            options={villages}
            label={{
              children: "Villages",
              htmlFor: "form-input-village"
            }}
            placeholder="Villages..."
            error={village.length === 0 || (aE && aI === 4)}
            onChange={handleChange}
            disabled={aL}
            loading={aL && aI === 4}
          />
          {aE && (
            <Message error visible={aE}>
              <div className="areaError">
                <span>{aM}</span>
                <span onClick={() => aR()}>Retry...</span>
              </div>
            </Message>
          )}
          <Message error content={lM} />
          <Message success content="Project details saved!" />
          <Divider />
          <Form.Button primary floated="right">
            Save
          </Form.Button>
        </Form>
        <div className="flex-1" />
      </div>
    </div>
  </div>
);

export default UI;
