import React from "react";

import { Form, Card, Header, Message } from "semantic-ui-react";
import validator from "validator";

const UI = ({
  lL,
  lE,
  lM,
  lS,
  new_password,
  new_password_repeat,
  handleChange,
  handleSubmit
}) => (
  <Card.Content>
    <Card.Header textAlign="center" as={Header} size="huge">
      Set Password
    </Card.Header>
    <Form loading={lL} error={lE} success={lS} onSubmit={handleSubmit}>
      <Form.Input
        fluid
        name="new_password"
        placeholder="New Password..."
        type="password"
        iconPosition="left"
        icon="lock"
        value={new_password}
        onChange={handleChange}
        error={validator.isEmpty(new_password)}
        label="New Password"
      />
      <Form.Input
        fluid
        name="new_password_repeat"
        placeholder="Repeat New Password..."
        type="password"
        iconPosition="left"
        icon="lock"
        value={new_password_repeat}
        onChange={handleChange}
        error={validator.isEmpty(new_password_repeat)}
        label="Repeat New Password"
      />
      {new_password !== new_password_repeat && (
        <Message
          error
          visible={
            !validator.isEmpty(new_password) &&
            !validator.isEmpty(new_password_repeat)
          }
          content="New password and new password repeat don't match."
        />
      )}
      <Message error header="Failed" content={lM} />
      <Message
        success
        header="Success"
        content={`Password successfully updated!`}
      />
      <Form.Button
        primary
        floated="right"
        content="Submit"
        labelPosition="right"
        icon="check"
        disabled={
          validator.isEmpty(new_password) ||
          validator.isEmpty(new_password_repeat) ||
          new_password !== new_password_repeat
        }
      />
    </Form>
  </Card.Content>
);

export default UI;
