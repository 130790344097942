import React from "react";
import numberFormat from "./numberFormat";
import { Link } from "react-router-dom";
var moment = require("moment");

let types = [
  "organization",
  "",
  "user",
  "",
  "profile",
  "cooperative",
  "union",
  "federation"
];

let update = (a, b, c, d, e) => (
  <span>
    <span className="name">
      <Link className="name" to={`/users/${a.id}`}>
        {a.name || a.username}
      </Link>
    </span>{" "}
    updated {types[c]}'s information{" "}
    <span className="name">

      {c === 0 && (
        <Link className="name" to={`/organizations/${b.id}`}>
          {b.name}
        </Link>
      )}

      {c === 2 && (
        <Link className="name" to={`/users/${b.id}`}>
          {b.name || b.username}
        </Link>
      )}

      {c === 4 && (
        <Link className="name" to={`/profiles/${b.id}`}>
          {b.name}
        </Link>
      )}

      {c === 5 && (
        <Link className="name" to={`/cooperatives/${b.id}`}>
          {b.name}
        </Link>
      )}

      {c === 6 && (
        <Link className="name" to={`/federations/${b.id}`}>
          {b.name}
        </Link>
      )}

      {c === 7 && (
        <Link className="name" to={`/unions/${b.id}`}>
          {b.name}
        </Link>
      )}
    </span>
    {d !== null && (
      <span className="time">, {moment(d).format("MMMM DD, YYYY")}</span>
    )}
    .
  </span>
);

let create = (a, b, c, d) => (
  <span>
    <span className="name">
      <Link className="name" to={`/users/${a.id}`}>
        {a.name || a.username}
      </Link>
    </span>{" "}
    created {types[c]}{" "}
    <span className="name">
      {/* <Link className="name" to={`/users/${b.id}`}>
        {b.name || b.username}
      </Link> */}
      {c === 0 && (
        <Link className="name" to={`/organizations/${b.id}`}>
          {b.name}
        </Link>
      )}

      {c === 2 && (
        <Link className="name" to={`/users/${b.id}`}>
          {b.name || b.username}
        </Link>
      )}

      {c === 4 && (
        <Link className="name" to={`/profiles/${b.id}`}>
          {b.name}
        </Link>
      )}

      {c === 5 && (
        <Link className="name" to={`/cooperatives/${b.id}`}>
          {b.name}
        </Link>
      )}

      {c === 6 && (
        <Link className="name" to={`/federations/${b.id}`}>
          {b.name}
        </Link>
      )}

      {c === 7 && (
        <Link className="name" to={`/unions/${b.id}`}>
          {b.name}
        </Link>
      )}
    </span>
    {d !== null && (
      <span className="time">, {moment(d).format("MMMM DD, YYYY")}</span>
    )}
    .
  </span>
);

let wallet = (a, b, c, d) => (
  <span>
    <span className="name">{a.name || a.username}</span>{" "}
    {["did a top-up of", "transfered"][c]} {numberFormat(b.amount, "", "RWF")}
    {" to "}
    {c === 0 ? (
      "wallet"
    ) : (
      <span className="name">{b.sg_name || b.name || b.agent_name}</span>
    )}
    .
  </span>
);

const activityMessages = (
  actioned_by,
  activity,
  resource_details,
  a_time = null,
  fsp = null
) => {
  let type = activity.activity_type;

  let message = null;

  switch (type) {
    case "ORGANIZATION_UPDATE":
      message = update(actioned_by, resource_details, 0, a_time);
      break;
    case "ORGANIZATION_CREATION":
      message = create(actioned_by, resource_details, 0, a_time, fsp);
      break;
    case "USER_UPDATE":
    case "USER_PASSWORD_RESET":
      message = update(actioned_by, resource_details, 2, a_time);
      break;
    case "USER_INVITATION":
    case "USER_CREATION":
      message = create(actioned_by, resource_details, 2, a_time, fsp);
      break;
    case "PROFILE_CREATION":
      message = create(actioned_by, resource_details, 4, a_time, fsp);
      break;
    case "PROFILE_UPDATE":
      message = update(actioned_by, resource_details, 4, a_time, fsp);
      break;
    case "ACCOUNT_TRANSFER_ORG_TO_SG":
    case "ACC_TRANSFER_ORG_TO_SG":
      message = wallet(actioned_by, resource_details, 1, a_time);
      break;
    case "ORGANIZATION_CASH_IN":
      message = wallet(actioned_by, resource_details, 0, a_time);
      break;
    case "COOPERATIVE_CREATION":
      message = create(actioned_by, resource_details, 5, a_time);
      break;

    case "COOPERATIVE_UPDATE":
      message = update(actioned_by, resource_details, 5, a_time);
      break;
    case "UNION_CREATION":
      message = create(actioned_by, resource_details, 6, a_time);
      break;

    case "UNION_UPDATE":
      message = update(actioned_by, resource_details, 6, a_time);
      break;
    case "FEDERATION_CREATION":
      message = create(actioned_by, resource_details, 7, a_time);
      break;

    case "FEDERATION_UPDATE":
      message = update(actioned_by, resource_details, 7, a_time);
      break;
    default:
      message = <span>???</span>;
  }

  return message;
};

export default activityMessages;
