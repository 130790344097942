const formErrors = (e = [null, { children: [] }]) => {
  let result = { ok: true, errors: [] };
  e[1].children.map(child => {
    if (child) {
      if (
        child.props &&
        (typeof child.props.children === "undefined" ||
          child.props.children.length === 0)
      ) {
        result.errors.push(
          ["FormField", "DateInput", "MySelect"].includes(child.type.name)
            ? child.props.error
            : false
        );
      } else {
        try {
          child.props.children.map(child_ => {
            try {
              result.errors.push(
                ["FormField", "DateInput", "MySelect"].includes(
                  child_.type.name
                )
                  ? child_.props.error
                  : false
              );
            } catch (exc) {}
            return child_;
          });
        } catch (exc) {}
      }
    }

    return child;
  });

  result.ok = !result.errors.includes(true);

  return result;
};

export default formErrors;
