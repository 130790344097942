import React from "react";
import { GET } from "./../../../../../../utils/api";

import UI from "./UI";

class ConfirmAction extends React.Component {
  state = {
    user: {},
    lL: false,
    lE: false,
    lM: "",
    lS: false,
    lR: null
  };

  componentDidMount = () => this.handleFetch();

  handleFetch = () => {
    let { id } = this.props;

    this.setState({ lL: true, lE: false, lM: "", lS: false, lR: null });

    GET(`save-ssa/user/${id}`)
      .then(data => {
        this.setState({
          user: data,
          lL: false,
          lE: false,
          lM: "",
          lS: false,
          lR: null
        });
      })
      .catch(error => {
        this.setState({
          lL: false,
          lE: true,
          lM: error,
          lS: false,
          lR: this.handleFetch
        });
      });
  };

  handleAction = () => {
    let { user } = this.state;
    let { id, email } = user;
    let { action } = this.props;

    let massages = {
      "reset-password": "Reset password email successfully sent!",
      deactivate: "User deactivated!"
    };

    let actions = {
      "reset-password": GET(`save-ssa/forgot_pwd/${email}`),
      deactivate: GET(`save-ssa/user/${id}/deactivate`)
    };

    this.setState({ lL: true, lE: false, lM: "", lS: false, lR: null });
    actions[action]
      .then(data => {
        this.setState({
          lL: false,
          lE: false,
          lM: massages[action],
          lS: true,
          lR: null
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lS: false, lR: null });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default ConfirmAction;
