import React from "react";

import { Grid, Icon } from "semantic-ui-react";

import Box from "./../../../../../components/Box";
var moment = require("moment");

const UI = ({ overview, history }) => (
  <div className="pannel-scroll">
    <div className="padding">
      <div className="header">
        {history && history.goBack && (
          <div className="back" onClick={() => history.goBack()}>
            <Icon name="arrow left" fitted size="large" />
          </div>
        )}
        <span className="title">Overview</span>
      </div>
      <Grid columns={3}>
        <Grid.Row stretched>
          <Grid.Column>
            <Box
              // title="FSPs"
              title="Organizations"
              // value={overview.fsps_count}
              value={overview.orgs_count}
              color="green"
              // chart={{ data: [], key: "count" }}
              chart={{ data: overview.org_enrollment_per_month, key: "count" }}
              lineChart
            />
            <Box
              title="NGOs"
              value={overview.ngos_count}
              color="orange"
              // chart={{
              //   data: [],
              //   key: "count"
              // }}
              chart={{ data: overview.ngo_enrollment_per_month, key: "count" }}
              lineChart
            />
          </Grid.Column>
          <Grid.Column>
            <Box
              title="AGENTS"
              value={overview.agents_count}
              color="blue"
              chart={{
                data: overview.agent_enrollment_per_month,
                key: "count"
              }}
              lineChart
            />
            <Box
              title="SAVING GROUPS"
              value={overview.savings_groups_count}
              color="red"
              chart={{
                data: overview.sg_enrollment_per_month,
                key: "count"
              }}
              lineChart
            />
          </Grid.Column>
          <Grid.Column>
            <Box
              title="Male members count"
              value={overview.male_members_count}
              icon="male"
              chart={{
                data: overview.sg_enrollment_by_gender_per_month,
                key: "male_count"
              }}
              barChart
            />
            <Box
              title="Female members count"
              value={overview.female_members_count}
              icon="female"
              chart={{
                data: overview.sg_enrollment_by_gender_per_month,
                key: "female_count"
              }}
              barChart
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid columns={2}>
        <Grid.Row stretched>
          <Grid.Column width={8}>
            <Box
              title="Savings VS Loans"
              chart={{
                data: [
                  ...overview.savings_vs_loans_distribution.map(d => ({
                    Savings: d.savings_amount,
                    Loans: d.loans_amount,
                    name: moment(d.month).format("MMM")
                  }))
                ],
                keys: ["Savings", "Loans"]
              }}
              doubleBarChart
            />
            <Box
              title="Members Age Range"
              chart={{
                data: [
                  {
                    name: "18-24",
                    caption: "Members:",
                    count: overview.member_age_groups.from1824
                  },
                  {
                    name: "25-29",
                    caption: "Members:",
                    count: overview.member_age_groups.from2529
                  },
                  {
                    name: "30-34",
                    caption: "Members:",
                    count: overview.member_age_groups.from3034
                  },
                  {
                    name: "35-39",
                    caption: "Members:",
                    count: overview.member_age_groups.from3539
                  },
                  {
                    name: "40-49",
                    caption: "Members:",
                    count: overview.member_age_groups.from4049
                  },
                  {
                    name: "50+",
                    caption: "Members:",
                    count: overview.member_age_groups.above50
                  }
                ],
                key: "count"
              }}
              radialBarChart
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Box
              title="Cumulative VS Outstanding Loans"
              chart={{
                data: [
                  ...overview.cumulative_vs_outstanding_loans.map(d => ({
                    Cumulative: d.loans_amount,
                    Outstanding: d.outstanding_loans_amount,
                    name: moment(d.month).format("MMM")
                  }))
                ],
                keys: ["Cumulative", "Outstanding"]
              }}
              doubleBarChart
            />
            <Box
              title="Revenue VS VAs Commission"
              chart={{
                data: [
                  ...overview.revenues_vs_comissions_distribution.map(d => ({
                    Revenue: d.revenue,
                    Commission: d.commissions,
                    name: moment(d.month).format("MMM")
                  }))
                ],
                keys: ["Revenue", "Commission"]
              }}
              doubleBarChart
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  </div>
);

export default UI;
