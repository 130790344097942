import React from "react";

import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import ContentLoader from "./../../../../../../components/Loader";
import DataTable from "./../../../../../../components/DataTable";

const UI = ({ lL, lE, lM, lR, agents, project_id, history }) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Fetching agents..."
    showContent={agents.length !== 0}
    showContentAction={{
      link: `/projects/${project_id}/agents/invite`,
      text: "Invite Agents"
    }}
  >
    <DataTable
      title="Agents"
      history={history}
      dataKey="agent_id"
      empty="No agent."
      more={
        <Button
          as={Link}
          to={`/projects/${project_id}/agents/invite`}
          primary
          content="Invite Agents"
          labelPosition="right"
          icon="add"
        />
      }
      data={agents}
      columns={[
        {
          dataField: "agent_name",
          text: "Agent",
          sort: true,
          formatter: "name",
          link: ["projects", "[(project_id)]", "agents", "[(agent_id)]"]
        },
        {
          dataField: "savings_group_count",
          text: "Saving Groups",
          sort: true,
          formatter: "number",
          link: [
            "projects",
            "[(project_id)]",
            "agents",
            "[(agent_id)]",
            "saving-groups"
          ]
        },
        {
          dataField: "partner",
          text: "Partner",
          sort: true,
          formatter: "_",
          link: ["projects", "[(project_id)]", "partners", "[(partner_id)]"]
        }
      ]}
    />
  </ContentLoader>
);

export default UI;
