import React from "react";

import ContentLoader from "./../../../../../../../../components/Loader";
import DataTable from "./../../../../../../../../components/DataTable";

const UI = ({ lL, lE, lM, lR, members, history }) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Fetching members..."
    showContent={members.length !== 0}
  >
    <DataTable
      title="Members"
      history={history}
      dataKey="member_id"
      empty="No member."
      data={members}
      columns={[
        {
          dataField: "member_name",
          text: "Member",
          sort: true,
          formatter: "name",
          link: [
            "organizations",
            "[(org_id)]",
            "saving-groups",
            "[(sg_id)]",
            "members",
            "[(member_id)]"
          ]
        },
        {
          dataField: "shares",
          text: "Shares",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "total_savings",
          text: "Total Savings",
          textPrefix: "(RWF)",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "total_loans",
          text: "Total Loans",
          textPrefix: "(RWF)",
          sort: true,
          formatter: "number"
        },
        {
          dataField: "social_fund",
          text: "Social Fund",
          textPrefix: "(RWF)",
          sort: true,
          formatter: "number"
        }
      ]}
    />
  </ContentLoader>
);

export default UI;
