import React from "react";

import { GET } from "./../../../utils/api";

import UI from "./UI";

class ResetPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lL: false,
      lE: false,
      lM: "",
      lS: false,
      email: "",
      prev: ""
    };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    let { email } = this.state;

    this.setState({ lL: true, lE: false, lM: "", lS: false });

    GET(`save-ssa/forgot_pwd/${email}`)
      .then(data => {
        this.setState({
          lL: false,
          lE: false,
          lM: "",
          lS: true,
          prev: email,
          email: ""
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lS: false });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default ResetPassword;
