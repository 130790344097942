import React from "react";
import { Form, Input, Header, Message, Icon, Divider } from "semantic-ui-react";
import validator from "validator";
import { types } from "./../../../../../utils/vars";

import MySelect from "./../../../../../components/MySelect";

const UI = ({
  lM,
  aL,
  aE,
  aM,
  aR,
  aI,
  history,
  name,
  type,
  min_monthly_savings,
  max_monthly_savings,
  min_monthly_loans,
  max_monthly_loans,
  min_age_range,
  max_age_range,
  male_gender_count,
  female_gender_count,
  province,
  district,
  sector,
  cell,
  village,
  provinces,
  districts,
  sectors,
  cells,
  villages,
  handleChange,
}) => (
  <div className="pannel-scroll">
    <div className="padding">
      <div className="header">
        {history && history.goBack && (
          <div className="back" onClick={() => history.goBack()}>
            <Icon name="arrow left" fitted size="large" />
          </div>
        )}
        <span className="title">Profile Settings</span>
      </div>
      <div className="pannels-row">
       <Form style={{ marginLeft: "50px", maxWidth: "500px", width: "500px" }}>
          <Header>Groups details</Header>
          <Divider />

          <Header className="header-mod">Name</Header>
          <Form.Field
            id="form-input-name"
            name="name"
            value={name}
            control={Input}
            placeholder="Name..."
            error={validator.isEmpty(name)}
            onChange={handleChange}
          />

          <Header className="header-mod">Monthly Savings</Header>
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-min_monthly_savings"
              className="rwf"
              name="min_monthly_savings"
              value={min_monthly_savings}
              control={Input}
              placeholder="Min..."
              error={!validator.isFloat(`${min_monthly_savings}`)}
              onChange={handleChange}
            />
            _
            <Form.Field
              id="form-input-max_monthly_savings"
              name="max_monthly_savings"
              value={max_monthly_savings}
              control={Input}
              placeholder="Max..."
              error={!validator.isFloat(max_monthly_savings)}
              onChange={handleChange}
            />
          </Form.Group>

          <Header className="header-mod">Monthly Loans</Header>
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-min_monthly_loans"
              className="rwf"
              name="min_monthly_loans"
              value={min_monthly_loans}
              control={Input}
              placeholder="Min..."
              error={!validator.isFloat(min_monthly_loans)}
              onChange={handleChange}
            />
            _
            <Form.Field
              id="form-input-max_monthly_loans"
              name="max_monthly_loans"
              value={max_monthly_loans}
              control={Input}
              placeholder="Max..."
              error={!validator.isFloat(max_monthly_loans)}
              onChange={handleChange}
            />
          </Form.Group>

          <Header className="header-mod">Type</Header>
          <Form.Field
            name="type"
            value={type}
            control={MySelect}
            fluid
            options={types}
            placeholder="Type..."
            error={type.length === 0 || (aE && aI === 3)}
            onChange={handleChange}
            disabled={aL}
            loading={aL && aI === 3}
          />

          <Header className="header-mod">
            Age Range &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; Male
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; Female
          </Header>
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-min_age_range"
              name="min_age_range"
              value={min_age_range}
              control={Input}
              placeholder="..."
              error={!validator.isInt(`${min_age_range}`)}
              onChange={handleChange}
              width="1"
            />
            _
            <Form.Field
              id="form-input-max_age_range"
              name="max_age_range"
              value={max_age_range}
              control={Input}
              placeholder="..."
              error={!validator.isInt(`${max_age_range}`)}
              onChange={handleChange}
              width="1"
            />
            <Form.Field
              id="form-input-male_gender_count"
              name="male_gender_count"
              value={male_gender_count}
              control={Input}
              placeholder="..."
              error={!validator.isInt(male_gender_count)}
              onChange={handleChange}
              width="1"
            />
            _
            <Form.Field
              id="form-input-female_gender_count"
              name="female_gender_count"
              value={female_gender_count}
              control={Input}
              placeholder="..."
              error={!validator.isInt(female_gender_count)}
              onChange={handleChange}
              width="1"
            />
          </Form.Group>

          <Header>Areas of interest</Header>
          <Divider />

          <Form.Group widths="equal">
            <Form.Field
              name="province"
              value={province}
              control={MySelect}
              fluid
              options={provinces}
              label={{
                children: "Provinces",
                htmlFor: "form-input-province"
              }}
              placeholder="Provinces..."
              error={province.length === 0 || (aE && aI === 0)}
              onChange={handleChange}
              disabled={aL}
              loading={aL && aI === 0}
            />
            <Form.Field
              name="district"
              value={district}
              control={MySelect}
              fluid
              options={districts}
              label={{
                children: "Districts",
                htmlFor: "form-input-district"
              }}
              placeholder="Districts"
              error={district.length === 0 || (aE && aI === 1)}
              onChange={handleChange}
              disabled={aL}
              loading={aL && aI === 1}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              name="sector"
              value={sector}
              control={MySelect}
              fluid
              options={sectors}
              label={{
                children: "Sectors",
                htmlFor: "form-input-sector"
              }}
              placeholder="Sectors..."
              error={sector.length === 0 || (aE && aI === 2)}
              onChange={handleChange}
              disabled={aL}
              loading={aL && aI === 2}
            />
            <Form.Field
              name="cell"
              value={cell}
              control={MySelect}
              fluid
              options={cells}
              label={{
                children: "Cells",
                htmlFor: "form-input-cell"
              }}
              placeholder="Cells..."
              error={cell.length === 0 || (aE && aI === 3)}
              onChange={handleChange}
              disabled={aL}
              loading={aL && aI === 3}
            />
          </Form.Group>
          <Form.Field
            name="village"
            value={village}
            control={MySelect}
            fluid
            options={villages}
            label={{
              children: "Villages",
              htmlFor: "form-input-village"
            }}
            placeholder="Villages..."
            error={village.length === 0 || (aE && aI === 4)}
            onChange={handleChange}
            disabled={aL}
            loading={aL && aI === 4}
          />
          {aE && (
            <Message error visible={aE}>
              <div className="areaError">
                <span>{aM}</span>
                <span onClick={() => aR()}>Retry...</span>
              </div>
            </Message>
          )}
          <Message error content={lM} />
          <Message success content="Profile details saved!" />
          <Divider />
          <Form.Button primary style={{ width: "150px" }}>
            Save
          </Form.Button>
        </Form>
        <div className="flex-1" />
      </div>
    </div>
  </div>
);

export default UI;
