import React from "react";

import { Switch, Route } from "react-router-dom";

import Overview from "./Overview";
import Partner from "./Partner";
import SavingGroups from "./SavingGroups";

const UI = props => (
  <Switch>
    <Route
      exact
      path={`/organizations/${props.org_id}/projects/${props.project_id}`}
      render={props_ => (
        <Overview
          {...props}
          {...props_}
          org_id={props.org_id}
          project_id={props.project_id}
        />
      )}
    />
    <Route
      exact
      path={`/organizations/${props.org_id}/projects/${
        props.project_id
      }/partner`}
      render={props_ => (
        <Partner
          {...props}
          {...props_}
          org_id={props.org_id}
          project_id={props.project_id}
        />
      )}
    />
    <Route
      exact
      path={`/organizations/${props.org_id}/projects/${
        props.project_id
      }/saving-groups`}
      render={props_ => (
        <SavingGroups
          {...props}
          {...props_}
          org_id={props.org_id}
          project_id={props.project_id}
        />
      )}
    />
  </Switch>
);

export default UI;
