import React from "react";
import { NavLink } from "react-router-dom";

const Toolbar = props => (
  <div className="toolbar">
    {props.links.map(
      ({ url = "/", title = "Go to...", exact = false }, index) => (
        <NavLink
          key={`${url}-${index}`}
          className="item"
          activeClassName="active"
          exact={exact}
          to={`${props.prefix}${url}`}
        >
          {title}
        </NavLink>
      )
    )}
    <div className="padder flex-1">&nbsp;</div>
    {props.right}
  </div>
);

export default Toolbar;
