import React from "react";
import { POST } from "./../../../../../../utils/api";
import formErrors from "./../../../../../../utils/formErrors";
import phoneVerify from "./../../../../../../utils/phoneVerify";
import UI from "./UI";

class New extends React.Component {
  constructor(props) {
    super(props);

    let { areas } = props.project;
    let are = {};
    Object.keys(areas).map(key => {
      are[`are_${key}`] = areas[key];
      are[`all_are_${key}`] = areas[key];
      return key;
    });

    this.state = {
      org_name: "",
      org_email: "",
      org_phone: "",
      org_type: 3,
      org_country: "Rwanda",
      org_address: "",
      adm_username: null,
      adm_password: null,
      adm_name: "",
      adm_email: "",
      adm_phone: "",
      adm_id_number: "",
      adm_gender: "",
      adm_type: 2,
      adm_birth_date: null,
      adm_education: null,
      adm_location: null,
      ...are,
      lL: false,
      lE: false,
      lM: "",
      lS: false
    };
  }

  areas = [
    "are_province",
    "are_district",
    "are_sector",
    "are_cell",
    "are_village"
  ];

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, lE: false, lS: false }, () => {
      setTimeout(() => {
        if (this.areas.includes(name)) {
          let next = this.areas.indexOf(name) + 1;
          if (next < this.areas.length) {
            this.handleLocation(next);
          }
        }
      }, 500);
    });
  };

  handleLocation = index => {
    let items = [];
    let prev = this.areas[index - 1];
    let next = this.areas[index];

    let value = this.state[prev];

    this.state[`all_${next}s`].map(item => {
      if (value.includes(item[prev.replace("are_", "")])) items.push(item);

      return item;
    });

    let obj = {};

    obj[`${this.areas[index]}`] = [];
    obj[`${this.areas[index]}s`] = items;

    for (let i = index + 1; i < this.areas.length; i++) {
      obj[`${this.areas[i]}`] = [];
      obj[`${this.areas[i]}s`] = [];
    }

    this.setState({
      ...obj
    });
  };

  handleSave = (...e) => {
    if (formErrors(e).ok) {
      const { project_id } = this.props;

      const {
        org_name,
        org_email,
        org_phone,
        org_type,
        org_country,
        org_address,
        adm_username,
        adm_password,
        adm_name,
        adm_email,
        adm_phone,
        adm_id_number,
        adm_gender,
        adm_type,
        adm_birth_date,
        adm_education,
        adm_location,
        are_village
      } = this.state;

      const data = {
        organization: {
          name: org_name,
          phone: phoneVerify(org_phone).phone,
          email: org_email,
          address: org_address,
          country: org_country,
          type: org_type
        },
        contact_person: {
          username: adm_username,
          password: adm_password,
          name: adm_name,
          email: adm_email,
          phone: phoneVerify(adm_phone).phone,
          type: adm_type,
          birth_date: adm_birth_date,
          gender: adm_gender,
          education: adm_education,
          id_number: adm_id_number,
          location: adm_location
        },
        intervation_area_data: are_village
      };

      this.setState({ lL: true, lE: false, lM: "", lS: false });

      POST(`v2/projects/${project_id}/invite/partners`, data)
        .then(data_ => {
          this.setState({ lL: false, lE: false, lM: "", lS: true });
        })
        .catch(error => {
          this.setState({ lL: false, lE: true, lM: error, lS: false });
        });
    }
  };

  render() {
    return <UI {...this} {...this.props} {...this.state} />;
  }
}

export default New;
