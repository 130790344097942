import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Projects from "./Projects";
import Project from "./Project";
import New from "./New";

class NGO extends React.Component {
  render(){
    
    return (
      <Switch>
        <Route
          exact
          path="/projects"
          render={props => <Projects {...this.props} {...props} />}
        />
        <Route
          exact
          path="/projects/new"
          render={props => (
            <New {...this.props} {...props} {...props.match.params} />
          )}
        />
        <Route
          path="/projects/:project_id"
          render={props => (
            <Project {...this.props} {...props} {...props.match.params} />
          )}
        />
        <Route path="/" render={props => <Home {...this.props} {...props} />} />
      </Switch>
    );
  }
}

export default NGO;
