import React from "react";

import { Switch, Route } from "react-router-dom";

import Overview from "./Overview";
import Projects from "./Projects";
import SavingGroups from "./SavingGroups";

const UI = props => (
  <Switch>
    <Route
      exact
      path={`/organizations/${props.org_id}/admins/${props.agent_id}`}
      render={props_ => (
        <Overview
          {...props}
          {...props_}
          org_id={props.org_id}
          agent_id={props.agent_id}
        />
      )}
    />
    <Route
      path={`/organizations/${props.org_id}/admins/${props.agent_id}/projects`}
      render={props_ => (
        <Projects
          {...props}
          {...props_}
          org_id={props.org_id}
          agent_id={props.agent_id}
        />
      )}
    />
    <Route
      path={`/organizations/${props.org_id}/admins/${
        props.agent_id
      }/saving-groups`}
      render={props_ => (
        <SavingGroups
          {...props}
          {...props_}
          org_id={props.org_id}
          agent_id={props.agent_id}
        />
      )}
    />
  </Switch>
);

export default UI;
