import React from "react";
import { GET, POST } from "./../../../../../../utils/api";
import formErrors from "./../../../../../../utils/formErrors";
import phoneVerify from "./../../../../../../utils/phoneVerify";
import UI from "./UI";

class Invite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: [""],
      phone: [""],
      partner: [0],
      partners: [],
      lL: false,
      lE: false,
      lM: "",
      lR: null,
      lS: false,
      lI: 0,
      formValidationErrors:[],
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    let n = e.target.name.split("-");
    let v = [...this.state[n[0]]];
    v[n[1] * 1] = e.target.value ;
    this.setState({ [n[0]]: v, lE: false, lS: false });
  };

  addRow = () => {
    const { name, phone, partner } = this.state;
    this.setState({
      name: [...name, ""],
      phone: [...phone, ""],
      partner: [...partner, 0]
    });
  };

  removeRow = i => {
    let { name, phone, partner } = this.state;
    name.splice(i, 1);
    phone.splice(i, 1);
    partner.splice(i, 1);

    this.setState({ name, phone, partner });
  };

  componentDidMount = () => this.handleFetch();

  handleFetch = () => {
    let { project_id } = this.props;

    this.setState({ lL: true, lE: false, lM: "", lR: null, lI: 0 });

    // GET(`v2/projects/${project_id}/partners`)
    GET(`save-ssa/project/${project_id}/partners`)
      .then(data => {
        this.setState({
          partners: [
            {
              text: "No Partner",
              value: 0,
              key: 0
            },
            ...data.map(p => ({
              text: p.partner_name,
              value: p.partner_id,
              key: p.partner_id
            }))
          ],
          lL: false,
          lE: false,
          lM: "",
          lR: null,
          lI: 1
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: null, lI: 0 });
      });
  };

  handleSave = (...e) => {
    if (formErrors(e).ok) {
      let data = [];
      const { name, phone, partner } = this.state;
      const { project_id, userOrg } = this.props;
      name.map((n, i) => {
        data.push({
          name: n,
          partner_id: partner[i] * 1,
          organization_id: userOrg.id * 1,
          project_id: project_id * 1,
          phone_number: phoneVerify(phone[i]).phone
        });
        return n;
      });
      let errors=[];
      [...data].map((invite,index)=>{
        
       if(invite.name.length===0){
         errors=[...errors,{nameError:'Name is required',errorField:index}]
       }
       if(invite.phone_number.length===0){
        errors=[...errors,{phoneError:'Phone Number is required',errorField:index}]
      }
      return null;
      });
      this.setState({formValidationErrors:errors},()=>{
        if(this.state.formValidationErrors.length===0){
          this.setState({ lL: true, lE: false, lM: "", lS: false });
          Promise.all([
            ...data.map((d, i) =>
              POST('save-ssa/agent/invite', d)
            )
          ])
            .then(data => {
              this.setState({
                name: [""],
                phone: [""],
                partner: [0],
                lL: false,
                lE: false,
                lM: `Agent${name.length > 1 && "s"} successfully invited!`,
                lS: true
              });
            })
            .catch(error => {
              this.setState({ lL: false, lE: true, lM: error, lS: false });
            });
        }
      });
        }
  };

  render() {
    return <UI {...this} {...this.props} {...this.state} />;
  }
}

export default Invite;
