import React from "react";
import { Switch, Route } from "react-router-dom";
import List from "./List";
import ListNCCR from "./ListNCCR";
import Agent from "./Agent";
import AgentNCCR from "./AgentNCCR";

const UI = props => (
  <Switch>
    <Route
      exact
      path={`/organizations/${props.org_id}/agents`}
      render={props_ => <List {...props} {...props_} />}
    />
    <Route
      exact
      path={`/organizations/${props.org_id}/admins`}
      render={props_ => <ListNCCR {...props} {...props_} />}
    />
    <Route
      path={`/organizations/${props.org_id}/agents/:agent_id`}
      render={props_ => (
        <Agent {...props} {...props_} agent_id={props_.match.params.agent_id} />
      )}
    />
    <Route
      path={`/organizations/${props.org_id}/admins/:agent_id`}
      render={props_ => (
        <AgentNCCR
          {...props}
          {...props_}
          agent_id={props_.match.params.agent_id}
        />
      )}
    />
  </Switch>
);

export default UI;
