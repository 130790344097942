import React from "react";
import { baseUrl, GET, POST } from "./../../../../utils/api";
import UI from "./UI";
import formErrors from "./../../../../utils/formErrors";

class New extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      start: "",
      end: "",
      budget: "",
      donor: "",
      ...props.defaultAreas,
      lL: false,
      lE: false,
      lM: "",
      lS: false,
      lR: this.handleSave,
      aL: false,
      aE: false,
      aM: "",
      aR: null,
      aI: null,
      isEndDateInvalid:false,
    };
  }

  areas = ["province", "district", "sector", "cell", "village"];

  handleChange = (e, { name, value }) => {
    this.setState(
      { [name]: value, lE: false, lS: false },
      () => {
        setTimeout(() => {
          if (this.areas.includes(name)) {
            let next = this.areas.indexOf(name) + 1;
            if (next < this.areas.length) {
              this.handleLocation(next);
            }
          }
        }, 500);
      }
    );
  };

  handleLocation = index => {
    let add_ = this.state[this.areas[index - 1]];

    this.setState({
      aL: true,
      aE: false,
      aM: "",
      aR: null,
      aI: index
    });

    let location = `${baseUrl}location/${this.areas[index - 1]}s_${
      this.areas[index]
    }s/${add_.join(",")}`;

    GET(location)
      .then(data => {
        let items = [];
        data.map(item => {
          items.push({
            label: item.name,
            text: item.name,
            value: item.id,
            key: item.id
          });
          return item;
        });

        let obj = {};

        obj[`${this.areas[index]}`] = [];
        obj[`${this.areas[index]}s`] = items;

        for (let i = index + 1; i < this.areas.length; i++) {
          obj[`${this.areas[i]}`] = [];
          obj[`${this.areas[i]}s`] = [];
        }

        this.setState({
          aL: false,
          aE: false,
          aM: "",
          aR: null,
          aI: null,
          ...obj
        });
      })
      .catch(error => {
        this.setState({
          aL: false,
          aE: true,
          aM: `Failed to get ${this.areas[index]}s.`,
          aR: () => this.handleLocation(index),
          aI: index
        });
      });
  };

  handleSave = (...e) => {
    let { userOrg, currentUser } = this.props;

    const { name, end, start, budget, donor } = this.state;

    let d = { name, end, start, budget: budget * 1, donor };
    this.setState({isEndDateInvalid:end<=start},()=>{
      if (formErrors(e).ok&&!this.state.isEndDateInvalid) {
      this.setState({ lL: true, lE: false, lM: "", lS: false });

      // POST(`v2/organizations/${userOrg.id}/projects`, {
      POST(`save-ssa/organization/${userOrg.id}/projects`, {
        ...d,
        type: 0,
        user_id: currentUser.id,
        common_activities: "",
        created_by: currentUser.id
      })
        .then(data =>
          this.setState({ project_id: data.id }, () => this.postIntervention(e))
        )
        .catch(error => {
          this.setState({ lL: false, lE: true, lM: error, lS: false });
        });
    }
  }

    )}
  postIntervention = (...e) => {
    if (formErrors(...e).ok) {
      this.setState({ lL: true, lE: false, lM: "", lS: false });

      const { project_id, village } = this.state;

      POST(`save-ssa/project/${project_id}/intervention_areas`, {
        village_ids: village
      })
        .then(data => {
          this.setState({
            lL: false,
            lE: false,
            lM: "",
            lS: true,
            lR: this.handleSave
          });
        })
        .catch(error => {
          this.setState({
            lL: false,
            lE: true,
            lM: error,
            lS: false,
            lR: this.postIntervention
          });
        });
    }


  };

  render() {
    return <UI {...this} {...this.props} {...this.state} />;
  }
}

export default New;
