import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Organizations from "./Organizations";
import Organization from "./Organization";

class SSA extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/organizations"
          render={props => <Organizations {...this.props} {...props} />}
        />
        <Route
          path="/organizations/:main_id"
          render={props => <Organization {...this.props} {...props} />}
        />
        <Route path="/" render={props => <Home {...this.props} {...props} />} />
      </Switch>
    );
  }
}

export default SSA;
