const capitalize = (text, i = 100) => {
  let arr = `${text}`.split(" ");
  arr = [
    ...arr.map((word, index) => {
      let first = "";

      let rest = "";

      if (word.length !== 0) {
        first = word.substr(0, 1);
        if (index <= i) {
          first = first.toUpperCase();
        }
      }

      if (word.length > 1) {
        rest = word.substr(1, word.length - 1).toLowerCase();
      }

      return `${first}${rest}`;
    })
  ];

  return arr.join(" ");
};

export default capitalize;
