import React from "react";
import { Icon, Grid } from "semantic-ui-react";
import Box from "./../../../../../components/Box";
import phoneVerify from "./../../../../../utils/phoneVerify";
import { genders } from "./../../../../../utils/vars";

var moment = require("moment");

const UI = ({ project, history }) => {
  return (
    <div className="pannel-scroll">
      <div className="padding">
        <div className="header">
          {history && history.goBack && (
            <div className="back" onClick={() => history.goBack()}>
              <Icon name="arrow left" fitted size="large" />
            </div>
          )}
          <span className="title">
            Federation: {project.project_details.name}
          </span>
        </div>

        <Grid columns={4}>
          <Grid.Row stretched>
            <Grid.Column width={4}>
              <Box
                title="SAVING GROUPS"
                value={project.sgs_count}
                color="green"
                chart={{
                  data: project.sg_enrollment_per_month,
                  key: "count"
                }}
                lineChart
              />
              <Box
                title="SAVINGS"
                value={project.savings_amount}
                unit="RWF"
                color="orange"
                chart={{
                  data: project.savings_vs_loans_distribution,
                  key: "savings_amount"
                }}
                lineChart
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Box
                title="ADMINS"
                value={project.agent_count}
                color="blue"
                chart={{
                  data: project.agent_enrollment_per_month,
                  key: "count"
                }}
                lineChart
              />
              <Box
                title="OUTSTANDING LOANS"
                value={project.outstanding_loans_amount}
                unit="RWF"
                color="red"
                chart={{
                  data: project.savings_vs_loans_distribution,
                  key: "outstanding_loans_amount"
                }}
                lineChart
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Box
                name={project.project_details.name}
                rows={[
                  [
                    {
                      label: "Common Activities",
                      value: project.project_details.common_activities
                    }
                  ],
                  [
                    {
                      label: "President",
                      value:
                        project.federation_committee.president.name ||
                        "No name..."
                    },
                    {
                      emptyLabel: "",
                      value: phoneVerify(
                        project.federation_committee.president.phone
                      ).phone_
                    }
                  ],
                  [
                    {
                      label: "Secretary",
                      value:
                        project.federation_committee.secretary.name ||
                        "No name..."
                    },
                    {
                      emptyLabel: "",
                      value: phoneVerify(
                        project.federation_committee.secretary.phone
                      ).phone_
                    }
                  ],
                  [
                    {
                      label: "Contact Person",
                      value:
                        project.federation_contact_person.name || "No name..."
                    },
                    {
                      emptyLabel: "",
                      value: phoneVerify(
                        project.federation_contact_person.phone
                      ).phone_
                    }
                  ],
                  [
                    {
                      label:
                        genders[project.federation_contact_person.gender].text,
                      className: "labelFlat noMargin"
                    },
                    {
                      label:
                        project.federation_contact_person.email ||
                        "No email...",
                      className: "labelFlat noMargin"
                    }
                  ],
                  [],
                  [
                    {
                      label: `Created on ${moment(
                        project.project_details.date
                      ).format("MMM DD, YYYY")}`,
                      className: "labelFlat footer right"
                    }
                  ]
                ]}
                noDate
                noChart
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Box
                title="Male Members"
                value={project.male_sg_members_count}
                chart={{
                  data: project.male_members_distribution,
                  key: "count"
                }}
                icon="male"
                barChart
              />
              <Box
                title="Female Members"
                value={project.female_sg_members_count}
                chart={{
                  data: project.female_members_distribution,
                  key: "count"
                }}
                icon="female"
                barChart
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column width={8}>
              <Box
                title="SGs Distribution Per Admin"
                chart={{
                  data: [
                    ...project.sg_distribution_by_agent.map(d => ({
                      name: d.agent_name,
                      caption: "SGs:",
                      count: d.sg_count
                    }))
                  ],
                  key: "count"
                }}
                radialChart
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Box
                title="MNO Distribution Per Province"
                chart={{
                  data: [
                    ...project.mno_distribution.map(d => ({
                      name: d.province_name,
                      MTN: d.mtn_count,
                      TIGO: d.tigo_count
                    }))
                  ],
                  keys: ["MTN", "TIGO"]
                }}
                doubleBarChart
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column width={8}>
              <Box
                title="SGs Enrolment Per Gender"
                chart={{
                  data: [
                    ...project.members_enrollment_by_month.map(d => {
                      let Male = 0,
                        Female = 0;

                      project.male_members_distribution.map(d_ => {
                        if (d.month === d_.month) {
                          Male = d_.count;
                        }
                        return d_;
                      });

                      project.female_members_distribution.map(d_ => {
                        if (d.month === d_.month) {
                          Female = d_.count;
                        }
                        return d_;
                      });

                      return {
                        name: moment(d.month).format("MMM"),
                        Male,
                        Female
                      };
                    })
                  ],
                  keys: ["Male", "Female"]
                }}
                doubleLineChart
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Box
                title="Admins' Level Of Education"
                chart={{
                  data: [
                    ...project.agent_education_grouping.map(d => ({
                      name: d.education,
                      caption: "Agents:",
                      count: d.count
                    }))
                  ],
                  key: "count"
                }}
                radialChart
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <Box
                title="Members Age Range"
                chart={{
                  data: [
                    {
                      name: "18-24",
                      caption: "Members:",
                      count: project.member_age_groups.from1824
                    },
                    {
                      name: "25-29",
                      caption: "Members:",
                      count: project.member_age_groups.from2529
                    },
                    {
                      name: "30-34",
                      caption: "Members:",
                      count: project.member_age_groups.from3034
                    },
                    {
                      name: "35-39",
                      caption: "Members:",
                      count: project.member_age_groups.from3539
                    },
                    {
                      name: "40-49",
                      caption: "Members:",
                      count: project.member_age_groups.from4049
                    },
                    {
                      name: "50+",
                      caption: "Members:",
                      count: project.member_age_groups.above50
                    }
                  ],
                  key: "count"
                }}
                radialBarChart
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Box
                title="Savings VS Loans"
                chart={{
                  data: [
                    ...(project.savings_vs_loans_distribution
                      ? project.savings_vs_loans_distribution
                      : []
                    ).map(d => ({
                      name: moment(d.month).format("MMM"),
                      Savings: d.savings_amount,
                      Loans: d.loans_amount
                    }))
                  ],
                  keys: ["Savings", "Loans"]
                }}
                doubleBarChart
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default UI;
