import React from "react";
import { POST, GET } from "./../../../../../../utils/api";
import formErrors from "./../../../../../../utils/formErrors";
import phoneVerify from "./../../../../../../utils/phoneVerify";
import UI from "./UI";

class Add extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      work_email: "",
      phone: "",
      position: "",
      id_number: "",
      existing: "",
      lL: false,
      lE: false,
      lM: "",
      lS: false,
      fL: false,
      fE: false,
      fM: "",
      fS: false
    };
  }

  handleChange = (e, { name, value }) =>
    this.setState(
      {
        [name]: value,
        lE: false,
        lS: false,
        fE: false
      },
      () => {
        if (name === "id_number") this.checkID();
      }
    );

  checkID = () => {
    clearTimeout(this.checkTimer);
    this.checkTimer = setTimeout(this.handleCheck, 1500);
  };

  handleCheck = () => {
    const { id_number } = this.state;

    clearTimeout(this.checkTimer);

    if (id_number.length > 0) {
      this.setState({ fL: true, fE: false, fM: "", fS: false });
      GET(`save-ssa/find_user/${id_number}`)
        .then(data => {
          this.setState({
            fL: false,
            fE: false,
            fM: "",
            fS: true,
            name: data.name,
            work_email: data.work_email,
            phone: data.phone,
            position: data.position,
            existing: data.id !== 0
          });
        })
        .catch(error => {
          this.setState({ fL: false, fE: true, fM: error, fS: false });
        });
    }
  };

  handleSave = (...e) => {
    if (formErrors(e).ok) {
      const { name, position, phone, work_email, id_number } = this.state;
      const { userOrg } = this.props;

      const { admin_type } = this.props.currentUser;

      let data = {
        organization_id: userOrg.id,
        username: `${(() =>
          name
            .split(" ")
            .map(d_ => (d_.length > 3 ? d_.substr(0, 3) : d_)))().join(
          ""
        )}${Date.now()}`,
        birth_date: "1900-01-01",
        type: 1,
        name,
        work_email,
        phone: phoneVerify(phone).phone,
        position,
        id_number,
        admin_type
      };

      this.setState({ lL: true, lE: false, lM: "", lS: false });

      POST(`save-ssa/user/create`, data)
        .then(data => {
          this.setState({
            name: "",
            work_email: "",
            phone: "",
            position: "",
            id_number: "",
            existing: "",
            lL: false,
            lE: false,
            lM: `User successfully added!`,
            lS: true,
            fL: false,
            fE: false,
            fM: "",
            fS: false
          });
        })
        .catch(error => {
          this.setState({ lL: false, lE: true, lM: error, lS: false });
        });
    }
  };

  render() {
    return <UI {...this} {...this.props} {...this.state} />;
  }
}

export default Add;
