import React from "react";
import {Grid } from "semantic-ui-react";
import Box from "./../../../../../../../components/Box";

import { Switch, Route } from "react-router-dom";

import List from "./List";
import Member from "./Member";

const UI = props => (
  <div className="pannels-row">
    <Switch>
      <div className="pannels-column">
        <Route
          exact
          path={`/profiles/${props.profile_id}/saving-groups/${
            props.sg_id
          }/members`}
          render={props_ => (
            <div className="pannel-scroll-inside">
              <List {...props} {...props_} />
            </div>
          )}
        />
        <Route
          path={`/profiles/${props.profile_id}/saving-groups/${
            props.sg_id
          }/members/:member_id`}
          render={props_ => (
            <Member
              {...props}
              {...props_}
              member_id={props_.match.params.member_id}
            />
          )}
        />
      </div>
    </Switch>

    <Switch>
      <Route
        exact
        path={`/profiles/${props.profile_id}/saving-groups/${
          props.sg_id
        }/members`}
        render={() => (
          <div className="aside-saving-groups-members">
            <div className="in-aside-savings-groups-members">
              <Grid columns={1}>
                <Grid.Row>
                  <Box
                    title="Gender Segregation"
                    chart={{
                      data: [
                        {
                          name: "Male",
                          caption: "Count: ",
                          count: 0
                        },
                        {
                          name: "Female",
                          caption: "Count: ",
                          count: 0
                        }
                      ],
                      key: "count"
                    }}
                    radialChart2
                  />
                </Grid.Row>

                <Grid.Row>
                  <Box
                    title="Members Age Range"
                    chart={{
                      data: [
                        {
                          name: "18-24",
                          caption: "Members:",
                          count: 0
                        },
                        {
                          name: "25-29",
                          caption: "Members:",
                          count: 0
                        },
                        {
                          name: "30-34",
                          caption: "Members:",
                          count: 0
                        },
                        {
                          name: "35-39",
                          caption: "Members:",
                          count: 0
                        },
                        {
                          name: "40-49",
                          caption: "Members:",
                          count: 0
                        },
                        {
                          name: "50+",
                          caption: "Members:",
                          count: 0
                        }
                      ],
                      key: "count"
                    }}
                    radialBarChart2
                  />
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}
      />
    </Switch>
  </div>
);

export default UI;
