import React from "react";
import {
  Form,
  Input,
  Select,
  Message,
  Header,
  Divider,
  Icon
} from "semantic-ui-react";
import phoneVerify from "./../../../../../../utils/phoneVerify";
import validator from "validator";
import { organizationTypes, genders } from "./../../../../../../utils/vars";
import MySelect from "./../../../../../../components/MySelect";

const UI = ({
  lL,
  lS,
  lE,
  lM,
  org_name,
  org_email,
  org_phone,
  org_type,
  org_address,
  adm_name,
  adm_email,
  adm_phone,
  adm_id_number,
  adm_gender,
  are_province,
  are_district,
  are_sector,
  are_cell,
  are_village,
  are_provinces,
  are_districts,
  are_sectors,
  are_cells,
  are_villages,
  handleSave,
  handleChange,
  history
}) => {
  return (
    <div className="pannel-scroll">
      <div className="padding">
        <div className="header">
          {history && history.goBack && (
            <div className="back" onClick={() => history.goBack()}>
              <Icon name="arrow left" fitted size="large" />
            </div>
          )}
          <span className="title">Invite Partner</span>
        </div>
        <div className="pannels-row">
          <div className="flex-1" />
          <Form
            className="fluid"
            loading={lL}
            success={lS}
            error={lE}
            onSubmit={handleSave}
          >
            <Header>Partner's Details</Header>
            <Form.Field
              id="form-input-org_name"
              name="org_name"
              value={org_name}
              control={Input}
              label="Name"
              placeholder="Name..."
              error={validator.isEmpty(org_name)}
              onChange={handleChange}
            />
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-org_email"
                name="org_email"
                value={org_email}
                control={Input}
                label="Email"
                type="email"
                placeholder="Email..."
                error={!validator.isEmail(org_email)}
                onChange={handleChange}
              />
              <Form.Field
                id="form-input-org_phone"
                name="org_phone"
                value={org_phone}
                control={Input}
                label="Phone"
                type="tel"
                placeholder="Phone..."
                error={!phoneVerify(org_phone).ok}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                name="org_type"
                value={org_type}
                control={Select}
                fluid
                options={organizationTypes}
                label={{ children: "Type", htmlFor: "form-input-org_type" }}
                placeholder="Type..."
                search
                searchInput={{ id: "form-input-org_type" }}
                error={!validator.isInt(`${org_type}`)}
                onChange={handleChange}
                disabled
              />
              <Form.Field
                id="form-input-org_address"
                name="org_address"
                value={org_address}
                control={Input}
                label="Address"
                placeholder="Address..."
                error={validator.isEmpty(org_address)}
                onChange={handleChange}
              />
            </Form.Group>
            <Header>Partner's Contact Person</Header>
            <Form.Field
              id="form-input-adm_name"
              name="adm_name"
              value={adm_name}
              control={Input}
              label="Name"
              placeholder="Name..."
              error={validator.isEmpty(adm_name)}
              onChange={handleChange}
            />
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-adm_email"
                name="adm_email"
                value={adm_email}
                control={Input}
                label="Email"
                type="email"
                placeholder="Email..."
                error={!validator.isEmail(adm_email)}
                onChange={handleChange}
              />
              <Form.Field
                id="form-input-adm_phone"
                name="adm_phone"
                value={adm_phone}
                control={Input}
                label="Phone"
                type="tel"
                placeholder="Phone..."
                error={!phoneVerify(adm_phone).ok}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-adm_id_number"
                name="adm_id_number"
                value={adm_id_number}
                control={Input}
                label="ID Number"
                placeholder="ID Number..."
                error={validator.isEmpty(adm_id_number)}
                onChange={handleChange}
              />
              <Form.Field
                name="adm_gender"
                value={adm_gender}
                control={Select}
                fluid
                options={genders}
                label={{
                  children: "Gender",
                  htmlFor: "form-input-adm_gender"
                }}
                placeholder="Gender..."
                search
                searchInput={{ id: "form-input-adm_gender" }}
                error={!validator.isInt(`${adm_gender}`)}
                onChange={handleChange}
              />
            </Form.Group>
            <Header>Partner's Intervention Area</Header>
            <Form.Group widths="equal">
              <Form.Field
                name="are_province"
                value={are_province}
                control={MySelect}
                fluid
                options={are_provinces}
                label={{
                  children: "Provinces",
                  htmlFor: "form-input-are_province"
                }}
                placeholder="Provinces..."
                search
                searchInput={{ id: "form-input-are_province" }}
                error={are_province.length === 0}
                onChange={handleChange}
                multiple
              />
              <Form.Field
                name="are_district"
                value={are_district}
                control={MySelect}
                fluid
                options={are_districts}
                label={{
                  children: "Districts",
                  htmlFor: "form-input-are_district"
                }}
                placeholder="Districts"
                search
                searchInput={{ id: "form-input-are_district" }}
                error={are_district.length === 0}
                onChange={handleChange}
                multiple
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                name="are_sector"
                value={are_sector}
                control={MySelect}
                fluid
                options={are_sectors}
                label={{
                  children: "Sectors",
                  htmlFor: "form-input-are_sector"
                }}
                placeholder="Sectors..."
                search
                searchInput={{ id: "form-input-are_sector" }}
                error={are_sector.length === 0}
                onChange={handleChange}
                multiple
              />
              <Form.Field
                name="are_cell"
                value={are_cell}
                control={MySelect}
                fluid
                options={are_cells}
                label={{
                  children: "Cells",
                  htmlFor: "form-input-are_cell"
                }}
                placeholder="Cells..."
                search
                searchInput={{ id: "form-input-are_cell" }}
                error={are_cell.length === 0}
                onChange={handleChange}
                multiple
              />
            </Form.Group>
            <Form.Field
              name="are_village"
              value={are_village}
              control={MySelect}
              fluid
              options={are_villages}
              label={{
                children: "Villages",
                htmlFor: "form-input-are_village"
              }}
              placeholder="Villages..."
              search
              searchInput={{ id: "form-input-are_village" }}
              error={are_village.length === 0}
              onChange={handleChange}
              multiple
            />
            <Message error content={lM} />
            <Message success content={`Partner created!`} />
            <Divider />
            <Form.Button primary floated="right">
              Invite Partner
            </Form.Button>
          </Form>
          <div className="flex-1" />
        </div>
      </div>
    </div>
  );
};

export default UI;
