import React from "react";
import {
  Form,
  Message,
  Divider,
  Icon,
  Button,
  Select,
} from "semantic-ui-react";
import phoneVerify from "../../../../../../utils/phoneVerify";
import ContentLoader from "./../../../../../../components/Loader";
import validator from "validator";

const UI = ({
  lL,
  lS,
  lE,
  lM,
  lR,
  lI,
  name,
  phone,
  partner,
  partners,
  handleSave,
  handleChange,
  addRow,
  removeRow,
  history,
  formValidationErrors
}) => {
  
  return (
    <ContentLoader
      lL={lL && lI === 0}
      lE={lE && lI === 0}
      lM={lM}
      lR={lR}
      message="Fetching partners..."
      showContentMessage="You have no partner!"
      showContent={lI !== 0}
    >
      <div className="pannel-scroll">
        <div className="padding">
          <div className="header">
            {history && history.goBack && (
              <div className="back" onClick={() => history.goBack()}>
                <Icon name="arrow left" fitted size="large" />
              </div>
            )}
            <span className="title">Invite Agent{name.length > 1 && "s"}</span>
          </div>
          <Form
            loading={lL}
            success={lS}
            error={lE}
            onSubmit={handleSave}
            className="fluid"
          >
    {name.map((n, i) => (
      <Form.Group key={`fg-${i}`} widths="equal">
        <div className={validator.isEmpty(name[i])?"error field":"field"}>
            <label>Name</label>
            <div className="ui fluid input">
            <input type="text" placeholder="Name"
            id={`form-input-name-${i}`}
            name={`name-${i}`}
            value={name[i]}
            onChange={handleChange}
            /></div>
            {formValidationErrors[i]&&i===formValidationErrors[i].errorField?(
            <div className="ui pointing top prompt label">Please enter a name</div>
            ):null}
          </div>

            <div className={!phoneVerify(phone[i]).ok?"error field":"field"}>
              <label>Phone</label>
              <div className="ui fluid input"><input type="tel" 
              onChange={handleChange}
              name={`phone-${i}`}
              value={phone[i]}
              placeholder="Phone" /></div>
              {formValidationErrors[i]&&i===formValidationErrors[i].errorField?(
              <div className="ui pointing top prompt label">Please enter a phone number</div>
              ):null}
            </div>
                <Form.Field
                  name={`partner-${i}`}
                  value={partner[i]}
                  control={Select}
                  fluid
                  options={partners}
                  label={{
                    children: "Partner",
                    htmlFor: `form-input-partner-${i}`
                  }}
                  placeholder="Partner..."
                  search
                  searchInput={{ id: `form-input-partner-${i}` }}
                  error={!validator.isInt(`${partner[i]}`)}
                  onChange={handleChange}
                />
                <Button
                  basic
                  color="red"
                  icon="close"
                  as="div"
                  disabled={i === 0 && name.length === 1}
                  onClick={() => removeRow(i)}
                />
              </Form.Group>
            ))}
            {lE && <Message error content={lM} />}
            <Message
              success
              content={`Agent(s) invited!`}
            />
            <Divider />
            <Form.Group widths="equal">
              <Button
                basic
                color="green"
                content="Add Row"
                labelPosition="left"
                icon="add"
                as="div"
                onClick={addRow}
              />
            </Form.Group>
            <Divider />
            <Form.Button
              primary
              floated="right"
              content={`Invite Agent${name.length > 1 ? "s" : ""}`}
              labelPosition="right"
              icon="check"
            />
          </Form>
        </div>
      </div>
    </ContentLoader>
  );
};

export default UI;
