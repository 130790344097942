import React from "react";

import ContentLoader from "./../../../../../../components/Loader";
import DataTable from "./../../../../../../components/DataTable";

const UI = ({ lL, lE, lM, lR, agents, history }) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Fetching agents..."
    showContent={agents.length !== 0}
  >
    <DataTable
      title="Agents"
      history={history}
      dataKey="agent_id"
      empty="No agent."
      data={agents}
      columns={[
        {
          dataField: "agent_name",
          text: "Agent",
          sort: true,
          formatter: "name",
          link: ["organizations", "[(org_id)]", "agents", "[(agent_id)]"]
        },
        {
          dataField: "savings_group_count",
          text: "Saving Groups",
          sort: true,
          formatter: "number",
          link: [
            "organizations",
            "[(org_id)]",
            "agents",
            "[(agent_id)]",
            "saving-groups"
          ]
        },
        {
          dataField: "project",
          text: "Project",
          sort: true,
          formatter: "_",
          link: ["organizations", "[(org_id)]", "projects", "[(project_id)]"]
        },
        {
          dataField: "commission",
          text: "Commission",
          textPrefix: "(RWF)",
          sort: true,
          formatter: "number"
        }
      ]}
    />
  </ContentLoader>
);

export default UI;
