import React from "react";

import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import ContentLoader from "./../../../../../../components/Loader";
import DataTable from "./../../../../../../components/DataTable";

const UI = ({ lL, lE, lM, lR, agents, project_id, history }) => (
  <ContentLoader
    lL={lL}
    lE={lE}
    lM={lM}
    lR={lR}
    message="Fetching admins..."
    showContent={agents.length !== 0}
    showContentAction={{
      link: `/federations/${project_id}/admins/invite`,
      text: "Invite Admins"
    }}
  >
    <DataTable
      title="Admins"
      history={history}
      dataKey="agent_id"
      empty="No admin."
      more={
        <Button
          as={Link}
          to={`/federations/${project_id}/admins/invite`}
          primary
          content="Invite Admins"
          labelPosition="right"
          icon="add"
        />
      }
      data={agents}
      columns={[
        {
          dataField: "name",
          text: "Admin",
          sort: true,
          formatter: "name",
          link: ["federations", "[(project_id)]", "admins", "[(id)]"]
        },
        {
          dataField: "union",
          text: "Union",
          sort: true,
          formatter: "_",
          link: ["federations", "[(project_id)]", "unions", "[(union_id)]"]
        },
        {
          dataField: "cooperative",
          text: "Cooperative",
          sort: true,
          formatter: "_",
          link: [
            "federations",
            "[(project_id)]",
            "cooperatives",
            "[(cooperative_id)]"
          ]
        },
        {
          dataField: "sg_count",
          text: "Saving Groups",
          sort: true,
          formatter: "number",
          link: [
            "federations",
            "[(project_id)]",
            "admins",
            "[(id)]",
            "saving-groups"
          ]
        }
      ]}
    />
  </ContentLoader>
);

export default UI;
