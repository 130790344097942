import React from "react";
import { Switch, Route } from "react-router-dom";
import List from "./List";
import SavingGroupsRequests from "./SavingGroupRequests";

const UI = props => (
  <Switch>
    <Route
      exact
      path={`/profiles/${props.profile_id}/requests`}
      render={props_ => <List {...props} {...props_} />}
    />
    <Route
      path={`/profiles/${props.profile_id}/requests/saving-groups/:sg_id`}
      render={props_ => <SavingGroupsRequests {...props} {...props_} sg_id={props_.match.params.sg_id} />}
    />
  </Switch>
);

export default UI;
