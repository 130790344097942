import React from "react";
import { GET } from "./../../../../../utils/api";
import UI from "./UI";

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activity: [],
      lL: false,
      lE: false,
      lM: "",
      lR: null
    };
  }

  componentDidMount = () => {
    this.handleFetch();
  };

  handleFetch = () => {
    this.setState({ lL: true, lE: false, lM: "", lR: null });

    const { userOrg } = this.props;

    GET(`save-ssa/organization/${userOrg.id}/activity`)
      .then(data => {
        this.setState({
          lL: false,
          lE: false,
          lM: "",
          lR: null,
          activity: [
            ...(() => {
              let items = [];
              data.map(item => {
                if (item.resource_details !== null) {
                  items.push({
                    ...item,
                    a_name: item.resource_details.name,
                    a_time: item.activity.created_on
                  });
                }
                return item;
              });
              return items;
            })()
          ]
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: this.handleFetch });
      });
  };

  render() {
    return <UI {...this.props} {...this} {...this.state} />;
  }
}

export default List;
