import React from "react";
import { GET } from "./../../../../../../utils/api";
import UI from "./UI";
import _ from "lodash";

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      lL: false,
      lE: false,
      lM: "",
      lR: null
    };
  }

  componentDidMount = () => {
    this.handleFetch();
  };

  handleFetch = () => {
    let { id } = this.props.userOrg;

    this.setState({ lL: true, lE: false, lM: "", lR: null });

    GET(`save-ssa/organization/users/${id}`)
      .then(data => {
        this.setState({
          lL: false,
          lE: false,
          lM: "",
          lR: null,
          users: _.sortBy(data, "name")
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: this.handleFetch });
      });
  };

  render() {
    return <UI {...this.props} {...this} {...this.state} />;
  }
}

export default List;
