const nThLetter = (t, n) => {
  let r = "";

  if (typeof t !== "undefined" && t !== null) {
    let t_ = t.split(" ");
    t_.map((name, i) => {
      r =
        r +
        (i < n
          ? `${name.charAt(0)}${t_.length === 1 ? name.charAt(1) : ""}`
          : "");
      return name;
    });
  }

  r = r.length === 0 ? "S" : r;

  return r;
};

export default nThLetter;
