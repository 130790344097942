import React from "react";
import MyList from "./../../../../components/MyList";
import phoneVerify from "./../../../../utils/phoneVerify";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { organizationTypes } from "./../../../../utils/vars";

class Organizations extends React.Component {
  render() {
    let { organizations } = this.props;

    organizations = (() => {
      let orgs = [];
      organizations.map(org => {
        if (org.type <= 4 || org.type >=8) orgs.push(org);
        return org;
      });
      return orgs;
    })();

    return (
      <div className="pannel-scroll flex-1">
        <div className="padding">
          <div className="header">
            {this.props.history && this.props.history.goBack && (
              <div className="back" onClick={() => this.props.history.goBack()}>
                <Icon name="arrow left" fitted size="large" />
              </div>
            )}
            <span className="title">
              Organizations ({organizations.length})
            </span>
          </div>
        </div>
        <MyList
          items={organizations}
          left="name"
          title="name"
          description={["phone", "email"]}
          descriptionFn={t => [
            <span>
              <Icon name="phone" fitted size="small" />
              &nbsp;{phoneVerify(t[0]).phone_}
            </span>,
            <span>
              <Icon name="at" fitted size="small" />
              &nbsp;{t[1]}
            </span>
          ]}
          descriptionJoin={<span className="padder">&nbsp;</span>}
          right="type"
          rightFn={t => {
            return organizationTypes[t - 1].text;
          }}
          as={Link}
          link={["organizations", "[(id)]"]}
        />
      </div>
    );
  }
}

export default Organizations;
