import React from "react";
import { Grid } from "semantic-ui-react";
import Box from "./../../../../../components/Box";
import numberFormat from "./../../../../../utils/numberFormat";

var moment = require("moment");

const UI = ({
  profile,
  agentEnrollmentByGender,
  cumulativeVsCurrentSavings,
  cumulativeVsOutstandingLoans
}) => (
  <div className="pannel-scroll">
    <div className="padding">
      <Grid columns={4}>
        <Grid.Row stretched>
          <Grid.Column width={4}>
            <Box
              title="SAVING GROUPS"
              value={profile.savings_result.savings_groups_count}
              color="green"
              chart={{
                data: profile.sg_enrollment_distributions,
                key: "count"
              }}
              lineChart
            />
            <Box
              title="ACTUAL SAVINGS"
              value={profile.savings_result.total_savings}
              unit="RWF"
              color="orange"
              chart={{
                data: profile.actual_savings ? profile.actual_savings : [],
                key: "savings"
              }}
              lineChart
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Box
              title="AGENTS"
              value={profile.agents_result.total_agent_count}
              color="blue"
              chart={{
                data: profile.agent_enrollment_per_month,
                key: "count"
              }}
              lineChart
            />
            <Box
              title="OUTSTANDING LOANS"
              value={profile.savings_result.total_loans}
              unit="RWF"
              color="red"
              chart={{
                data: profile.outstanding_loans_distribution,
                key: "loans_amount"
              }}
              lineChart
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Box
              name={profile.profile.name}
              rows={[
                [
                  {
                    label: "Members",
                    value: profile.savings_result.total_member_count,
                    className: "profile-slight-detail"
                  },
                  {
                    label: "Dropouts",
                    value: profile.dropout_count,
                    className: "profile-slight-detail"
                  }
                ],
                [
                  {
                    label: "Cumulative Savings",
                    value: numberFormat("0", "", "RWF"),
                    className: "profile-slight-detail"
                  }
                ],
                [
                  {
                    label: "Cumulative Interest",
                    value: numberFormat("0", "", "RWF"),
                    className: "profile-slight-detail"
                  }
                ],
                [],
                [
                  {
                    label: `Created on ${moment(
                      profile.profile.created_at
                    ).format("MMM DD, YYYY")}`,
                    className: "labelFlat footer right"
                  }
                ]
              ]}
              noDate
              noChart
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Box
              title="Male Members Count"
              value={profile.savings_result.total_male_count}
              chart={{
                data: profile.male_member_enrollment_per_month,
                key: "count"
              }}
              icon="male"
              barChart
            />
            <Box
              title="Female Members Count"
              value={profile.savings_result.total_female_count}
              chart={{
                data: profile.male_member_enrollment_per_month,
                key: "count"
              }}
              icon="female"
              barChart
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row stretched>
          <Grid.Column width={8}>
            <Box
              title="Agent enrolment per gender"
              chart={{
                data: [
                  ...agentEnrollmentByGender.map(d => ({
                    Male: d.male_count,
                    Female: d.female_count,
                    name: moment(d.month).format("MMM")
                  }))
                ],
                keys: ["Male", "Female"]
              }}
              doubleLineChart
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <Box
              title="Cumulative vs Current Savings"
              chart={{
                data: [
                  ...cumulativeVsCurrentSavings.map(d => ({
                    "Cumulative Savings": d.cumulative_savings,
                    "Current Savings": d.current_savings,
                    name: moment(d.month).format("MMM")
                  }))
                ],
                keys: ["Cumulative Savings", "Current Savings"]
              }}
              doubleBarChart
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row stretched>
          <Grid.Column width={8}>
            <Box
              title="Members Age Range"
              chart={{
                data: [
                  {
                    name: "18-24",
                    caption: "Members:",
                    count: profile.savings_result.age_distribution.from1824
                  },
                  {
                    name: "25-29",
                    caption: "Members:",
                    count: profile.savings_result.age_distribution.from2529
                  },
                  {
                    name: "30-34",
                    caption: "Members:",
                    count: profile.savings_result.age_distribution.from3034
                  },
                  {
                    name: "35-39",
                    caption: "Members:",
                    count: profile.savings_result.age_distribution.from3539
                  },
                  {
                    name: "40-49",
                    caption: "Members:",
                    count: profile.savings_result.age_distribution.from4049
                  },
                  {
                    name: "50+",
                    caption: "Members:",
                    count: profile.savings_result.age_distribution.above50
                  }
                ],
                key: "count"
              }}
              radialBarChart
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <Box
              title="Gender Segregation"
              chart={{
                data: [
                  {
                    name: "Male",
                    caption: "Count: ",
                    count: profile.savings_result.total_male_count
                  },
                  {
                    name: "Female",
                    caption: "Count: ",
                    count: profile.savings_result.total_female_count
                  }
                ],
                key: "count"
              }}
              radialChart
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row stretched>
          <Grid.Column width={8}>
            <Box
              title="MNO wallet distribution per province"
              chart={{
                data: profile.mno_distribution
                  ? [
                      ...profile.mno_distribution.map(d => ({
                        name: d.province_name,
                        MTN: d.mtn_count,
                        TIGO: d.tigo_count
                      }))
                    ]
                  : [],
                keys: ["MTN", "TIGO"]
              }}
              doubleBarChart
            />
          </Grid.Column>

          <Grid.Column width={8}>
            <Box
              title="Cumulative vs Outstanding Loans"
              chart={{
                data: [
                  ...cumulativeVsOutstandingLoans.map(d => ({
                    "Cumulative Loans": d.cumulative_loans,
                    "Outstanding Loans": d.outstanding_loans,
                    name: moment(d.month).format("MMM")
                  }))
                ],
                keys: ["Cumulative Loans", "Outstanding Loans"]
              }}
              doubleLineChart
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  </div>
);

export default UI;
