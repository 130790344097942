import React from "react";
import {
  Form,
  Input,
  Select,
  Message,
  Header,
  Divider,
  Icon,
  Button
} from "semantic-ui-react";
import phoneVerify from "../../../../../../utils/phoneVerify";
import validator from "validator";
import {
  genders,
  maritalStatuses,
  educationLevels
} from "../../../../../../utils/vars";
import ContentLoader from "./../../../../../../components/Loader";
import { DateInput } from "semantic-ui-calendar-react";
import { Link } from "react-router-dom";

const UI = ({
  id,
  lL,
  lS,
  lE,
  lM,
  fL,
  fE,
  fM,
  fR,
  aE,
  aM,
  aR,
  name,
  id_number,
  birth_date,
  gender,
  ocupation,
  martial_status,
  phone,
  education,
  email,
  work_email,
  position,
  is_admin,
  handleSave,
  handleChange,
  history,
  me,
  currentUser
}) => {
  return (
    <ContentLoader
      lL={fL}
      lE={fE}
      lM={fM}
      lR={fR}
      message="Wait a moment..."
      showContent={is_admin !== null}
      showContentMessage={`Couldn't find the user with ID #${id}.`}
    >
      <div className="pannel-scroll">
        <div className="padding">
          <div className="header">
            {history && history.goBack && (
              <div className="back" onClick={() => history.goBack()}>
                <Icon name="arrow left" fitted size="large" />
              </div>
            )}
            <span className="title">
              {is_admin ? "Admin" : "User"}: {name}
              {me().text}
            </span>
            {me().ok && (
              <Button
                as={Link}
                to="/users/me/change-password"
                icon="lock"
                labelPosition="right"
                content="Change Password"
              />
            )}
            {!me().ok && currentUser.is_admin === 1 && (
              <Button
                as={Link}
                labelPosition="right"
                to={`/users/${id}/reset-password`}
                icon="lock"
                content="Reset Password"
              />
            )}
            {!me().ok && currentUser.is_admin === 1 && (
              <Button
                as={Link}
                to={`/users/${id}/deactivate`}
                labelPosition="right"
                icon="ban"
                color="red"
                content="Deactivate"
              />
            )}
          </div>
          <Form loading={lL} success={lS} error={lE} onSubmit={handleSave}>
            <Header>Personal Details</Header>
            <Form.Field
              id="form-input-name"
              name="name"
              value={name}
              control={Input}
              label="Name"
              placeholder="Name..."
              error={validator.isEmpty(name)}
              onChange={handleChange}
              disabled={!me().ok && currentUser.is_admin === 0}
            />
            <Form.Field
              id="form-input-id_number"
              name="id_number"
              value={id_number}
              control={Input}
              label="ID Number"
              placeholder="ID number..."
              error={validator.isEmpty(id_number)}
              onChange={handleChange}
              disabled={!me().ok && currentUser.is_admin === 0}
            />
            <Form.Group widths="equal">
              <DateInput
                id="form-input-birth_date"
                name="birth_date"
                value={birth_date}
                label="Birthdate"
                error={validator.isEmpty(birth_date)}
                placeholder="Birthdate..."
                iconPosition="left"
                dateFormat="YYYY-MM-DD"
                closeOnMouseLeave={false}
                closable={true}
                onChange={handleChange}
                disabled={!me().ok && currentUser.is_admin === 0}
              />
              <Form.Field
                name="gender"
                value={gender}
                control={Select}
                fluid
                options={genders}
                label={{ children: "Gender", htmlFor: "form-input-gender" }}
                placeholder="Gender..."
                search
                searchInput={{ id: "form-input-gender" }}
                error={!validator.isInt(`${gender}`)}
                onChange={handleChange}
                disabled={!me().ok && currentUser.is_admin === 0}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-ocupation"
                name="ocupation"
                value={ocupation}
                control={Input}
                label="Occupation"
                placeholder="Occupation..."
                error={validator.isEmpty(ocupation)}
                onChange={handleChange}
                disabled={!me().ok && currentUser.is_admin === 0}
              />
              <Form.Field
                name="martial_status"
                value={martial_status}
                control={Select}
                fluid
                options={maritalStatuses}
                label={{
                  children: "Marital Status",
                  htmlFor: "form-input-martial_status"
                }}
                placeholder="Marital status..."
                search
                searchInput={{ id: "form-input-martial_status" }}
                error={!validator.isInt(`${martial_status}`)}
                onChange={handleChange}
                disabled={!me().ok && currentUser.is_admin === 0}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-phone"
                name="phone"
                value={phone}
                control={Input}
                label="Phone"
                type="tel"
                placeholder="Phone..."
                error={!phoneVerify(phone).ok}
                onChange={handleChange}
                disabled={!me().ok && currentUser.is_admin === 0}
              />
              <Form.Field
                name="education"
                value={education}
                control={Select}
                fluid
                options={educationLevels}
                label={{
                  children: "Level Of Education",
                  htmlFor: "form-input-education"
                }}
                placeholder="Level of education..."
                search
                searchInput={{ id: "form-input-education" }}
                error={validator.isEmpty(`${education}`)}
                onChange={handleChange}
                disabled={!me().ok && currentUser.is_admin === 0}
              />
            </Form.Group>
            <Form.Field
              id="form-input-email"
              name="email"
              value={email}
              control={Input}
              label="Email"
              type="email"
              placeholder="Email..."
              error={!validator.isEmail(email)}
              onChange={handleChange}
              disabled={!me().ok && currentUser.is_admin === 0}
            />
            <Header>Professional Details</Header>
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-work_email"
                name="work_email"
                value={work_email}
                control={Input}
                label="Work Email"
                type="email"
                placeholder="Work Email..."
                error={!validator.isEmail(work_email)}
                onChange={handleChange}
                disabled={!currentUser.is_admin}
              />
              <Form.Field
                id="form-input-position"
                name="position"
                value={position}
                control={Input}
                label="Position"
                placeholder="Position..."
                error={validator.isEmpty(position)}
                onChange={handleChange}
                disabled={!currentUser.is_admin}
              />
            </Form.Group>
            {aE && (
              <Message
                error
                content={
                  <div className="areaError">
                    <span>{aM}</span>
                    <span onClick={() => aR()}>Retry...</span>
                  </div>
                }
              />
            )}
            {lE && <Message error content={lM} />}
            <Message
              success
              content={`${is_admin ? "Admin" : "User"} details saved!`}
            />
            <Divider />
            <Form.Button
              primary
              floated="right"
              disabled={!me().ok && currentUser.is_admin === 0}
            >
              Save
            </Form.Button>
          </Form>
        </div>
      </div>
    </ContentLoader>
  );
};

export default UI;
