import React from "react";
import { GET } from "./../../../../../../../../utils/api";
import UI from "./UI";

class List extends React.Component {
  state = {
    members: [],
    sg_name: "",
    lL: false,
    lE: false,
    lM: "",
    lR: null
  };
  componentDidMount = () => this.handleFetch();

  handleFetch = () => {
    let { sg_id, profile_id } = this.props;

    this.setState({ lL: true, lE: false, lM: "", lR: null });
    Promise.all([
      GET(`save-fsp/sg/members/${sg_id}`),
      GET(`save-fsp/sg/${sg_id}`)
    ])
      .then(data => {
        this.setState({
          members: data[0].map(item => ({
            ...item,
            sg_id: sg_id,
            profile_id: profile_id
          })),
          sg_name: data[1].saving_group.name,
          lL: false,
          lE: false,
          lM: "",
          lR: null
        });
      })
      .catch(error => {
        this.setState({ lL: false, lE: true, lM: error, lR: null });
      });
  };

  render = () => <UI {...this.props} {...this.state} {...this} />;
}

export default List;
