import React from "react";
import { Form, Input, Message, Divider, Icon } from "semantic-ui-react";
import phoneVerify from "../../../../../../utils/phoneVerify";
import validator from "validator";

const UI = ({
  lL,
  lS,
  lE,
  lM,
  fL,
  fS,
  fE,
  fM,
  existing,
  name,
  position,
  phone,
  work_email,
  id_number,
  handleSave,
  handleChange,
  orgAdmin,
  orgName,
  history
}) => {
  return (
    <div className="pannel-scroll">
      <div className="padding">
        <div className="header">
          {history && history.goBack && (
            <div className="back" onClick={() => history.goBack()}>
              <Icon name="arrow left" fitted size="large" />
            </div>
          )}
          <span className="title">
            Add {orgAdmin ? `admin for ${orgName}` : `User`}
          </span>
        </div>
        <Form loading={lL} success={lS} error={lE} onSubmit={handleSave}>
          <Form.Field
            id={`form-input-id_number`}
            name={`id_number`}
            value={id_number}
            control={Input}
            label="ID Number"
            placeholder="ID Number..."
            error={validator.isEmpty(id_number)}
            onChange={handleChange}
            loading={fL}
          />
          {fE && <Message visible={true} error content={fM} />}
          <Divider />
          <Form.Group widths="equal">
            <Form.Field
              id={`form-input-name`}
              name={`name`}
              value={name}
              control={Input}
              label="Name"
              placeholder="Name..."
              error={validator.isEmpty(name)}
              onChange={handleChange}
              disabled={!fS || existing}
            />
            <Form.Field
              id={`form-input-phone`}
              name={`phone`}
              value={phone}
              control={Input}
              label="Phone"
              type="tel"
              placeholder="Phone..."
              error={!phoneVerify(phone).ok}
              onChange={handleChange}
              disabled={!fS || existing}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              id={`form-input-position`}
              name={`position`}
              value={position}
              control={Input}
              label="Position"
              placeholder="Position..."
              error={validator.isEmpty(position)}
              onChange={handleChange}
              disabled={!fS}
            />
            <Form.Field
              id={`form-input-work_email`}
              name={`work_email`}
              value={work_email}
              control={Input}
              label="Work Email"
              type="email"
              placeholder="Work Email..."
              error={!validator.isEmail(work_email)}
              onChange={handleChange}
              disabled={!fS}
            />
          </Form.Group>
          {lE && <Message error content={lM} />}
          <Message success content={`${orgAdmin ? "Admin" : "User"} added!`} />
          <Divider />
          <Form.Button
            primary
            floated="right"
            content={`Add ${orgAdmin ? "Admin" : "User"}`}
            labelPosition="right"
            icon="check"
            disabled={!fS}
          />
        </Form>
      </div>
    </div>
  );
};

export default UI;
