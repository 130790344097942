import React from "react";

import { Form, Card, Header, Message } from "semantic-ui-react";
import validator from "validator";

const UI = ({
  lL,
  lE,
  lM,
  email,
  password,
  em,
  pa,
  handleSignIn,
  handleChange
}) => (
  <Card.Content>
    <Card.Header textAlign="center" as={Header} size="huge">
      Welcome!
    </Card.Header>
    <Form loading={lL} error={lE} onSubmit={handleSignIn}>
      <Form.Input
        fluid
        name="email"
        placeholder="Email..."
        type="email"
        autoComplete="email"
        value={email}
        onChange={handleChange}
        iconPosition="left"
        icon="at"
        error={!validator.isEmail(email) && em > 0}
        label="Email"
      />
      <Form.Input
        fluid
        name="password"
        placeholder="Password..."
        type="password"
        autoComplete="current-password"
        value={password}
        onChange={handleChange}
        iconPosition="left"
        icon="lock"
        error={validator.isEmpty(password) && em > 0}
        label="Password"
      />
      <Message error content={lM} />
      <Form.Button
        primary
        floated="right"
        disabled={validator.isEmpty(password) || !validator.isEmail(email)}
        content="Sign In"
        labelPosition="right"
        icon="unlock"
      />
    </Form>
  </Card.Content>
);

export default UI;
