import React from "react";
import { Form, Input, Select, Message, Icon } from "semantic-ui-react";
import phoneVerify from "../../../../../utils/phoneVerify";
import validator from "validator";
import { organizationTypes } from "../../../../../utils/vars";

const UI = ({
  lL,
  lS,
  lE,
  lM,
  name,
  email,
  phone,
  address,
  type,
  handleSave,
  handleChange,
  history
}) => (
  <div className="pannel-scroll">
    <div className="padding">
      <div className="header">
        {history && history.goBack && (
          <div className="back" onClick={() => history.goBack()}>
            <Icon name="arrow left" fitted size="large" />
          </div>
        )}
        <span className="title">Organization Settings</span>
      </div>
      <Form loading={lL} success={lS} error={lE} onSubmit={handleSave}>
        <Form.Field
          id="form-input-name"
          name="name"
          value={name}
          control={Input}
          label="Name"
          placeholder="Name..."
          error={validator.isEmpty(name)}
          onChange={handleChange}
        />
        <Form.Field
          id="form-input-email"
          name="email"
          value={email}
          control={Input}
          label="Email"
          type="email"
          placeholder="Email..."
          error={!validator.isEmail(email)}
          onChange={handleChange}
        />
        <Form.Field
          id="form-input-phone"
          name="phone"
          value={phone}
          control={Input}
          label="Phone"
          type="tel"
          placeholder="Phone..."
          error={!phoneVerify(phone).ok}
          onChange={handleChange}
        />
        <Form.Field
          id="form-input-address"
          name="address"
          value={address}
          control={Input}
          label="Address"
          placeholder="Address..."
          error={validator.isEmpty(address)}
          onChange={handleChange}
        />
        <Form.Field
          name="type"
          value={type}
          control={Select}
          options={organizationTypes}
          label={{ children: "Type", htmlFor: "form-input-type" }}
          placeholder="Type..."
          search
          searchInput={{ id: "form-input-type" }}
          error={!validator.isInt(`${type}`)}
          onChange={handleChange}
        />
        <Message error content={lM} />
        <Message success content="Organization details saved!" />
        <Form.Button primary floated="right">
          Save
        </Form.Button>
      </Form>
    </div>
  </div>
);

export default UI;
