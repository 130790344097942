import React from "react";

import { Icon, Form, Input, Message, Divider } from "semantic-ui-react";

import validator from "validator";

const UI = ({
  lL,
  lE,
  lM,
  lS,
  old_password,
  new_password,
  new_password_repeat,
  handleChange,
  handleSave,
  history
}) => (
  <div className="pannel-scroll">
    <div className="padding">
      <div className="header">
        {history && history.goBack && (
          <div className="back" onClick={() => history.goBack()}>
            <Icon name="arrow left" fitted size="large" />
          </div>
        )}
        <span className="title">Change Password</span>
      </div>
      <Form loading={lL} success={lS} error={lE} onSubmit={handleSave}>
        <Form.Field
          id="form-input-old_password"
          name="old_password"
          value={old_password}
          control={Input}
          type="password"
          label="Old Password"
          placeholder="Old Password..."
          error={validator.isEmpty(old_password)}
          onChange={handleChange}
        />
        <Form.Field
          id="form-input-new_password"
          name="new_password"
          value={new_password}
          control={Input}
          type="password"
          label="New Password"
          placeholder="New Password..."
          error={validator.isEmpty(new_password)}
          onChange={handleChange}
        />
        <Form.Field
          id="form-input-new_password_repeat"
          name="new_password_repeat"
          value={new_password_repeat}
          control={Input}
          type="password"
          label="New Password Repeat"
          placeholder="New Password Repeat..."
          error={validator.isEmpty(new_password_repeat)}
          onChange={handleChange}
        />
        {new_password !== new_password_repeat && (
          <Message
            error
            visible={
              !validator.isEmpty(new_password) &&
              !validator.isEmpty(new_password_repeat)
            }
            content="New password and new password repeat don't match."
          />
        )}
        {lE && <Message error content={lM} />}
        <Message success content={`Password changed!`} />
        <Divider />
        <Form.Button primary floated="right">
          Submit
        </Form.Button>
      </Form>
    </div>
  </div>
);

export default UI;
