import React from "react";
import { POST, GET, baseUrl } from "./../../../../../../utils/api";
import formErrors from "./../../../../../../utils/formErrors";
import UI from "./UI";

class New extends React.Component {
  constructor(props) {
    super(props);

    let { defaultAreas } = props;

    this.state = {
      org_name: "",
      org_type: 6,
      adm_name: "",
      adm_email: "",
      adm_phone: "",
      adm_id_number: "",
      adm_gender: "",
      ...defaultAreas,
      lL: false,
      lE: false,
      lM: "",
      lS: false,
      aL: false,
      aE: false,
      aM: "",
      aR: null,
      aI: null
    };
  }

  areas = ["province", "district", "sector", "cell", "village"];

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, lE: false, lS: false }, () => {
      setTimeout(() => {
        if (this.areas.includes(name)) {
          let next = this.areas.indexOf(name) + 1;
          if (next < this.areas.length) {
            this.handleLocation(next);
          }
        }
      }, 500);
    });
  };

  handleLocation = index => {
    let add_ = this.state[this.areas[index - 1]];

    this.setState({
      aL: true,
      aE: false,
      aM: "",
      aR: null,
      aI: index
    });

    let location = `${baseUrl}location/${this.areas[index - 1]}s_${
      this.areas[index]
    }s/${add_.join(",")}`;

    GET(location)
      .then(data => {
        let items = [];
        data.map(item => {
          items.push({
            label: item.name,
            text: item.name,
            value: item.id,
            key: item.id
          });
          return item;
        });

        let obj = {};

        obj[`${this.areas[index]}`] = [];
        obj[`${this.areas[index]}s`] = items;

        for (let i = index + 1; i < this.areas.length; i++) {
          obj[`${this.areas[i]}`] = [];
          obj[`${this.areas[i]}s`] = [];
        }

        this.setState({
          aL: false,
          aE: false,
          aM: "",
          aR: null,
          aI: null,
          ...obj
        });
      })
      .catch(error => {
        this.setState({
          aL: false,
          aE: true,
          aM: `Failed to get ${this.areas[index]}s.`,
          aR: () => this.handleLocation(index),
          aI: index
        });
      });
  };

  handleSave = (...e) => {
    if (formErrors(e).ok) {
      const { project_id } = this.props;

      const {
        org_name,
        org_type,
        adm_name,
        adm_email,
        adm_phone,
        adm_id_number,
        adm_gender,
        village
      } = this.state;

      const data = {
        name: org_name,
        federation_id: project_id * 1,
        type: org_type,
        union_contact_person: {
          name: adm_name,
          email: adm_email,
          phone: adm_phone,
          id_number: adm_id_number,
          gender: adm_gender
        },
        village_ids: village
      };

      this.setState({ lL: true, lE: false, lM: "", lS: false });
      POST(`save-ssa/union/create`, data)
        .then(data_ => {
          this.setState({ lL: false, lE: false, lM: "", lS: true });
        })
        .catch(error => {
          this.setState({ lL: false, lE: true, lM: error, lS: false });
        });
    }
  };

  render() {
    return <UI {...this} {...this.props} {...this.state} />;
  }
}

export default New;
