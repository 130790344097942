import React from "react";
import {
  Dropdown,
  Popup,
  Image,
  Header,
  Icon,
  Input,
  Transition,
  Button
} from "semantic-ui-react";
import { Switch, Route, Link, NavLink } from "react-router-dom";
import abName from "./../../utils/abName";
import SignOut from "./SignOut";
import ContentLoader from "./../../components/Loader";
import HomeIcon from "./../../assets/images/home_icon.svg";
import DashIcon from "./../../assets/images/dashboard_icon.svg";
import WalletIcon from "./../../assets/images/wallet_icon.svg";
import ShareIcon from "./../../assets/images/share_arrow.svg";

import { listItemNames } from "./../../utils/vars";

import SSA from "./_ssa";
import NGO from "./_ngo";
import NCCR from "./_nccr";
import FSP from "./_fsp";

import nThLetter from "./../../utils/nThLetter";

const UI = ({
  organization,
  defaultAreas,
  user,
  lL,
  lM,
  lE,
  lR,
  selectedItem,
  listItems,
  theSelectedItem,
  overview,
  showList,
  searchStr = "",
  toggleItems,
  selectItem,
  setSelected,
  updateOrg,
  listFilter,
  handleSearchChange,
  handleFetchNGO
}) => (
  <div id="app">
    <div id="sidebar">
      <Popup
        trigger={
          <Link className="abrev" to="/">
            {nThLetter(organization.name, 2)}
          </Link>
        }
        content={organization.name || "Fetching..."}
      />
      <NavLink
        className="abrev menu-item"
        to={user.admin_type === 1 ? "/overview" : "/"}
        exact
        activeClassName="active"
      >
        <Image src={DashIcon} />
      </NavLink>
      {user.admin_type === 1 && user.is_admin === 1 && (
        <NavLink
          className="abrev menu-item"
          to="/wallet"
          exact
          activeClassName="active"
        >
          <Image src={WalletIcon} />
        </NavLink>
      )}
      {organization.type === 8 && (
        <NavLink
          className="abrev menu-item"
          to={user.admin_type === 1 ? "/overview" : "/"}
          exact
          activeClassName="active"
        >
          <Image src={ShareIcon} />
        </NavLink>
      )}

      <div className="flex-1" />
      <Dropdown
        text={nThLetter(user.name, 2)}
        pointing="left"
        className="abrev"
      >
        <Dropdown.Menu>
          <Dropdown.Item
            icon="user"
            text="Account"
            description={abName(user.name)}
            as={Link}
            to="/users/me"
          />
          <Dropdown.Item icon="lock" text="Sign Out" as={Link} to="/sign-out" />
        </Dropdown.Menu>
      </Dropdown>
    </div>
    <ContentLoader
      lL={lL}
      lE={lE}
      lM={lM}
      lR={lR}
      message="Wait a moment..."
      showContent={typeof organization.id !== "undefined"}
    >
      <div id="content">
        <div id="titlebar">
          <NavLink className="home-link" activeClassName="active" exact to="/">
            <Image src={HomeIcon} />
          </NavLink>
          <div id="top-selector" className={selectedItem ? "selected" : ""}>
            <Header as="h1" onClick={() => toggleItems()}>
              <span>
                {/* The selection dropdown currently caters for ssa and ngo,for other user types
                an expression should be chained to the Select postfix  */}
                {selectedItem
                  ? selectItem().name
                  : `Select ${
                      user.admin_type === 0 ? "Project" : "Organisation"
                    }...`}
              </span>
              <Icon name="caret down" fitted />
            </Header>
          </div>
          <div className="flex-1" />
          {[1, 2, 3].includes(organization.type) && user.admin_type === 1 && (
            <Button
              as={NavLink}
              to="/new"
              activeClassName="disabled"
              exact
              primary
              content="New Organization"
              labelPosition="right"
              icon="add"
            />
          )}
          {organization.type === 4 && (
            <Button
              as={NavLink}
              to="/federations/new"
              activeClassName="disabled"
              exact
              primary
              content="New Federation"
              labelPosition="right"
              icon="add"
            />
          )}
          {organization.type === 8 && (
            <Button
              as={NavLink}
              to="/profiles/new"
              activeClassName="disabled"
              exact
              primary
              content="New Profile"
              labelPosition="right"
              icon="add"
            />
          )}
          {[1, 2, 3].includes(organization.type) && user.admin_type === 0 && (
            <Button
              as={NavLink}
              to="/projects/new"
              activeClassName="disabled"
              exact
              primary
              content="New Project"
              labelPosition="right"
              icon="add"
            />
          )}
        </div>
        <div id="pages" key={`${selectedItem}`}>
          <Switch>
            <Route exact path="/sign-out" component={SignOut} />
            <Route
              path={`/`}
              render={props => {
                setTimeout(() => setSelected(props.history.location), 300);
                if (user.admin_type === 1) {
                  return (
                    <SSA
                      {...props}
                      organizations={listItems}
                      selectedOrganization={theSelectedItem}
                      overview={overview}
                      userOrg={organization}
                      currentUser={user}
                      updateOrg={updateOrg}
                      defaultAreas={defaultAreas}
                    />
                  );
                } else if (user.admin_type === 0) {
                  return (
                    <NGO
                      {...props}
                      projects={listItems}
                      selectedProject={theSelectedItem}
                      userOrg={organization}
                      currentUser={user}
                      updateOrg={updateOrg}
                      defaultAreas={defaultAreas}
                    />
                  );
                } else if (organization.type === 4) {
                  return (
                    <NCCR
                      {...props}
                      projects={listItems}
                      selectedProject={theSelectedItem}
                      userOrg={organization}
                      currentUser={user}
                      updateOrg={updateOrg}
                      defaultAreas={defaultAreas}
                      handleFetchNGO={handleFetchNGO}
                    />
                  );
                } else if (organization.type === 8) {
                  return (
                    <FSP
                      {...props}
                      profiles={listItems}
                      selectedProfile={theSelectedItem}
                      overview={overview}
                      userOrg={organization}
                      currentUser={user}
                      updateOrg={updateOrg}
                      defaultAreas={defaultAreas}
                    />
                  );
                } else {
                  return "404";
                }
              }}
            />
          </Switch>
        </div>
        <Transition visible={showList} animation="fade up" duration={300}>
          <div id="top-item-selector">
            <div className="backdrop" onClick={() => toggleItems()}>
              Click to close
            </div>
            <div className="inner">
              <Icon name="caret up" fitted />
              <div className="scroll">
                {listItems.length >= 10 && (
                  <div className="search">
                    <Input
                      fluid
                      transparent
                      inverted
                      placeholder="Search..."
                      value={searchStr}
                      onChange={handleSearchChange}
                    />
                  </div>
                )}
                <div className="items">
                  <div>
                    {listFilter(searchStr).map(item =>
                      item.type > 4 && item.type < 8 ? null : (
                        <NavLink
                          to={`/${listItemNames[user.admin_type]}s/${item.id}`}
                          key={item.id}
                          activeClassName="active"
                          onClick={() => toggleItems(true)}
                        >
                          {item.name}
                        </NavLink>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </ContentLoader>
  </div>
);

export default UI;
