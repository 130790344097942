import React from "react";

import { LineChart, Line } from "recharts";
import { Segment } from "semantic-ui-react";

var moment = require("moment");


const Boxxx = ({
  title = "",
  value = null,
  chart = { data: [], key: "", keys: [] },
                  
  title2 = "",
  value2 = null,
  chart2 = { data: [], key: "", keys: [] }
}) => (
  <Segment>
    
    <div  className="boxxx-overview" style={{display: "flex", flexDirection: "column"}}>
        <div>

          
          <div className="boxxx-title">{title}</div>

          <div className="date">
            <span>{moment().format("MMM YYYY")}</span>
          </div>

          <div className="boxx-value">{value}</div>
          <LineChart width={275} height={80} data={chart.data}>
            <Line
              type="monotone"
              dataKey={chart.key}
              stroke="#4183C4"
              strokeWidth={2}
            />
          </LineChart>
           
         </div>
         
         <div style={{margin: "0 15px 0 15px"}}>

         <div className="boxxx-title">{title2}</div>

          <div className="date">
            <span>{moment().format("MMM YYYY")}</span>
          </div>
           <div className="boxx-value">{value2}</div>
          <LineChart width={275} height={80} data={chart2.data}>
            <Line
              type="monotone"
              dataKey={chart2.key}
              stroke="#4183C4"
              strokeWidth={2}
            />
          </LineChart>
         </div>
         
    </div>

  </Segment>
);

export default Boxxx;
