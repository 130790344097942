import React from "react";

import { Switch, Route } from "react-router-dom";

import Overview from "./Overview";
import Members from "./Members";
import Activity from "./Activity";

const UI = props => (
  <Switch>
    <Route
      exact
      path={`/organizations/${props.id}/saving-groups/${
        props.match.params.sg_id
      }`}
      render={props_ => (
        <Overview
          {...props}
          {...props_}
          org_id={props.id}
          sg_id={props.match.params.sg_id}
        />
      )}
    />
    <Route
      path={`/organizations/${props.id}/saving-groups/${
        props.match.params.sg_id
      }/members`}
      render={props_ => (
        <Members
          {...props}
          {...props_}
          org_id={props.id}
          sg_id={props.match.params.sg_id}
        />
      )}
    />
    <Route
      path={`/organizations/${props.id}/saving-groups/${
        props.match.params.sg_id
      }/activity`}
      render={props_ => (
        <Activity
          {...props}
          {...props_}
          org_id={props.id}
          sg_id={props.match.params.sg_id}
        />
      )}
    />
  </Switch>
);

export default UI;
