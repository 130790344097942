import React from "react";
import {
  Form,
  Input,
  Select,
  Message,
  Header,
  Divider,
  Icon
} from "semantic-ui-react";
import phoneVerify from "./../../../../../../utils/phoneVerify";
import validator from "validator";
import { organizationTypes, genders } from "./../../../../../../utils/vars";
import MySelect from "./../../../../../../components/MySelect";

const UI = ({
  lL,
  lS,
  lE,
  lM,
  aL,
  aE,
  aM,
  aR,
  aI,
  org_name,
  org_type,
  adm_name,
  adm_email,
  adm_phone,
  adm_id_number,
  adm_gender,
  province,
  district,
  sector,
  cell,
  village,
  provinces,
  districts,
  sectors,
  cells,
  villages,
  handleSave,
  handleChange,
  history
}) => {
  return (
    <div className="pannel-scroll">
      <div className="padding">
        <div className="header">
          {history && history.goBack && (
            <div className="back" onClick={() => history.goBack()}>
              <Icon name="arrow left" fitted size="large" />
            </div>
          )}
          <span className="title">Add Union</span>
        </div>
        <div className="pannels-row">
          <div className="flex-1" />
          <Form
            className="fluid"
            loading={lL}
            success={lS}
            error={lE}
            onSubmit={handleSave}
          >
            <Header>Union's Details</Header>
            <Form.Field
              id="form-input-org_name"
              name="org_name"
              value={org_name}
              control={Input}
              label="Name"
              placeholder="Name..."
              error={validator.isEmpty(org_name)}
              onChange={handleChange}
            />
            <Form.Field
              name="org_type"
              value={org_type}
              control={Select}
              fluid
              options={organizationTypes}
              label={{ children: "Type", htmlFor: "form-input-org_type" }}
              placeholder="Type..."
              search
              searchInput={{ id: "form-input-org_type" }}
              error={!validator.isInt(`${org_type}`)}
              onChange={handleChange}
              disabled
            />
            <Header>Union's Contact Person</Header>
            <Form.Field
              id="form-input-adm_name"
              name="adm_name"
              value={adm_name}
              control={Input}
              label="Name"
              placeholder="Name..."
              error={validator.isEmpty(adm_name)}
              onChange={handleChange}
            />
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-adm_email"
                name="adm_email"
                value={adm_email}
                control={Input}
                label="Email"
                type="email"
                placeholder="Email..."
                error={!validator.isEmail(adm_email)}
                onChange={handleChange}
              />
              <Form.Field
                id="form-input-adm_phone"
                name="adm_phone"
                value={adm_phone}
                control={Input}
                label="Phone"
                type="tel"
                placeholder="Phone..."
                error={!phoneVerify(adm_phone).ok}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-adm_id_number"
                name="adm_id_number"
                value={adm_id_number}
                control={Input}
                label="ID Number"
                placeholder="ID Number..."
                error={validator.isEmpty(adm_id_number)}
                onChange={handleChange}
              />
              <Form.Field
                name="adm_gender"
                value={adm_gender}
                control={Select}
                fluid
                options={genders}
                label={{
                  children: "Gender",
                  htmlFor: "form-input-adm_gender"
                }}
                placeholder="Gender..."
                search
                searchInput={{ id: "form-input-adm_gender" }}
                error={!validator.isInt(`${adm_gender}`)}
                onChange={handleChange}
              />
            </Form.Group>
            <Header>Union's Coverage Area</Header>
            <Form.Group widths="equal">
              <Form.Field
                name="province"
                value={province}
                control={MySelect}
                fluid
                options={provinces}
                label={{
                  children: "Provinces",
                  htmlFor: "form-input-province"
                }}
                placeholder="Provinces..."
                error={province.length === 0 || (aE && aI === 0)}
                onChange={handleChange}
                disabled={aL}
                loading={aL && aI === 0}
              />
              <Form.Field
                name="district"
                value={district}
                control={MySelect}
                fluid
                options={districts}
                label={{
                  children: "Districts",
                  htmlFor: "form-input-district"
                }}
                placeholder="Districts"
                error={district.length === 0 || (aE && aI === 1)}
                onChange={handleChange}
                disabled={aL}
                loading={aL && aI === 1}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                name="sector"
                value={sector}
                control={MySelect}
                fluid
                options={sectors}
                label={{
                  children: "Sectors",
                  htmlFor: "form-input-sector"
                }}
                placeholder="Sectors..."
                error={sector.length === 0 || (aE && aI === 2)}
                onChange={handleChange}
                disabled={aL}
                loading={aL && aI === 2}
              />
              <Form.Field
                name="cell"
                value={cell}
                control={MySelect}
                fluid
                options={cells}
                label={{
                  children: "Cells",
                  htmlFor: "form-input-cell"
                }}
                placeholder="Cells..."
                error={cell.length === 0 || (aE && aI === 3)}
                onChange={handleChange}
                disabled={aL}
                loading={aL && aI === 3}
              />
            </Form.Group>
            <Form.Field
              name="village"
              value={village}
              control={MySelect}
              fluid
              options={villages}
              label={{
                children: "Villages",
                htmlFor: "form-input-village"
              }}
              placeholder="Villages..."
              error={village.length === 0 || (aE && aI === 4)}
              onChange={handleChange}
              disabled={aL}
              loading={aL && aI === 4}
            />
            {aE && (
              <Message error visible={aE}>
                <div className="areaError">
                  <span>{aM}</span>
                  <span onClick={() => aR()}>Retry...</span>
                </div>
              </Message>
            )}
            <Message error content={lM} />
            <Message success content={`Union created!`} />
            <Divider />
            <Form.Button primary floated="right">
              Add Union
            </Form.Button>
          </Form>
          <div className="flex-1" />
        </div>
      </div>
    </div>
  );
};

export default UI;
