import React from "react";
import {
  Form,
  Input,
  Select,
  Message,
  Header,
  Divider,
  Icon
} from "semantic-ui-react";
import phoneVerify from "./../../../../../../utils/phoneVerify";
import validator from "validator";
import { organizationTypes, genders } from "./../../../../../../utils/vars";

import ContentLoader from "./../../../../../../components/Loader";

const UI = ({
  lL,
  lS,
  lE,
  lM,
  aL,
  aE,
  aM,
  aR,
  aI,
  org_name,
  org_type,
  adm_name,
  adm_email,
  adm_phone,
  adm_id_number,
  adm_gender,
  partners,
  partner,
  handleSave,
  handleChange,
  history
}) => {
  return (
    <ContentLoader
      lL={aL && aI === 0}
      lE={aE && aI === 0}
      lM={aM}
      lR={aR}
      message="Fetching unions..."
      showContentMessage="You have no union!"
      showContent={aI !== 0}
    >
      <div className="pannel-scroll">
        <div className="padding">
          <div className="header">
            {history && history.goBack && (
              <div className="back" onClick={() => history.goBack()}>
                <Icon name="arrow left" fitted size="large" />
              </div>
            )}
            <span className="title">Add Cooperative</span>
          </div>
          <div className="pannels-row">
            <div className="flex-1" />
            <Form
              className="fluid"
              loading={lL}
              success={lS}
              error={lE}
              onSubmit={handleSave}
            >
              <Header>Cooperative's Details</Header>
              <Form.Field
                id="form-input-org_name"
                name="org_name"
                value={org_name}
                control={Input}
                label="Name"
                placeholder="Name..."
                error={validator.isEmpty(org_name)}
                onChange={handleChange}
              />
              <Form.Field
                name="org_type"
                value={org_type}
                control={Select}
                fluid
                options={organizationTypes}
                label={{ children: "Type", htmlFor: "form-input-org_type" }}
                placeholder="Type..."
                search
                searchInput={{ id: "form-input-org_type" }}
                error={!validator.isInt(`${org_type}`)}
                onChange={handleChange}
                disabled
              />
              <Form.Field
                name={`partner`}
                value={partner}
                control={Select}
                fluid
                options={partners}
                label={{
                  children: "Union",
                  htmlFor: `form-input-partner`
                }}
                placeholder="Union..."
                search
                searchInput={{ id: `form-input-partner` }}
                error={!validator.isInt(`${partner}`)}
                onChange={handleChange}
              />
              <Header>Cooperative's Contact Person</Header>
              <Form.Field
                id="form-input-adm_name"
                name="adm_name"
                value={adm_name}
                control={Input}
                label="Name"
                placeholder="Name..."
                error={validator.isEmpty(adm_name)}
                onChange={handleChange}
              />
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-adm_email"
                  name="adm_email"
                  value={adm_email}
                  control={Input}
                  label="Email"
                  type="email"
                  placeholder="Email..."
                  error={!validator.isEmail(adm_email)}
                  onChange={handleChange}
                />
                <Form.Field
                  id="form-input-adm_phone"
                  name="adm_phone"
                  value={adm_phone}
                  control={Input}
                  label="Phone"
                  type="tel"
                  placeholder="Phone..."
                  error={!phoneVerify(adm_phone).ok}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-adm_id_number"
                  name="adm_id_number"
                  value={adm_id_number}
                  control={Input}
                  label="ID Number"
                  placeholder="ID Number..."
                  error={validator.isEmpty(adm_id_number)}
                  onChange={handleChange}
                />
                <Form.Field
                  name="adm_gender"
                  value={adm_gender}
                  control={Select}
                  fluid
                  options={genders}
                  label={{
                    children: "Gender",
                    htmlFor: "form-input-adm_gender"
                  }}
                  placeholder="Gender..."
                  search
                  searchInput={{ id: "form-input-adm_gender" }}
                  error={!validator.isInt(`${adm_gender}`)}
                  onChange={handleChange}
                />
              </Form.Group>
              <Message error content={lM} />
              <Message success content={`Cooperative created!`} />
              <Divider />
              <Form.Button primary floated="right">
                Add Cooperative
              </Form.Button>
            </Form>
            <div className="flex-1" />
          </div>
        </div>
      </div>
    </ContentLoader>
  );
};

export default UI;
