import React from "react";
import { POST } from "./../../../../utils/api";
import UI from "./UI";
import formErrors from "./../../../../utils/formErrors";

import phoneVerify from "./../../../../utils/phoneVerify";

class New extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      activities: "",
      adm_name: "",
      adm_email: "",
      adm_phone: "",
      adm_id_number: "",
      adm_gender: "",
      pre_name: "",
      pre_phone: "",
      sec_name: "",
      sec_phone: "",
      lL: false,
      lE: false,
      lM: "",
      lS: false,
      lR: this.handleSave
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, lE: false, lS: false });
  };

  handleSave = (...e) => {
    let { userOrg, handleFetchNGO } = this.props;

    const {
      name,
      activities,
      adm_name,
      adm_email,
      adm_phone,
      adm_id_number,
      adm_gender,
      pre_name,
      pre_phone,
      sec_name,
      sec_phone
    } = this.state;

    if (formErrors(e).ok) {
      this.setState({ lL: true, lE: false, lM: "", lS: false });

      let dt = {
        parent_org_id: userOrg.id,
        name,
        common_activities: activities,
        federation_contact_person: {
          name: adm_name,
          email: adm_email,
          phone: phoneVerify(adm_phone).phone,
          id_number: adm_id_number,
          gender: adm_gender
        },
        federation_committee: {
          president: {
            name: pre_name,
            phone: phoneVerify(pre_phone).phone
          },
          secretary: {
            name: sec_name,
            phone: phoneVerify(sec_phone).phone
          }
        }
      };

      POST(`save-ssa/federation/create`, dt)
        .then(data =>
          this.setState(
            {
              lL: false,
              lE: false,
              lM: "",
              lS: true,
              name: "",
              activities: "",
              adm_name: "",
              adm_email: "",
              adm_phone: "",
              adm_id_number: "",
              adm_gender: "",
              pre_name: "",
              pre_phone: "",
              sec_name: "",
              sec_phone: ""
            },
            handleFetchNGO
          )
        )
        .catch(error => {
          this.setState({ lL: false, lE: true, lM: error, lS: false });
        });
    }
  };

  render() {
    return <UI {...this} {...this.props} {...this.state} />;
  }
}

export default New;
