import React from "react";
import careateLink from "./../../utils/createLink";
import Box from "./../Box";
import numberFormat from "./../../utils/numberFormat";

const moment=require("moment");

class MyListB extends React.Component {
  render() {
    let {
      items = [],
      as = "div",
      asFn = t => t,
      link = []
    } = this.props;
    const As = asFn(as);
    return (
      <div className={`my-list-custom`}>
        {items.map((item, index) => (
        <As className="item" key={index} {...careateLink(item, link)}>
        <Box
                className2="value-card-custom"
                name={item.name}
                rows={[

                  [
                    {
                      label: "Groups",
                      value: numberFormat(item.groups),
                      className: "labelNeigbour paddingBoxx"
                    },
                    {
                      label: "Members",
                      value: numberFormat(item.members),
                      className: "labelNeigbour right paddingBoxx"
                    }
                  ],

                  [
                    {
                      label: "Current Savings",
                      value: numberFormat(
                        item.current_savings,
                        "",
                        "RWF"
                      ),
                      className: "paddingBoxx"
                    }
                  ],
                  [
                    {
                      label: "Current Loans",
                      value: numberFormat(
                        item.current_loans,
                        "",
                        "RWF"
                      ),
                      className: "paddingBoxx"
                    }
                  ],
              
                  [],
                  [
                    {
                      label: `Profile created on ${moment(
                        item.created_at
                      ).format("MMM DD, YYYY")}`,
                      className: "labelFlat footer right"
                    }
                  ]
                ]}
                noDate
                noChart
              />
              </As>
        ))}
      </div>
    );
  }
}

export default MyListB;
